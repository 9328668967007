import React from 'react';
import { css } from 'aphrodite';
import Error, { ERROR_STYLES } from './Error';
import { styles } from '../../../constants/styles';

const SESSION_EXPIRED_ERROR =
  'Your session has expired due to inactivity. Please click your most recent activation link to try again.';

const SessionExpiredError = () => {
  return (
    <Error disclaimer={<div className={css(ERROR_STYLES.errorText, styles.bodyText)}>{SESSION_EXPIRED_ERROR}</div>} />
  );
};

export default SessionExpiredError;
export { SESSION_EXPIRED_ERROR };
