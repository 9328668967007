import React from 'react';
import { ChangeEventHandler, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Input } from '@tcl-argon-foundations/input';
import { fonts, styles, text } from '../../../constants/styles';
import { grayPalette } from '@tcl-argon-colors/colors';

type AppInputProps = {
  label: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  value: string;
};

const AppInput: FunctionComponent<AppInputProps> = (props) => {
  return (
    <Input
      classes={{
        input: css(APP_INPUT_STYLES.input, styles.supportingBodyText),
        inputWrapper: css(APP_INPUT_STYLES.wrapper),
        label: css(fonts.ibmPlexSansMedium, text.supportingBodyText),
      }}
      label={props.label}
      onChange={props.onChange}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
};

const APP_INPUT_STYLES = StyleSheet.create({
  wrapper: {
    ':focus': {
      border: `0.07em solid ${grayPalette.gray30}`,
    },
    ':focus-within': {
      border: `0.07em solid ${grayPalette.gray30}`,
    },
  },
  input: {
    border: `0.07em 0px 0.07em 0.07em solid ${grayPalette.gray30}`,
    backgroundColor: '#FFFFFF',
    boxShadow: `0 0.14em 0.28em 0 ${grayPalette.gray10}`,
  },
});

export default AppInput;
