import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConsentAppForm } from '@tempus/patient-forms-service-shared';

import NavigatorChecklist from './NavigatorChecklist';
import Verification, { ValidatedValues } from './Verification';
import { useQuery } from '../../../api/hooks';
import { FullScreenError, FullScreenErrorType } from '../../../app/components/screen/FullScreenError';

const CustomToDoListRoot: React.FunctionComponent = () => {
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [formList, setFormList] = useState<ConsentAppForm[]>([]);
  const [validatedValues, setValidatedValues] = useState<ValidatedValues>({} as ValidatedValues);
  const history = useHistory();

  const orderId = useQuery('orderId');

  const setStateHandler = (formListConfig: ConsentAppForm[], validatedValues: ValidatedValues) => {
    setFormList(formListConfig);
    setValidatedValues(validatedValues);
    setIsVerified(true);
  };

  if (!orderId) {
    return (
      <FullScreenError
        type={FullScreenErrorType.OUTAGE}
        title="Link Expired"
        message={
          <>
            The link you followed has expired. Use the link in the most recent message you received from us. If the
            issue persists, reach out to us at <a href="mailto:support@tempus.com">support@tempus.com</a> for help!
          </>
        }
      ></FullScreenError>
    );
  }
  if (!isVerified) {
    return <Verification id={orderId} setCustomToDoListState={setStateHandler} />;
  } else {
    return <NavigatorChecklist formList={formList} validatedValues={validatedValues} history={history} />;
  }
};

export default CustomToDoListRoot;
