type TermsOfUseDisclaimerProps = {
  id: string;
  href: string;
  disclaimerText: string;
};

const DISCLAMIER_LINK_TEXT = 'Terms of Use';
const DISCLAMIER_ENDING_TEXT = '.';

const TermsOfUseDisclaimer = (props: TermsOfUseDisclaimerProps): HTMLDivElement => {
  const termOfUseDisclaimer = document.createElement('p');
  termOfUseDisclaimer.id = props.id;

  const termOfUseDisclaimerBeginningText = document.createTextNode(props.disclaimerText);

  const termOfUseDisclaimerLink = document.createElement('a');
  const termOfUseDisclaimerLinkText = document.createTextNode(DISCLAMIER_LINK_TEXT);
  termOfUseDisclaimerLink.appendChild(termOfUseDisclaimerLinkText);
  termOfUseDisclaimerLink.href = props.href;
  termOfUseDisclaimerLink.target = '_blank';
  termOfUseDisclaimerLink.rel = 'noreferrer';

  const termOfUseDisclaimerEndingText = document.createTextNode(DISCLAMIER_ENDING_TEXT);

  termOfUseDisclaimer.appendChild(termOfUseDisclaimerBeginningText);
  termOfUseDisclaimer.appendChild(termOfUseDisclaimerLink);
  termOfUseDisclaimer.appendChild(termOfUseDisclaimerEndingText);

  return termOfUseDisclaimer;
};

export default TermsOfUseDisclaimer;
export { DISCLAMIER_LINK_TEXT };
