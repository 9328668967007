import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Question } from '@tcl-argon-icons/icons';
import { Tooltip } from '@tcl-argon-prefabs/tooltip';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { Breakpoints, fonts } from '../../../constants/styles';
import { isMobileDevice, useComponentVisible } from '../../../utils';
import HelpModal, { HelpContent } from './HelpModal';

const HELP_BUTTON_TEXT = 'Help';
const MENU_QUESTION_ICON = 'menu-question-icon';

type HelpTextProps = {
  onClick: () => void;
};

const HelpText = (props: HelpTextProps) => {
  return (
    <p onClick={props.onClick} className={css(fonts.ibmPlexSansRegular, UnverifiedUserTabsStyles.helpButtontext)}>
      {HELP_BUTTON_TEXT}
    </p>
  );
};

const UnverifiedUserTabs = (): JSX.Element => {
  const { ref, isComponentVisible: isOpen, setIsComponentVisible: setIsOpen } = useComponentVisible(false);
  const window = useWindowDimensions();
  const helpButtonOnClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div ref={ref} className={css(UnverifiedUserTabsStyles.container)}>
      {isMobileDevice(window) ? (
        <Fragment>
          <Question data-testid={MENU_QUESTION_ICON} onClick={helpButtonOnClick} />
          <HelpModal isOpen={isOpen} />
        </Fragment>
      ) : (
        <Tooltip isOpen={isOpen} position="below-left">
          {{
            tooltip: <HelpContent />,
            base: <HelpText onClick={helpButtonOnClick} />,
          }}
        </Tooltip>
      )}
    </div>
  );
};

export default UnverifiedUserTabs;
export { MENU_QUESTION_ICON };

const UnverifiedUserTabsStyles = StyleSheet.create({
  container: {
    cursor: 'pointer',
    marginRight: '2.14em',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      marginRight: '0px',
    },
  },
  helpButtontext: {
    fontSize: '14px',
    lineHeight: '16px',
  },
});
