import React, { FunctionComponent, ReactNode } from 'react';
import { primaryPalette } from '@tcl-argon-colors/colors';
import { Warning } from '@tcl-argon-icons/icons';
import { Alert } from '@tcl-argon-prefabs/alert';
import { css, StyleSheet } from 'aphrodite/no-important';
import { styles } from '../../../constants/styles';

type ErrorBannerProps = {
  title?: ReactNode;
};

const DEFAULT_ERROR_TITLE_TEXT = 'error';
const ERROR_ALERT_STYLES = {
  backgroundColor: 'rgba(215,48,59,0.1)',
  borderRadius: '0',
  boxShadow: 'none',
  display: 'block',
  maxWidth: 'none',
  width: 'auto',
};

const ERROR_BANNER_STYLES = StyleSheet.create({
  text: {
    marginLeft: '0.71em',
    marginTop: '-0.14em',
    textTransform: 'uppercase',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ErrorBanner: FunctionComponent<ErrorBannerProps> = (props: ErrorBannerProps) => (
  <Alert style={ERROR_ALERT_STYLES}>
    {{
      content: (
        <div className={css(ERROR_BANNER_STYLES.wrapper)}>
          <Warning color={primaryPalette.errorPrimary} height="15px" width="15px" />
          {props.title ?? (
            <div className={css(ERROR_BANNER_STYLES.text, styles.sectionHeaderBlack)}>{DEFAULT_ERROR_TITLE_TEXT}</div>
          )}
        </div>
      ),
    }}
  </Alert>
);

export default ErrorBanner;
export { ERROR_BANNER_STYLES };
