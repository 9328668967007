import React, { useEffect, useState, FunctionComponent } from 'react';
import { useAsync } from 'react-async';
import { RichText } from '../../app/components/richtext/RichText';
import Error from '../../app/components/screen/Error';
import { ConsentFooter } from './commonComponents';
import { TextInput } from '@tcl-argon-prefabs/text-input';
import { StyleSheet, css } from 'aphrodite/no-important';
import { styles, fonts, Breakpoints } from '../../constants/styles';
import { grayPalette } from '@tcl-argon-colors/colors';
import ContentLayout from '../../app/components/screen/ContentLayout';
import { commonStyles } from '../../constants/commonStyles';
import { setConsentFormLegacy, setPCPOnConsentForm } from '../../api/PatientConsent';
import { usePatientInfo } from '../../api/PatientInfo';

const content = {
  description: {
    paragraph: [
      {
        text:
          'Please provide your primary care physician contact information below. Tempus will contact them to securely transfer your files.',
      },
    ],
  },
};

const consentPCPLegacyDeferFn = ([consent]: any[]) => setConsentFormLegacy(consent);
const consentPCPDeferFn = ([consent]: any[]) => setPCPOnConsentForm(consent);

type PhysicianInfoFormProps = {
  next: () => void;
};

const PhysicianInfoForm: FunctionComponent<PhysicianInfoFormProps> = ({ next }) => {
  const { patientInfo } = usePatientInfo();
  const [physicianName, setPhysicianName] = useState('');
  const [institution, setInstitution] = useState('');
  const [cityState, setCityState] = useState('');

  const consentSubmitPCPLegacy = useAsync({ deferFn: consentPCPLegacyDeferFn });
  const consentSubmitPCP = useAsync({ deferFn: consentPCPDeferFn });

  const submitForm = async () => {
    if (patientInfo?.consent?.consented) {
      consentSubmitPCPLegacy.run({
        physicianName: physicianName,
        institution: institution,
        cityState: cityState,
      });
    } else {
      consentSubmitPCP.run({
        pcpPhysicianName: physicianName,
        pcpInstitution: institution,
        pcpCityState: cityState,
      });
    }
  };

  useEffect(() => {
    if (consentSubmitPCPLegacy.isFulfilled || consentSubmitPCP.isFulfilled) {
      next();
    }
  }, [consentSubmitPCPLegacy.isFulfilled, consentSubmitPCP.isFulfilled]);

  if (consentSubmitPCPLegacy.isRejected || consentSubmitPCP.isRejected) {
    return <Error />;
  }

  return (
    <ContentLayout>
      <div className={css(commonStyles.outerContainer, PhysicianInfoFormStyles.container)}>
        <div className={css([PhysicianInfoFormStyles.headerText, styles.title])}>Primary Care Physician</div>
        <RichText
          viewStyle={PhysicianInfoFormStyles.contentContainer}
          content={content.description}
          lineStyle={[PhysicianInfoFormStyles.content, styles.bodyText]}
        />
        <TextInput
          classes={{
            root: css(PhysicianInfoFormStyles.textInputContainer),
            label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
            inputWrapper: css(PhysicianInfoFormStyles.textInputWrapper),
          }}
          label="Physician name*"
          data-testid="name"
          required
          onChange={(name) => setPhysicianName(name)}
          value={physicianName}
        />
        <TextInput
          classes={{
            root: css(PhysicianInfoFormStyles.textInputContainer),
            label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
            inputWrapper: css(PhysicianInfoFormStyles.textInputWrapper),
          }}
          label="Hospital/Practice*"
          data-testid="institution"
          required
          onChange={(institution) => setInstitution(institution)}
          value={institution}
        />
        <TextInput
          classes={{
            root: css(PhysicianInfoFormStyles.textInputContainer),
            label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
            inputWrapper: css(PhysicianInfoFormStyles.textInputWrapper),
          }}
          label="City,State*"
          data-testid="cityState"
          required
          onChange={(cityState) => setCityState(cityState)}
          value={cityState}
        />
        <ConsentFooter
          accept={() => submitForm()}
          decline={() => next()}
          submitButtonText="Done"
          supportingText="Don't have this information right now?"
          declineText="Skip for now"
          declineButtonDisabled={consentSubmitPCPLegacy.isLoading || consentSubmitPCP.isLoading}
          submitButtonDisabled={consentSubmitPCPLegacy.isLoading || consentSubmitPCP.isLoading || !institution}
          submitButtonLoading={consentSubmitPCPLegacy.isLoading || consentSubmitPCP.isLoading}
        />
      </div>
    </ContentLayout>
  );
};

export default PhysicianInfoForm;

const PhysicianInfoFormStyles = StyleSheet.create({
  container: {
    maxWidth: '24.29em',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      maxWidth: '21.43em',
    },
  },
  headerText: {
    textAlign: 'center',
    marginBottom: '2.14em',
  },
  contentContainer: {
    marginBottom: '0.71em',
    textAlign: 'center',
  },
  content: {
    color: grayPalette.gray100,
    marginBottom: '2.14em',
  },
  textInputContainer: {
    width: '100%',
    marginBottom: '2.14em',
  },
  textInputWrapper: {
    border: `0.07em solid ${grayPalette.gray30}`,
    boxShadow: `0 0.14em 0.29em 0 ${grayPalette.gray10}`,
  },
});
