import { ICResultDataProduct } from './IC';

export enum Assay {
  SarsCov2 = 'sars-cov-2.v1',
  SalivaDirect = 'sars-cov-2-saliva-direct.v1',
}

type BaseOrderResult = {
  assay: Assay;
  resultManifestId: string;
  resultDataProduct: unknown;
};

export type ICResult = BaseOrderResult & {
  assay: Assay.SarsCov2;
  resultDataProduct: ICResultDataProduct;
};

export const isICResult = (result: ResultType): result is ICResult => result.assay === Assay.SarsCov2;

export type ResultType = ICResult | BaseOrderResult;
