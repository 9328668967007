import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import ReactHtmlParser from 'react-html-parser';
import DisclaimerHeader from '../components/DisclaimerNoticeWrapper/DisclaimerNoticeHeader';
import AppStoreButton from '../../../assets/images/appStoreButton.svg';
import GooglePlayButton from '../../../assets/images/googlePlayButton.png';
import { styles, fonts, text, Breakpoints } from '../../../constants/styles';
import { primaryPalette, grayPalette } from '@tcl-argon-colors/colors';
import {
  googlePlayLink,
  appleStoreLink,
  appleStoreButtonAltText,
  googlePlayButtonAltText,
} from '../../../constants/appStoreLinks';

const inlineLinkStyle = `color: ${primaryPalette.tempusPrimary};fontSize: 12px;lineHeight: 14px`;
const content = {
  subtitle:
    'Use the Tempus COVID-19 mobile app to access the results of your lab test (ordered through a licensed medical provider).',
  section: {
    sectionHeader: 'Having issues creating your account?',
    subHeader:
      'Your results must be accessed from our mobile app. If the link provided to you does not open the app but takes you here, please follow the instructions below.',
    helpContent: [
      'Download or update the Tempus COVID-19 app on your Android or iOS smart phone using the link below.',
      `Request a new account activation link through the app by tapping the "Don't have an account?" button on the login page.`,
      `Launch the app using the most recent email you've received from Tempus.`,
      `Or if you received an activation text from us, try the link again after installing the Tempus COVID-19 app.`,
    ],
  },
  downloadAppText: 'Download the Tempus COVID-19 App',
  footerContent: [
    'Tempus is a healthcare provider that operates a clinical laboratory offering polymerase chain reaction (PCR) testing for diagnosis of COVID-19 patients around the country. Tempus also operates a broad-based, healthcare operations initiative to aggregate and structure clinical and molecular data collected from COVID-19 patients.',
    `More information about Tempus is available at <a rel="noreferrer" target="_blank" style="${inlineLinkStyle}" href="https://www.tempus.com"> https://www.tempus.com</a>`,
  ],
};

const FAQ = (): JSX.Element => {
  const { section, subtitle, downloadAppText, footerContent } = content;
  return (
    <Fragment>
      <DisclaimerHeader />
      <div className={css(FAQStyles.contentWrapper)}>
        <div className={css(FAQStyles.container)}>
          <p className={css(FAQStyles.subtitle, styles.bodyHeader)}>{subtitle}</p>
          <div className={css(FAQStyles.contentSection)}>
            <p className={css(FAQStyles.sectionHeader, fonts.ibmPlexSansBold)}>{section.sectionHeader}</p>
            <p className={css(text.bodyHeader)}>{section.subHeader}</p>
            <ol className={css(text.bodyHeader)}>
              {section.helpContent.map((point: string, idx: number) => (
                <li className={css(FAQStyles.helpContent)} key={idx}>
                  {point}
                </li>
              ))}
            </ol>
          </div>
          <p className={css(FAQStyles.sectionHeader, fonts.ibmPlexSansBold)}>{downloadAppText}</p>
          <div className={css(FAQStyles.appButtonsContainer)}>
            <a href={appleStoreLink} target="_blank" rel="noreferrer">
              <img
                alt={appleStoreButtonAltText}
                className={css(FAQStyles.appleStoreButton, FAQStyles.storeButton)}
                src={AppStoreButton}
              />
            </a>
            <a href={googlePlayLink} target="_blank" rel="noreferrer">
              <img
                alt={googlePlayButtonAltText}
                className={css(FAQStyles.playStoreButton, FAQStyles.storeButton)}
                src={GooglePlayButton}
              />
            </a>
          </div>
        </div>
        <div className={css(FAQStyles.footerContainer)}>
          {footerContent.map((point: string, idx: number) => (
            <p className={css(text.footerText, FAQStyles.footerText)} key={idx}>
              {ReactHtmlParser(point)}
            </p>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

const FAQStyles = StyleSheet.create({
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      padding: '0px 1.25em',
    },
  },
  container: {
    maxWidth: '31.25em',
    textAlign: 'center',
  },
  subtitle: {
    paddingTop: '1.25em',
  },
  contentSection: {
    padding: '0px 1.25em',
  },
  sectionHeader: {
    fontSize: '16px',
    lineHeight: '20px',
    paddingTop: '1.25em',
  },
  helpContent: {
    paddingTop: '0.625em',
    textAlign: 'left',
  },
  appButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1.25em',
    justifyContent: 'center',
  },
  appleStoreButton: {
    height: '3.75em',
    width: '7.5em',
  },
  playStoreButton: {
    height: '3.75em',
    width: '9.69em',
  },
  storeButton: {
    margin: '0px 0.9375em',
  },
  footerContainer: {
    borderTop: `0.0625em solid ${grayPalette.gray45}`,
    maxWidth: '42.5em',
    textAlign: 'center',
    padding: '3.125em',
    paddingTop: '0.625em',
    boxSizing: 'border-box',
  },
  footerText: {
    lineHeight: '16px',
  },
});

export default FAQ;
export { content };
