import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import ReactHtmlParser from 'react-html-parser';
import { grayPalette } from '@tcl-argon-colors/colors';
import { termsOfUse, ContentType, Term, ListType } from './termsContent';
import DisclaimerNoticeWrapper from '../components/DisclaimerNoticeWrapper/DisclaimerNoticeWrapper';
import { styles, fonts, text, Breakpoints } from '../../../constants/styles';

const TITLE_TEXT = 'Tempus Mobile App Terms of Use';
const SUBTITLE_DATE_TEXT = 'Effective November 17, 2020';
const CONTENT_SUBTITLE = 'Important Notices';
const TERMS_TEST_ID = 'terms-section';

// This function recursively processes strings into divs or html lists
const convertStringsToJSX = (termsContent: ContentType, idx: number, isList: boolean): JSX.Element => {
  // base case
  if (typeof termsContent === 'string') {
    return isList ? (
      <li key={idx}>{termsContent}</li>
    ) : (
      <div key={idx} className={css(TermsOfUseStyles.newLine)}>
        {ReactHtmlParser(termsContent)}
      </div>
    );
  }

  //recursively traverse lists
  const renderContent = (): JSX.Element[] =>
    termsContent.listContent.map((listItem: ContentType, i: number) => convertStringsToJSX(listItem, i, true));

  if (termsContent.listSelection.type === ListType.UNORDERED) {
    return (
      <ul key={idx} style={{ listStyleType: termsContent.listSelection.bulletStyle }}>
        {renderContent()}
      </ul>
    );
  } else {
    return (
      <ol key={idx} type={termsContent.listSelection.bulletStyle}>
        {renderContent()}
      </ol>
    );
  }
};

const TermsOfUseContent: React.FC = () => {
  return (
    <div className={css(TermsOfUseStyles.container)}>
      <div className={css(TermsOfUseStyles.headerContainer)}>
        <div className={css([styles.title, TermsOfUseStyles.title])}>{TITLE_TEXT}</div>
        <div className={css(styles.sectionHeader, TermsOfUseStyles.subheader)}>{SUBTITLE_DATE_TEXT}</div>
      </div>
      <div className={css(styles.bodyText, TermsOfUseStyles.lineHeight)}>
        {termsOfUse.preface.map((line: string, idx: number) => convertStringsToJSX(line, idx, false))}
      </div>
      <div className={css(TermsOfUseStyles.paragraph)}>
        <p className={css([text.bodyText, fonts.ibmPlexSansBold, TermsOfUseStyles.sectionHeader])}>
          {CONTENT_SUBTITLE}
        </p>
        <ul className={css(styles.bodyText, TermsOfUseStyles.lineHeight)}>
          {termsOfUse.importantNotices.map((line: string, idx: number) => convertStringsToJSX(line, idx, true))}
        </ul>
      </div>
      <div className={css(styles.bodyText, TermsOfUseStyles.lineHeight)}>
        {termsOfUse.terms.map((term: Term, idx: number) => {
          return (
            <div data-testid={TERMS_TEST_ID} className={css(TermsOfUseStyles.paragraph)} key={idx}>
              <div className={css([text.bodyText, fonts.ibmPlexSansBold, TermsOfUseStyles.sectionHeader])}>
                {idx + 1}. {term.title}
              </div>
              <div>
                {term.content.map((termContent: ContentType, i: number) => convertStringsToJSX(termContent, i, false))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { TITLE_TEXT, SUBTITLE_DATE_TEXT, TERMS_TEST_ID };

const TermsOfUse: React.FC = () => (
  <DisclaimerNoticeWrapper>
    <TermsOfUseContent />
  </DisclaimerNoticeWrapper>
);

export default TermsOfUse;

const TermsOfUseStyles = StyleSheet.create({
  container: {
    padding: '0 12vw',
    [Breakpoints.LARGE_DEVICE_BREAKPOINT]: {
      padding: '0 10vw',
    },
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      padding: '0 8vw',
    },
  },
  headerContainer: {
    marginTop: '4.44em',
    marginBottom: '2.78em',
  },
  title: {
    fontWeight: 500,
    marginBottom: '1.11em',
  },
  subheader: {
    color: grayPalette.gray60,
    fontSize: '18px',
    lineHeight: '36px',
  },
  sectionHeader: {
    marginBottom: '0.83em',
  },
  paragraph: {
    marginBottom: '1.67em',
  },
  newLine: {
    marginBottom: '1.11em',
  },
  lineHeight: {
    lineHeight: '30px',
  },
});
