import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import TempusLogo from '../../../shared/TempusIconLogo';
import { TEMPUS_PRO_PATH } from '../..';
import { joinClasses } from '../../utils';
import { Success } from '@tcl-boron-icons/icons';
import { AppContext, AppContextType } from '../App/AppContext';
import styles from './LandingPage.module.css';
import { SuccessPalette } from '@tcl-boron-colors/colors';

const LandingPage: React.FC = () => {
  const { assessments, notificationId } = React.useContext(AppContext) as AppContextType;

  const assessmentCount = assessments.length;

  return (
    <>
      <div className={styles.landingPage}>
        <TempusLogo />
        <h1 className={joinClasses(typography.header, styles.headerText)}>Welcome!</h1>
      </div>
      <div>
        <h3 className={joinClasses(typography.sectionHeader, styles.subheaderText)}>
          {`You have ${assessmentCount} assessment${
            assessmentCount > 1 || assessmentCount === 0 ? 's' : ''
          } to complete`}
        </h3>
        {assessmentCount === 0 ? (
          <div className={styles.zeroAssessmentsWrapper}>
            <Success height={18} width={18} color={SuccessPalette[700]} />
            <div className={styles.zeroAssessmentsText}>You are all caught up!</div>
          </div>
        ) : (
          <ul>
            {assessments.map((assessment: any) => (
              <li key={assessment.id}>
                <p>
                  <strong>{assessment.fullName}</strong>
                </p>
                <p>
                  {assessment.name} ({assessment.numQuestions} Questions)
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
      {assessmentCount > 0 && (
        <div className={styles.startButtonContainer}>
          <Link to={`${TEMPUS_PRO_PATH}/form?id=${notificationId}`}>
            <Button buttonType="primary" data-test-id="start-button" ariaLabel="Start" className={styles.startButton}>
              Start
            </Button>
          </Link>
        </div>
      )}
    </>
  );
};

export default LandingPage;
