import React from 'react';
import { Button } from '@tcl-boron-prefabs/button';
import { ChevronRight } from '@tcl-boron-icons/icons';
import { AppContext, AppContextType } from '../../App/AppContext';
import { QuestionResponse } from '../../../types';
import styles from './SubmitButton.module.css';

const SubmitButton: React.FC = () => {
  const { assessments, currentIndex, responses, submitResponses } = React.useContext(AppContext) as AppContextType;

  const submitDisabled = React.useMemo(() => {
    return responses.some((QuestionResponse: QuestionResponse) => QuestionResponse.value === -1);
  }, [responses]);

  return (
    <div className={styles.submitButtonContainer}>
      <Button
        data-test-id="assessment-submit"
        id="assessmentSubmit"
        onClick={submitResponses}
        disabled={submitDisabled}
        className={styles.submitButton}
        buttonType="primary"
        ariaLabel="Submit"
      >
        {currentIndex < assessments.length - 1 ? (
          <span className={styles.nextButtonText}>
            Next&nbsp;
            <ChevronRight />
          </span>
        ) : (
          'Done'
        )}
      </Button>
      <p className={styles.submitHelpText}>Please answer all questions to proceed</p>
    </div>
  );
};

export default SubmitButton;
