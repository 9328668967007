import React from 'react';
import styles from './ErrorPage.module.css';
import ErrorIcon from './ErrorIcon';
import { joinClasses } from '../../utils';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';

const ErrorPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <ErrorIcon />
      <h1 className={joinClasses(styles.header, typography.subSectionHeader)}>Not Available</h1>
      <p className={joinClasses(styles.text, typography.supportingBody)}>
        We&apos;re sorry, but Tempus PRO is currently unavailable. Please refresh your page or try again later.
      </p>
      <p className={joinClasses(styles.text, typography.supportingBody)}>
        If the issue persists, reach out to us at <a href="mailto:help@tempus.com">help@tempus.com</a> for help!
      </p>
    </div>
  );
};

export default ErrorPage;
