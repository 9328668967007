import React, { FunctionComponent } from 'react';
import { Button } from '@tcl-argon-prefabs/button';
import { StyleSheet, css } from 'aphrodite/no-important';
import { InputTheme } from '@tcl-argon-models/models';
import { commonStyles } from '../../constants/commonStyles';
import { styles } from '../../constants/styles';

type FooterProps = {
  accept: () => void;
  decline: () => void;
  submitButtonText: string;
  supportingText: string;
  declineText: string;
  declineButtonDisabled?: boolean;
  submitButtonDisabled?: boolean;
  submitButtonLoading?: boolean;
};

const ConsentFooter: FunctionComponent<FooterProps> = ({
  accept,
  decline,
  submitButtonText,
  supportingText,
  declineText,
  declineButtonDisabled = false,
  submitButtonDisabled = false,
  submitButtonLoading = false,
}) => (
  <div className={css(ConsentCommonStyles.footer)}>
    <Button
      type="submit"
      disabled={submitButtonDisabled}
      className={css(commonStyles.primaryButton)}
      isInverted
      onClick={accept}
      theme={InputTheme.Default}
      loading={submitButtonLoading}
    >
      {submitButtonText}
    </Button>
    <div className={css(ConsentCommonStyles.skipContainer)}>
      <p className={css(styles.supportingBodyText)}>{supportingText}</p>
      <p
        onClick={declineButtonDisabled ? undefined : decline}
        className={css([ConsentCommonStyles.skipTextLink, styles.inlineLink])}
      >
        {declineText}
      </p>
    </div>
  </div>
);

export { ConsentFooter };

const ConsentCommonStyles = StyleSheet.create({
  footer: {
    width: '100%',
    marginTop: '2.14em',
  },
  skipContainer: {
    textAlign: 'center',
    marginTop: '1.43em',
  },
  skipTextLink: {
    cursor: 'pointer',
  },
});
