import React, { useEffect, Fragment, useState } from 'react';
import { useAsync, AsyncState } from 'react-async';
import { useOktaAuth } from '@okta/okta-react';
import { Content } from './ContentTypes';
import { createGenericContext } from '../utils';
import { fetchHelper } from './fetchHelper/fetchHelper';
import Loading from '../app/components/screen/Loading';
import ServiceUnavailable from '../app/components/screen/ServiceUnavailable';
import { RESULTS_NOT_FOUND_ERROR_TITLE } from './Orders';

type PatientInfoLoaderProps = { children: JSX.Element };

export type NotificationPreferences = {
  sms?: boolean;
  push?: boolean;
  email?: boolean;
  phone?: boolean;
};

export type PatientConsent = {
  consented: boolean;
  content: Content;
  contentVersion: string;
  email?: string;
  fullName?: string;
  neverAskForPCPAgain: boolean;
  physicianContactInfoProvided: boolean;
  signature?: string;
  updatedDate: Date;
  createdDate: Date;
};

export interface PatientInfo {
  city?: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  gender?: string;
  lastName: string;
  medicalRecordNumber?: string;
  middleName?: string;
  phone?: string;
  state?: string;
  street1?: string;
  street2?: string;
}

export interface Form {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  patientId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  email: string;
  phoneNumbers: string[];
  street1: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  medicalRecordNumber: string;
  pcpPhysicianName?: string;
  pcpInstitution?: string;
  pcpCityState?: string;
  signature: string;
  signatureType: string;
  diseaseType: string;
  formType: string;
  formVersion: string;
  dateSigned: Date;
  dateActive: Date;
  dateExpired?: Date;
  dateSubmitted: string;
  revoked: boolean;
}

export interface FormSubmission {
  diseaseType: string;
  formType: string;
  formVersion: string;
  form?: Form;
  dateSubmitted: Date;
}

export type MyInfo = {
  firstLoginDate?: Date;
  notificationPreferences: NotificationPreferences;
  consent?: PatientConsent;
  forms: FormSubmission[];
  neverAskForPCPAgain: boolean;
} & PatientInfo;

export type PatientInfoContext = {
  patientInfoState: AsyncState<MyInfo>;
  refreshPatientInfo: () => void;
  isFirstTimeLogin: boolean;
  setIsFirstTimeLogin: (val: boolean) => void;
};

// Constants
const DEFAULT_FORM_VERSION = '2.0.0';
const DATE_FORMAT = 'MMM D, YYYY';
const DEFAULT_DISEASE_TYPE = 'infectious-disease';
const DEFAULT_FORM_TYPE = 'covid-hipaa-consent';
const DEFAULT_SIGNATURE_TYPE = 'base64';

export const formConstants = {
  DEFAULT_DISEASE_TYPE,
  DEFAULT_FORM_VERSION,
  DEFAULT_FORM_TYPE,
  DEFAULT_SIGNATURE_TYPE,
  DATE_FORMAT,
};

const fetchPatientInfo = async (): Promise<MyInfo> => {
  return fetchHelper('/v3/users/myinfo');
};

export const getHipaaConsentForm = (forms?: FormSubmission[]): FormSubmission | undefined => {
  return forms?.find((formSubmission) => formSubmission.formType === formConstants.DEFAULT_FORM_TYPE);
};

export const [usePatientInfoState, PatientInfoProvider] = createGenericContext<PatientInfoContext>();

export const usePatientInfo = (): { patientInfo: MyInfo | undefined; refreshPatientInfo: () => void } => {
  const {
    patientInfoState: { data: patientInfo },
    refreshPatientInfo,
  } = usePatientInfoState();
  return { patientInfo, refreshPatientInfo };
};

export const PatientInfoLoader: React.FC = ({ children }) => {
  const { authState } = useOktaAuth();
  const state = useAsync<MyInfo>({ deferFn: fetchPatientInfo });
  const { run: refreshPatientInfo } = state;
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState(false);

  useEffect(() => {
    if (authState?.accessToken?.accessToken) {
      refreshPatientInfo();
    }
  }, [authState?.accessToken?.accessToken]);

  if (state.isRejected) {
    return <ServiceUnavailable title={RESULTS_NOT_FOUND_ERROR_TITLE} />;
  }

  const patientInfoContext = {
    patientInfoState: state,
    refreshPatientInfo,
    isFirstTimeLogin,
    setIsFirstTimeLogin,
  };

  return <PatientInfoProvider value={patientInfoContext}>{children}</PatientInfoProvider>;
};

export const AwaitPatientInfo = ({ children }: PatientInfoLoaderProps): JSX.Element | null => {
  const { patientInfoState } = usePatientInfoState();
  return patientInfoState.isSettled ? <Fragment>{children}</Fragment> : <Loading />;
};
