import { Modal } from '@tcl-boron-prefabs/modal';
import React from 'react';
import { AppContext, AppContextType } from '../App/AppContext';
import {
  PRO_TERMS_OF_USE_URL,
  PRO_PRIVACY_POLICY_URL,
  PRO_NOTICE_OF_PRIVACY_PRACTICES_URL,
  PRO_TERMS_OF_USE_KEY,
  TermsOfUseStatus,
} from '.';
import styles from './TermsOfUse.module.css';
import client from '../../client';
import { useLocalStorage } from '../../utils';

export const getLink = (text: string, url: string) => {
  return (
    <a href={url} rel="noreferrer" target="_blank" className={styles.link}>
      {text}
    </a>
  );
};

const TermsOfUse: React.FC = () => {
  const { notificationId, orderId } = React.useContext(AppContext) as AppContextType;
  const [termsOfUse, setTermsOfUse] = useLocalStorage(PRO_TERMS_OF_USE_KEY, {
    firstSeenDate: Date.now(),
    status: TermsOfUseStatus.Unacknowledged,
  });

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (termsOfUse.status !== TermsOfUseStatus.Accepted) {
      setIsModalOpen(true);
    }
  });

  const setTermsOfUseWithApi = (status: TermsOfUseStatus) => {
    setTermsOfUse({ ...termsOfUse, status });

    if (status === TermsOfUseStatus.Unacknowledged) {
      return;
    }

    const accepted = status === TermsOfUseStatus.Accepted;
    client.setTermsOfUse(notificationId, orderId, accepted);
  };

  let title = 'Terms and Privacy';
  let cancelButtonText = 'Back';
  if (termsOfUse.status === TermsOfUseStatus.Unacknowledged) {
    cancelButtonText = 'Cancel';
  } else if (termsOfUse.status === TermsOfUseStatus.Rejected) {
    title = 'Tempus PRO access denied';
  } else if (termsOfUse.status === TermsOfUseStatus.Accepted) {
    // We don't change from defaults here, but if statement kept to be explicit
  }

  return (
    <Modal
      title={title}
      isOpen={isModalOpen}
      className={styles.modal}
      callToActionButton={{
        onClick: () => {
          setTermsOfUseWithApi(TermsOfUseStatus.Accepted);
          setIsModalOpen(false);
        },
        buttonText: 'I agree',
        id: 'Confirm',
        disabled: termsOfUse.status === TermsOfUseStatus.Rejected,
      }}
      cancelButton={{
        onClick: () => {
          if (termsOfUse.status === TermsOfUseStatus.Rejected) {
            setTermsOfUseWithApi(TermsOfUseStatus.Unacknowledged);
          } else {
            setTermsOfUseWithApi(TermsOfUseStatus.Rejected);
          }
        },
        buttonText: cancelButtonText,
        id: 'Cancel',
      }}
      size="large"
    >
      {termsOfUse.status === TermsOfUseStatus.Unacknowledged && (
        <div data-testid="unacknowledged">
          In order to use Tempus PRO&trade;, please review the following terms and indicate your agreement using the
          button below:
          <p>
            {getLink('Terms of Use', PRO_TERMS_OF_USE_URL)}, {getLink('Privacy Policy', PRO_PRIVACY_POLICY_URL)}, and{' '}
            {getLink('Notice of Privacy Practices', PRO_NOTICE_OF_PRIVACY_PRACTICES_URL)}.
          </p>
        </div>
      )}
      {termsOfUse.status === TermsOfUseStatus.Rejected && (
        <div data-testid="rejected">
          Before you proceed, you must read and accept the Terms Of Use for Tempus PRO&trade;
        </div>
      )}
    </Modal>
  );
};

export default TermsOfUse;
