import React from 'react';
import styles from '../../forms/shared/styles.module.css';
import checklistStyles from '../checklistStyles.module.css';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import classNames from 'classnames';
import { GrayCheckmark } from '../assets/GrayCheckmark';
import { BlueCheckmark } from '../assets/BlueCheckmark';
import { Link } from 'react-router-dom';
import StatusBadge from '../../forms/shared/StatusBadge';
import { useQuery } from '../../../api/hooks';
import { ConsentAppForm } from '@tempus/patient-forms-service-shared';

type ChecklistTableProps = {
  tableTitle: string;
  forms: ConsentAppForm[];
  complete: boolean;
};

const getChecklistRowTitle = (form: ConsentAppForm) => {
  if (form.required) {
    return form.title + ' (Required)';
  }
  return form.title;
};

const renderChecklistFormField = (form: ConsentAppForm, complete: boolean) => {
  const orderId = useQuery('orderId');
  const link = `/checklist/form/${form.link}?orderId=${orderId}`;

  return (
    <div>
      <div className={checklistStyles.checklistContentRow}>
        <div>{form.description}</div>
        <div className={checklistStyles.checklistCheckbox}>{complete ? <BlueCheckmark /> : <GrayCheckmark />}</div>
      </div>
      {complete ? (
        <div className={checklistStyles.checklistCompleteDoc}>Document complete</div>
      ) : (
        <Link to={link} className={checklistStyles.completeLink} id={`complete-document-link-${form.key}`}>
          Complete Document
        </Link>
      )}
    </div>
  );
};

const ChecklistTable: React.FunctionComponent<ChecklistTableProps> = (props) => {
  return (
    <div>
      <div className={typography.subSectionHeader}>{props.tableTitle}</div>
      <table className={classNames(styles.table, checklistStyles.checklistTable)}>
        <tbody>
          {props.forms.map((formModel, index) => {
            return (
              <tr key={index} className={styles.tableRow} id={`field-type-${formModel.link}`}>
                <td className={styles.tableRowTitle}>
                  <div className={styles.tableRowLabelContainer}>
                    <span className={classNames(styles.tableRowTitleText, styles.bold)}>
                      {getChecklistRowTitle(formModel)}
                    </span>
                    {formModel.financialAidInfo && (
                      <StatusBadge
                        title={'\xa0' + formModel.financialAidInfo.title}
                        text={formModel.financialAidInfo.statement}
                        type={formModel.financialAidInfo.status}
                      />
                    )}
                  </div>
                </td>
                <td className={styles.tableRowContent}>
                  <div className={checklistStyles.formChecklistDescription}>
                    {renderChecklistFormField(formModel, props.complete)}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ChecklistTable;
