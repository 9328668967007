type ResetPasswordLinkProps = {
  id: string;
  open: () => void;
};

const RESET_PASSWORD_LINK_TEXT = 'Forgot password?';

const ResetPasswordLink = (props: ResetPasswordLinkProps): HTMLDivElement => {
  const resetPasswordContainer = document.createElement('div');
  const resetPasswordLink = document.createElement('a');
  resetPasswordContainer.id = props.id;
  const resetPasswordLinkText = document.createTextNode(RESET_PASSWORD_LINK_TEXT);
  resetPasswordLink.appendChild(resetPasswordLinkText);
  resetPasswordLink.onclick = props.open;
  resetPasswordContainer.appendChild(resetPasswordLink);
  return resetPasswordContainer;
};

export default ResetPasswordLink;
