import React from 'react';
import { NavigationRoute, AuthenticationState } from '../../../api/Navigation';
import VerifiedRoute from './VerifiedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';

export type RouteWrapperProps = NavigationRoute;

const RouteWrapper: React.FC<RouteWrapperProps> = (props) => {
  const RouteMapper = {
    [AuthenticationState.UNAUTHENTICATED]: UnauthenticatedRoute,
    [AuthenticationState.AUTHENTICATED]: AuthenticatedRoute,
    [AuthenticationState.VERIFIED]: VerifiedRoute,
  };

  const Route = RouteMapper[props.authState];

  return <Route {...props} />;
};

export default RouteWrapper;
