import { OktaAuth } from '@okta/okta-auth-js';
import Config from '../../config';

enum OktaSignOutState {
  SUCCESS = 'SUCCESS',
  SUSPENDED = 'SUSPENDED',
  SIGNED_OUT = 'SIGNED_OUT',
}

enum OktaGroups {
  PATIENTS = 'Patients',
  PATIENTS_VERIFIED = 'Patients-Verified',
}

export const OKTA_SIGN_OUT_STATE = 'okta-sign-out-state';

const oktaConfig = Config.okta;

const oktaAuthConfiguration = {
  issuer: oktaConfig.issuer,
  clientId: oktaConfig.clientId,
  redirectUri: window.location.origin + '/callback',
};

const oktaWidgetDefaultConfiguration = {
  baseUrl: oktaConfig.baseUrl,
  clientId: oktaConfig.clientId,
  redirectUri: window.location.origin + '/callback',
  authParams: {
    issuer: oktaConfig.issuer,
  },
};

const oktaAuth = new OktaAuth(oktaAuthConfiguration);

const logOutAndClearStorage = async (state: OktaSignOutState) => {
  try {
    await oktaAuth.signOut();
    oktaAuth.tokenManager.clear();
  } finally {
    localStorage.clear();
    localStorage.setItem(OKTA_SIGN_OUT_STATE, state);
  }
};

const getOktaSignOutState = () => {
  return localStorage.getItem(OKTA_SIGN_OUT_STATE);
};

const removeOktaSignOutState = () => {
  localStorage.removeItem(OKTA_SIGN_OUT_STATE);
};

export {
  OktaSignOutState,
  OktaGroups,
  oktaAuth,
  getOktaSignOutState,
  removeOktaSignOutState,
  logOutAndClearStorage,
  oktaAuthConfiguration,
  oktaWidgetDefaultConfiguration,
};
