import { css, StyleSheet } from 'aphrodite/no-important';
import React, { FunctionComponent } from 'react';
import Error from '../../../app/components/screen/Error';
import { ERROR_BANNER_STYLES } from '../../../app/components/screen/ErrorBanner';
import { styles } from '../../../constants/styles';

const ERROR_TEXT =
  'Your account has been suspended for your security due to too many incorrect attempts to verify your identity. ';
const ERROR_BANNER_TEXT = 'account suspended';

const Suspended: FunctionComponent = () => {
  return (
    <Error
      disclaimer={
        <div className={css(SUSPENDED_STYLES.container)}>
          <div className={css(SUSPENDED_STYLES.errorText, styles.bodyText)}>{ERROR_TEXT}</div>
          <div className={css(SUSPENDED_STYLES.errorText, styles.bodyText)}>
            Please reach out to{' '}
            <a className={css(styles.link)} href={'mailto:covidinfo@tempus.com'}>
              covidinfo@tempus.com
            </a>{' '}
            to verify your identity and reactivate your account. You may also contact your ordering health care provider
            for your result.
          </div>
        </div>
      }
      title={<div className={css(ERROR_BANNER_STYLES.text, styles.sectionHeaderBlack)}>{ERROR_BANNER_TEXT}</div>}
    />
  );
};

const SUSPENDED_STYLES = StyleSheet.create({
  container: {
    padding: '0px 2.5em',
  },
  errorText: {
    marginTop: '1.07em',
    textAlign: 'center',
  },
});

export default Suspended;
