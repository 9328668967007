export const REQUIRED_FIELD_WARNING = 'Required field';
export const INVALID_DATE_OF_BIRTH_WARNING = 'Enter a valid date of birth (MM/DD/YYYY)';
export const INVALID_ZIP_WARNING = 'Enter a valid zip code';
export const INVALID_EMAIL_WARNING = 'Enter a valid email';
export const INVALID_PHONE_WARNING = 'Enter a valid United States phone number';
export const INVALID_NUMBER_WARNING = 'Enter a valid number';
export const CHARACTER_LIMIT_WARNING = 'You are over the character limit';

export const getCharacterLimitWarning = (inputLength: number, limit: number) => {
  const lengthDiff = inputLength - limit;
  if (lengthDiff < 1) {
    return undefined;
  }

  const returnWarning = CHARACTER_LIMIT_WARNING.concat(` by ${lengthDiff} character`);
  if (lengthDiff > 1) {
    return returnWarning.concat('s');
  } else {
    return returnWarning;
  }
};
