import React from 'react';
import { NavigationRoute, AppNavigationType } from '../../../api/Navigation';
import OktaAuthRouter from '../okta/OktaAuth';
import RouteWrapper from './RouteWrapper';
import { ContentLoader, AwaitContent } from '../../../api/Content';
import { PatientInfoLoader, AwaitPatientInfo } from '../../../api/PatientInfo';
import { OrdersLoader, AwaitOrders } from '../../../api/Orders';
import { SecureRoute } from '@okta/okta-react';
import { Switch, Route } from 'react-router-dom';
import { getFormRoutes } from '../../../screens/forms/forms';
import CustomToDoListRoot from '../../../screens/custom-to-do-list/components/CustomToDoListRoot';
import { featureEnabledInEnvironment } from '../../../../config/feature-toggles';
import { FullScreenError, FullScreenErrorType } from '../screen/FullScreenError';

type RoutesWithContext = {
  routes: NavigationRoute[];
};

const VerifiedRoutesWithContext = (props: RoutesWithContext) => {
  return (
    <ContentLoader>
      <AwaitContent>
        <PatientInfoLoader>
          <AwaitPatientInfo>
            <OrdersLoader>
              <AwaitOrders>
                <Switch>
                  {props.routes.map((route, index) => {
                    return <RouteWrapper key={index} {...route} />;
                  })}
                </Switch>
              </AwaitOrders>
            </OrdersLoader>
          </AwaitPatientInfo>
        </PatientInfoLoader>
      </AwaitContent>
    </ContentLoader>
  );
};

const AuthenticatedRoutesWithContext = (props: RoutesWithContext) => {
  return (
    <Switch>
      {props.routes.map((route, index) => {
        return <RouteWrapper key={index} {...route} />;
      })}
    </Switch>
  );
};

const UnauthenticatedRoutesWithContext = (props: RoutesWithContext) => {
  return (
    <Switch>
      {props.routes.map((route, index) => {
        return <RouteWrapper key={index} {...route} />;
      })}
    </Switch>
  );
};

const FormRoutes = () => {
  return (
    <Switch>
      {getFormRoutes().map((route, index) => (
        <Route key={index} {...route.routeProps} component={route.Component} />
      ))}
      <Route
        render={() => (
          <FullScreenError
            title={'Page Not Found'}
            message={'The page was deleted, the URL is incorrect, or some other error occurred.'}
            type={FullScreenErrorType.OUTAGE}
          />
        )}
      />
    </Switch>
  );
};

const CustomToDoList = () => {
  return <CustomToDoListRoot />;
};

type AppNavigatorProps = {
  routes: AppNavigationType;
};

const AppNavigator: React.FC<AppNavigatorProps> = (props) => {
  const { routes } = props;
  const verifiedRoutePaths = routes.VERIFIED.map((route) => route.path);
  const authenticatedRoutePaths = routes.AUTHENTICATED.map((route) => route.path);
  const unauthenticatedRoutePaths = routes.UNAUTHENTICATED.map((route) => route.path);

  return (
    <OktaAuthRouter>
      <SecureRoute
        path={verifiedRoutePaths}
        render={() => {
          return <VerifiedRoutesWithContext routes={routes.VERIFIED} />;
        }}
      />
      <SecureRoute
        path={authenticatedRoutePaths}
        render={() => {
          return <AuthenticatedRoutesWithContext routes={routes.AUTHENTICATED} />;
        }}
      />
      <Route
        path={unauthenticatedRoutePaths}
        render={() => {
          return <UnauthenticatedRoutesWithContext routes={routes.UNAUTHENTICATED} />;
        }}
      />
      <Route path="/form" component={FormRoutes} />
      {featureEnabledInEnvironment('enableFormChecklist') ? (
        <Route path="/checklist" component={CustomToDoList} />
      ) : (
        <Route
          render={() => (
            <FullScreenError
              title={'Page Not Found'}
              message={'The page was deleted, the URL is incorrect, or some other error occurred.'}
              type={FullScreenErrorType.OUTAGE}
            />
          )}
        />
      )}
    </OktaAuthRouter>
  );
};

export default AppNavigator;
