import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Modal } from '@tcl-argon-prefabs/modal';
import { Breakpoints, styles } from '../../../constants/styles';
import Config from '../../../../config';

const HELP_MENU_RESULT_QUESTION = 'Questions about your results?';
const HELP_MENU_CONTACT_PROVIDER = 'Please reach out to your healthcare provider';
const HELP_MENU_FEEDBACK = 'Feedback or have a technical issue?';
const HELP_MENU_CONTACT_SUPPORT = 'Please email us at ';
const HELP_CONTENT_TEST_ID = 'help-content';
const SUPPORT_EMAIL = 'covidinfo@tempus.com';

const HelpContent = () => (
  <div className={css(HelpStyling.menuTextContainer)} data-testid={HELP_CONTENT_TEST_ID}>
    <p className={css(styles.bodyHeader)}>{HELP_MENU_RESULT_QUESTION}</p>
    <p className={css(styles.bodyText, HelpStyling.bottomTextPadding)}>{HELP_MENU_CONTACT_PROVIDER}</p>
    <p className={css(styles.bodyHeader, HelpStyling.topTextPadding)}>{HELP_MENU_FEEDBACK}</p>
    <p className={css(styles.bodyText)}>
      {HELP_MENU_CONTACT_SUPPORT}
      <a className={css(styles.link, HelpStyling.emailLinkText)} href={`mailto:${SUPPORT_EMAIL}`}>
        {SUPPORT_EMAIL}
      </a>
    </p>
  </div>
);

type HelpModalProps = {
  isOpen: boolean;
};

const HelpModal = (props: HelpModalProps) => {
  const isTestEnvironment = Config.environment === 'test';

  return (
    <Modal
      ariaHideApp={!isTestEnvironment}
      style={{ overlay: { top: '60px' } }}
      className={css(HelpStyling.modalContainer)}
      title=""
      size="small"
      isOpen={props.isOpen}
    >
      <HelpContent />
    </Modal>
  );
};

export default HelpModal;
export { HelpContent, HELP_CONTENT_TEST_ID };

const HelpStyling = StyleSheet.create({
  container: {
    cursor: 'pointer',
    marginRight: '2.14em',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      marginRight: '0px',
    },
  },
  bottomTextPadding: {
    marginBottom: '0px',
    paddingBottom: '1.07em',
  },
  helpButtontext: {
    fontSize: '14px',
    lineHeight: '16px',
  },
  menuTextContainer: {
    textAlign: 'center',
    width: '14.29em',
    margin: 'auto',
  },
  topTextPadding: {
    marginTop: '0px',
    paddingTop: '1.07em',
  },
  emailLinkText: {
    fontSize: '14px',
  },
  modalContainer: {
    maxWidth: '20em',
    height: '17.29em',
    padding: '0.36em',
    top: '1.87em',
    overflow: 'hidden',
  },
});
