import React from 'react';
import Header from '../header/Header';
import { HeaderType } from '../header/headerType';

export type ScreenLayoutProps = {
  header?: HeaderType;
};

const ScreenLayout: React.FC<ScreenLayoutProps> = (props) => {
  return (
    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      {props.header ? <Header headerType={props.header} /> : null}
      {props.children}
    </div>
  );
};

export default ScreenLayout;
