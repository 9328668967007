import React from 'react';
import { StyleSheet, css, StyleDeclarationValue } from 'aphrodite/no-important';
import { primaryPalette, grayPalette } from '@tcl-argon-colors/colors';
import { InputTheme } from '@tcl-argon-models/models';
import { styles, fonts } from '../../constants/styles';
import ReportCard, { OrderInfo } from './ReportCard';
import { useOrders, ReportStatus, AssayName, Order, ReportStatusText } from '../../api/Orders';
import NoResultsImage from '../../../images/noResults.svg';
import DownloadApp from './DownloadApp';
import { useHistory } from 'react-router-dom';
import { usePatientInfoState, usePatientInfo, getHipaaConsentForm } from '../../api/PatientInfo';
import DismissableAlert from '../../app/components/header/DismissableAlert';

const title = 'Home';
const noResultsAltText = 'No results';
const CONSENT_RECEVED_MESSAGE = 'Consent Received. A copy of this consent can be found in the app menu at any time.';

type ReportStatusStyleType = {
  [key: string]: StyleDeclarationValue[];
};

const NoOrders = () => (
  <div className={css(statusScreenStyles.noResults)}>
    <img src={NoResultsImage} alt={noResultsAltText} />
    <div className={css(styles.sectionHeaderGrey, statusScreenStyles.noResultsText)}>NO RESULTS AVAILABLE</div>
  </div>
);

const Orders = (props: { orders: Order[] }) => {
  const { push } = useHistory();
  const { orders } = props;
  const reportStatusStyle: ReportStatusStyleType = {
    [ReportStatus.CANCELED]: [statusScreenStyles.orderCanceled],
    [ReportStatus.PENDING]: [fonts.ibmPlexSansItalic],
  };
  return (
    <div className={css(statusScreenStyles.ordersContainer)}>
      {orders?.map((order) => {
        return (
          <div key={order.orderId} className={css(statusScreenStyles.orderSection)}>
            <OrderInfo order={order} />
            {order.reports.map((report) => {
              const actionText = report.isAmended ? 'View amendment' : 'View report';

              return (
                <ReportCard
                  key={`${order.orderId}${report.manifestId}`}
                  action={() => push(`/result?order=${order.orderId}&report=${report.manifestId}`)}
                  assayName={AssayName[report.assayName]}
                  status={ReportStatusText[report.status]}
                  statusStyles={reportStatusStyle[report.status]}
                  actionText={report.status === ReportStatus.DELIVERED ? actionText : ''}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const StatusScreen = () => {
  const orders = useOrders();
  const { isFirstTimeLogin, setIsFirstTimeLogin } = usePatientInfoState();
  const { patientInfo } = usePatientInfo();

  const form = getHipaaConsentForm(patientInfo!.forms)?.form;

  return (
    <>
      {isFirstTimeLogin && (form || patientInfo?.consent?.consented) && (
        <DismissableAlert
          message={CONSENT_RECEVED_MESSAGE}
          onDismiss={() => setIsFirstTimeLogin(false)}
          theme={InputTheme.Success}
        />
      )}
      <div className={css(statusScreenStyles.container)}>
        <div className={css(styles.title, fonts.ibmPlexSansLight, statusScreenStyles.title)}>{title}</div>
        {orders?.length ? <Orders orders={orders} /> : <NoOrders />}
        <DownloadApp />
      </div>
    </>
  );
};

const statusScreenStyles = StyleSheet.create({
  orderSection: {
    margin: '50px 0px',
  },
  noResults: {
    marginTop: '2.85em',
    marginBottom: '12.85em',
    maxWidth: '400px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  noResultsText: {
    marginTop: '30px',
  },
  orderPending: {
    fontStyle: 'italic',
  },
  orderCanceled: {
    color: primaryPalette.errorPrimary,
  },
  container: {
    backgroundColor: grayPalette.gray5,
    padding: '0px 2.85em 2.14em',
    overflow: 'auto',
    height: '100%',
  },
  title: {
    marginTop: '1.25em',
    marginBottom: '1.66em',
  },
  sectionHeader: {
    marginBottom: '1.42em',
  },
  ordersContainer: {
    marginBottom: '8.57em',
    maxWidth: '900px',
    ':nth-child(1n) > div': {
      boxSizing: 'border-box',
    },
  },
});

export default StatusScreen;
export { noResultsAltText, CONSENT_RECEVED_MESSAGE };
