import React, { CSSProperties, Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { createDynamicStyleObject } from '../../../utils';
import { textJustificationTypes, ContentFieldDto } from '../../../api/ContentTypes';
import { fonts } from '../../../constants/styles';

enum RichContentStyleTypes {
  bold = 'bold',
  header = 'header',
  underline = 'underline',
  italic = 'italic',
}

export const mapTextJustifyToTextAlign = (textJustification: textJustificationTypes): string => {
  switch (textJustification) {
    case textJustificationTypes.FLEX_START:
      return 'left';
    case textJustificationTypes.FLEX_END:
      return 'right';
    default:
      return 'center';
  }
};

const Line = ({
  textObj,
  lineStyle,
  children,
}: {
  textObj: { text: string; styles?: string[] };
  lineStyle?: CSSProperties[] | CSSProperties;
  children?: React.ReactNode;
}) => (
  <div className={css([lineStyle, textObj.styles?.map((style) => dynamicStyles[style])])}>
    {children ?? textObj.text}
  </div>
);

const ParagraphContent = ({
  content,
  lineStyle,
}: {
  content: ContentFieldDto;
  lineStyle?: CSSProperties[] | CSSProperties;
}) => {
  return (
    <div className={css(lineStyle)}>
      {content.paragraph.map((textObj, i) => (
        <Line key={i} textObj={textObj} lineStyle={lineStyle} />
      ))}
    </div>
  );
};

const Paragraph = ({
  content,
  indent,
  lineStyle,
  viewStyle,
}: {
  content: ContentFieldDto;
  indent?: number;
  lineStyle?: CSSProperties[] | CSSProperties;
  viewStyle?: CSSProperties[] | CSSProperties;
}) => (
  <div
    className={css([
      viewStyle,
      RichContentStyles.richTextView,
      content.justify ? createDynamicStyleObject('textAlign', mapTextJustifyToTextAlign(content.justify)) : undefined,
      indent ? createDynamicStyleObject('marginLeft', indent) : undefined,
    ])}
  >
    <ParagraphContent content={content} lineStyle={lineStyle} />
  </div>
);

export function RichText({
  content,
  indent,
  bulletPoint,
  letterBulletPoint,
  lineStyle,
  viewStyle,
}: {
  content: ContentFieldDto;
  bulletPoint?: boolean;
  letterBulletPoint?: string;
  indent?: number;
  lineStyle?: CSSProperties[] | CSSProperties;
  viewStyle?: CSSProperties[] | CSSProperties;
}): JSX.Element {
  if (bulletPoint || letterBulletPoint) {
    return (
      <div className={css(RichContentStyles.bulletPointWrapper)}>
        <div className={css([RichContentStyles.appText, fonts.ibmPlexSansBook, RichContentStyles.bulletPoint])}>
          {letterBulletPoint ? `${letterBulletPoint}.` : '•'}
        </div>
        <Paragraph content={content} lineStyle={lineStyle} viewStyle={viewStyle} indent={indent} />
      </div>
    );
  } else {
    return <Paragraph content={content} lineStyle={lineStyle} viewStyle={viewStyle} indent={indent} />;
  }
}

export function RichTextLines({
  lines,
  style,
  lineStyle,
  viewStyle,
  doubleSpace = true,
  headerStyle,
}: {
  lines: ContentFieldDto[];
  style?: CSSProperties[] | CSSProperties;
  viewStyle?: CSSProperties[] | CSSProperties;
  lineStyle?: CSSProperties[] | CSSProperties;
  doubleSpace?: boolean; // Add a blank line between paragraphs.
  headerStyle?: CSSProperties[] | CSSProperties;
}): JSX.Element {
  return (
    <div className={css(style)}>
      {lines.map((line, i) => (
        <Fragment key={i}>
          <RichText
            content={line}
            viewStyle={viewStyle}
            lineStyle={
              headerStyle && line.paragraph[0].styles?.includes(RichContentStyleTypes.header) ? headerStyle : lineStyle
            }
            letterBulletPoint={line.letterBulletPoint}
            bulletPoint={line.bulletPoint}
            indent={line.indent}
          />
          {doubleSpace && i !== lines.length - 1 && !line.singleSpace && (
            <RichText content={{ paragraph: [{ text: '' }] }} />
          )}
        </Fragment>
      ))}
    </div>
  );
}

const dynamicStyles: Record<string, CSSProperties> = StyleSheet.create({
  bold: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 700,
  },
  header: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 500,
    paddingBottom: '4px',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  italic: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'italic',
    fontWeight: 400,
  },
});

const RichContentStyles = StyleSheet.create({
  richTextView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  bulletPointWrapper: {
    flexDirection: 'row',
  },
  bulletPoint: {
    textAlign: 'right',
    paddingRight: '0.71em',
  },
  appText: {
    lineHeight: '22px',
  },
});
