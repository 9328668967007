import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { primaryPalette } from '@tcl-argon-colors/colors';
import { Warning } from '@tcl-argon-icons/icons';
import { Alert } from '@tcl-argon-prefabs/alert';
import { fonts } from '../../../constants/styles';

const RESULT_UNAVAILABLE = 'result unavailable';

const ResultUnavailableBanner: React.FunctionComponent = () => {
  return (
    <Alert className={css(styles.alert)}>
      {{
        content: (
          <div className={css(styles.container)}>
            <Warning className={css(styles.icon)} />
            <p className={css(styles.text, fonts.ibmPlexSansBold, styles.sectionHeaderBlack)}>{RESULT_UNAVAILABLE}</p>
          </div>
        ),
      }}
    </Alert>
  );
};

export default ResultUnavailableBanner;

const styles = StyleSheet.create({
  alert: {
    backgroundColor: 'rgba(215,48,59,0.1)',
    boxShadow: 'none',
    boxSizing: 'border-box',
    maxWidth: 'none',
    width: 'auto',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: primaryPalette.errorPrimary,
    height: '1.071em',
    marginRight: '0.714em',
    width: '1.071em',
  },
  sectionHeaderBlack: {
    fontSize: '14px',
    letterSpacing: '2px',
    lineHeight: '20px',
  },
  text: {
    margin: 0,
    textTransform: 'uppercase',
  },
});
