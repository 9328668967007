import React from 'react';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import styles from '../../forms/shared/styles.module.css';
import checklistStyles from '../checklistStyles.module.css';
import classNames from 'classnames';
import { ThankYouCheckmark } from '../assets/ThankYouCheckmark';

const ThankYouSection: React.FunctionComponent = () => {
  return (
    <div className={checklistStyles.checklistThankYouSection}>
      <ThankYouCheckmark />
      <div className={classNames(styles.bold, typography.header)}>Thank you!</div>
      <div className={checklistStyles.checklistThankYouText}>
        <p>
          Your forms have been successfully submitted.
          <br />
          If you have questions, please contact
          <a className={checklistStyles.checklistLink} href="tel:800.739.4137">
            &nbsp; 800.739.4137&nbsp;
          </a>
          or
          <a className={checklistStyles.checklistLink} href="mailto:support@tempus.com">
            &nbsp; support@tempus.com
          </a>
          <span>.</span>
        </p>
      </div>
    </div>
  );
};

export default ThankYouSection;
