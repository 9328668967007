type WelcomeHeaderProps = {
  id: string;
  user: any;
};

const WelcomeHeader = (props: WelcomeHeaderProps): HTMLDivElement => {
  const welcomeHeader = document.createElement('p');
  welcomeHeader.id = props.id;
  const welcomeHeaderText = document.createTextNode(
    `Welcome, ${props.user.profile.login}. Please create a password below to continue setting up your account. `,
  );
  welcomeHeader.appendChild(welcomeHeaderText);

  return welcomeHeader;
};

export default WelcomeHeader;
