import React, { StrictMode, useEffect } from 'react';
import { AppNavigationRoutes } from '../api/Navigation';
import { initAnalytics } from '../api/Pendo';
import AppNavigator from './components/navigation/AppNavigator';
import ErrorBoundary from './components/screen/ErrorBoundary';

const App = (): JSX.Element => {
  useEffect(() => {
    initAnalytics();
  }, []);

  return (
    <StrictMode>
      <ErrorBoundary>
        <AppNavigator routes={AppNavigationRoutes} />
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
