import { grayPalette } from '@tcl-argon-colors/colors';
import { css, StyleSheet } from 'aphrodite/no-important';
import { InputTheme } from '@tcl-argon-models/models';
import React, { FunctionComponent } from 'react';
import { styles as textStyles } from '../../../constants/styles';
import { Button } from '@tcl-argon-prefabs/button';
import { useHistory } from 'react-router-dom';
import { OktaAuthNavigationPath } from '../../../api/Navigation';
import { logOutAndClearStorage, OktaSignOutState } from '../../../api/Okta';

const SIGN_OUT = 'Sign out';
const TERMS_OF_USE = 'Terms of Use';
const PRIVACY_POLICY = 'Privacy Policy';
const NOTICE_OF_PRIVACY_PRACTICES = 'Notice of Privacy Practices';

const Footer: FunctionComponent = () => {
  const { push: navigate } = useHistory();

  return (
    <div className={css(styles.container)}>
      <Button
        className={css(styles.button)}
        onClick={() => {
          logOutAndClearStorage(OktaSignOutState.SIGNED_OUT).catch(() => {
            navigate(OktaAuthNavigationPath.LOGIN);
          });
        }}
        theme={InputTheme.Dark}
      >
        <p className={css(styles.text)}>{SIGN_OUT}</p>
      </Button>
      <ul className={css(styles.linkContainer)}>
        <li>
          <a
            className={css(textStyles.link, textStyles.bodyText, styles.link)}
            href="/terms"
            target="_blank"
            rel="noreferrer"
          >
            {TERMS_OF_USE}
          </a>
        </li>
        <li>
          <a
            className={css(styles.link, textStyles.link, textStyles.bodyText, styles.link)}
            href="https://www.tempus.com/privacypolicy/"
            target="_blank"
            rel="noreferrer"
          >
            {PRIVACY_POLICY}
          </a>
        </li>
        <li>
          <a
            className={css(styles.link, textStyles.link, textStyles.bodyText, styles.link)}
            href="https://www.tempus.com/notice-of-privacy-practices/"
            target="_blank"
            rel="noreferrer"
          >
            {NOTICE_OF_PRIVACY_PRACTICES}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Footer;

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: '#000000',
    display: 'flex',
    maxWidth: '21.42em',
    justifyContent: 'center',
  },
  container: {
    display: 'block',
    borderTop: `0.07em solid ${grayPalette.gray30}`,
    padding: '2.14em 2.14em 2.14em 2.14em',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  link: { color: 'black', textDecoration: 'underline' },
  linkContainer: {
    listStyleType: 'none',
    padding: 0,
    marginTop: '2.14em',
  },
  text: {
    margin: 0,
  },
});
