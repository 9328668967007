import React from 'react';

export const BlueCheckmark = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="blue-checkmark">
            <circle cx="10" cy="10" r="10" fill="#206EF3"/>
            <g clipPath="url(#clip0_128_6437)">
                <path fillRule="evenodd" clipRule="evenodd" 
                d="M5.25107 10.6652H5.25162H5.25107ZM8.4148 15.1724C8.40981 15.1724 8.40537 15.1724 8.40037 
                15.1719C8.15398 15.1658 7.93533 15.0169 7.8421 14.7915C7.32156 13.5322 5.69225 10.9659 5.21666 
                10.6526C4.91533 10.5333 4.7555 10.1997 4.85928 9.89021C4.96694 9.56921 5.31655 9.39552 5.64175 
                9.5016C6.39425 9.74895 7.65286 11.7849 8.40481 13.1667C9.72891 10.502 12.0841 6.10911 13.366 
                4.38758C13.5686 4.1144 13.9565 4.05669 14.2312 4.25786C14.5064 4.45849 14.5647 4.84215 14.3621 
                5.11478C12.9809 6.96988 10.0902 12.4516 8.97585 14.8184C8.87374 15.0345 8.65509 15.1724 8.4148 15.1724Z" 
                fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_128_6437">
                    <rect width="11.0345" height="11.0345" fill="white" transform="translate(4.13818 4.13794)"/>
                </clipPath>
            </defs>
        </svg>
    );
};