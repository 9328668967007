import React, { useEffect, Fragment } from 'react';
import { useAsync, AsyncState } from 'react-async';
import { useOktaAuth } from '@okta/okta-react';
import { createGenericContext } from '../utils';
import { fetchHelper } from './fetchHelper/fetchHelper';
import Loading from '../app/components/screen/Loading';
import ServiceUnavailable from '../app/components/screen/ServiceUnavailable';

type OrdersLoaderProps = { children: JSX.Element };

export const RESULTS_NOT_FOUND_ERROR_TITLE = 'Results not available';

export enum ReportStatus {
  PENDING = 'PENDING',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export enum Assay {
  SarsCov2 = 'sars-cov-2.v1',
  SalivaDirect = 'sars-cov-2-saliva-direct.v1',
}

export type Report = {
  manifestId?: string;
  assayName: Assay;
  status: ReportStatus;
  deliveredDate?: string;
  isAmended: boolean;
  reportName: {
    shortName: string;
    longName: string;
    description: string;
  };
  dynamicRendering: string[];
  factSheet: string;
};

export type Order = {
  orderId: string;
  fullName: string;
  sampleCollectionDate?: string;
  providerPhone?: string;
  testingSite?: string;
  orderCity?: string;
  orderState?: string;
  orderingInstitution: string;
  zip?: string;
  reports: Report[];
};

export type OrderState = {
  isLoading: boolean;
  error?: Error;
  data?: Order;
};

type AssayNameType = {
  [key in Assay]: string;
};

export const AssayName: AssayNameType = {
  [Assay.SarsCov2]: 'iC SARS-CoV-2',
  [Assay.SalivaDirect]: 'SalivaDirect™ SARS-CoV-2',
};

type ReportStatusTextType = {
  [key in ReportStatus]: string;
};

export const ReportStatusText: ReportStatusTextType = {
  [ReportStatus.PENDING]: 'Pending',
  [ReportStatus.DELIVERED]: 'Delivered',
  [ReportStatus.CANCELED]: 'Canceled',
};

const fetchOrders = async (): Promise<Order[]> => {
  return fetchHelper('/v2/orders');
};

export const [useOrdersState, OrdersProvider] = createGenericContext<AsyncState<Order[]>>();
export const useOrders = (): Order[] | undefined => {
  const { data: orders } = useOrdersState();
  return orders;
};

export const useOrder = (orderId: string): OrderState => {
  try {
    const ordersState = useOrdersState();
    const orders = ordersState.data as Order[] | undefined;

    const order = orders && orders.find((order) => order.orderId === orderId);

    if (!order) {
      throw new Error('invalid orderId');
    }

    return {
      isLoading: false,
      data: order,
    };
  } catch (e) {
    return {
      isLoading: false,
      error: e,
    };
  }
};

export const OrdersLoader: React.FC = ({ children }) => {
  const { authState } = useOktaAuth();
  const state = useAsync<Order[]>({ deferFn: fetchOrders });
  const { run } = state;
  useEffect(() => {
    if (authState?.accessToken?.accessToken) {
      run();
    }
  }, [authState?.accessToken?.accessToken]);

  if (state.isRejected) {
    return <ServiceUnavailable title={RESULTS_NOT_FOUND_ERROR_TITLE} />;
  }

  return <OrdersProvider value={state}>{children}</OrdersProvider>;
};

export const AwaitOrders = ({ children }: OrdersLoaderProps): JSX.Element | null => {
  const state = useOrdersState();
  return state.isSettled ? <Fragment>{children}</Fragment> : <Loading />;
};
