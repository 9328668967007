import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../api/hooks';
import { NavigationPath } from '../../api/Navigation';
import Result from './Result';

const ResultWrapper: FunctionComponent = () => {
  const history = useHistory();
  const orderId = useQuery('order');
  const manifestId = useQuery('report');

  useEffect(() => {
    if (!orderId || !manifestId) {
      history.push(NavigationPath.ORDERS);
    }
  }, [orderId, manifestId]);

  return orderId && manifestId ? <Result orderId={orderId} manifestId={manifestId} /> : null;
};

export default ResultWrapper;
