import { fetchHelper } from './fetchHelper/fetchHelper';

export interface PatientConsentDto {
  consented: boolean;
  contentVersion: string;
  signature?: string;
  physicianName?: string;
  institution?: string;
  cityState?: string;
  fullName?: string;
  email?: string;
}

export interface PatientFormDto {
  consented: boolean;
  diseaseType: string;
  formType: string;
  formVersion: string;
  form?: {
    firstName: string;
    middleName?: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    email: string;
    phoneNumbers: string[];
    street1: string;
    street2?: string;
    city: string;
    state: string;
    postalCode: string;
    medicalRecordNumber: string;
    pcpPhysicianName?: string;
    pcpInstitution?: string;
    pcpCityState?: string;
    signature: string;
    signatureType: string;
    diseaseType: string;
    formType: string;
    formVersion: string;
    dateActive: Date;
    dateExpired?: Date;
    dateSubmitted: string;
  };
}

export interface PatientFormPCPDto {
  pcpPhysicianName?: string;
  pcpInstitution?: string;
  pcpCityState?: string;
}

export const setConsentFormLegacy = async (form: PatientFormPCPDto) => {
  return fetchHelper('/consents', {
    method: 'POST',
    body: form,
  });
};

export const setConsentForm = async (form: PatientFormDto) => {
  return fetchHelper('/v3/consents', {
    method: 'POST',
    body: form,
  });
};

export const setPCPOnConsentForm = async (form: PatientFormDto) => {
  return fetchHelper('/v3/consents/pcp', {
    method: 'PUT',
    body: form,
  });
};
