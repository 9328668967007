import React, { useRef, useState } from 'react';
import OktaSignInWidget, { isFailedLogin } from '../../app/components/okta/OktaSignInWidget';
import Modal from './components/Modal';
import ContentLayout from '../../app/components/screen/ContentLayout';
import { FunctionComponent } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { oktaWidgetDefaultConfiguration } from '../../api/Okta';
import { Page } from '../../app/components/okta/OktaSignInWidget';
import Error, { ERROR_STYLES } from '../../app/components/screen/Error';
import { css } from 'aphrodite';
import { styles } from '../../constants/styles';
import { ERROR_BANNER_STYLES } from '../../app/components/screen/ErrorBanner';

const MAX_LOGIN_ATTEMPTS = 5;
const LOCKED_OUT_TITLE = 'ACCOUNT LOCKED';
const LOCKED_OUT_DISCLAIMER =
  'Your account has been locked for security. If there is an account associated with your email, we will send you information on how to unlock your account.';
const RESET_PASSWORD_MODAL_CONTENT =
  'Please type the email address associated with your account below, and Tempus will send you a link to reset your password.';
const RESET_PASSWORD_MODAL_SEND_LINK_BUTTON_TEXT = 'Send Link';
const RESET_PASSWORD_MODAL_TITLE = 'Reset password';
const RESET_PASSWORD_SUCCESS_MODAL_TITLE = 'Reset link sent';
const RESET_PASSWORD_SUCCESS_MODAL_CONTENT =
  'If there is an account associated with your email, a link will be sent to reset your password.';

const ACTIVATE_ACCOUNT_MODAL_CONTENT =
  'You should have received an order confirmation email from Tempus after your sample was collected with a link to activate your account. You may resend that link by entering your email below.';
const ACTIVATE_ACCOUNT_MODAL_SEND_LINK_BUTTON_TEXT = 'Resend activation email';
const ACTIVATE_ACCOUNT_MODAL_TITLE = 'Create account';
const ACTIVATE_ACCOUNT_SUCCESS_MODAL_TITLE = 'Activation link sent';
const ACTIVATE_ACCOUNT_SUCCESS_MODAL_CONTENT =
  'If there is an account associated with your email, a link will be sent to activate your account.';

const LOGIN_DISCLAIMER_TEXT = 'By signing in, I agree to the ';

const oktaSignInWidgetConfiguration: OktaSignInConfig = {
  ...oktaWidgetDefaultConfiguration,
  i18n: {
    en: {
      'primaryauth.title': 'Welcome',
    },
  },
  features: {
    rememberMe: false,
  },
};

const Login: FunctionComponent = () => {
  const [lockedOut, setLockedOut] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [failedAttempts, setFailedAttemptsState] = useState<number>(0);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState<boolean>(false);
  const [activateAccountModalVisible, setActivateAccountModalVisible] = useState<boolean>(false);
  const { oktaAuth } = useOktaAuth();

  const failedAttemptsRef = useRef<number>(failedAttempts);
  const setFailedAttempts = (value: number) => {
    failedAttemptsRef.current = value;
    setFailedAttemptsState(value);
  };

  const onSessionExpired = () => {
    setError(true);
  };

  const onSuccess = (tokens?: Tokens) => {
    if (tokens?.accessToken?.accessToken) {
      oktaAuth.handleLoginRedirect(tokens).catch(() => {
        setError(true);
      });
    }
  };

  const onError = (error?: OktaError) => {
    if (isFailedLogin(error)) {
      const newFailedAttempts = failedAttemptsRef.current + 1;
      if (newFailedAttempts === MAX_LOGIN_ATTEMPTS) {
        setLockedOut(true);
      }
      setFailedAttempts(newFailedAttempts);
    } else {
      setError(true);
    }
  };

  if (error) {
    return <Error />;
  }

  if (lockedOut) {
    return (
      <Error
        disclaimer={<div className={css(ERROR_STYLES.errorText, styles.bodyText)}>{LOCKED_OUT_DISCLAIMER}</div>}
        title={<div className={css(ERROR_BANNER_STYLES.text, styles.sectionHeaderBlack)}>{LOCKED_OUT_TITLE}</div>}
      />
    );
  }

  return (
    <ContentLayout>
      <Modal
        close={() => {
          setResetPasswordModalVisible(false);
        }}
        content={{
          content: RESET_PASSWORD_MODAL_CONTENT,
          submitButton: RESET_PASSWORD_MODAL_SEND_LINK_BUTTON_TEXT,
          title: RESET_PASSWORD_MODAL_TITLE,
          successTitle: RESET_PASSWORD_SUCCESS_MODAL_TITLE,
          successContent: RESET_PASSWORD_SUCCESS_MODAL_CONTENT,
        }}
        isOpen={resetPasswordModalVisible}
      />
      <Modal
        close={() => {
          setActivateAccountModalVisible(false);
        }}
        content={{
          content: ACTIVATE_ACCOUNT_MODAL_CONTENT,
          submitButton: ACTIVATE_ACCOUNT_MODAL_SEND_LINK_BUTTON_TEXT,
          title: ACTIVATE_ACCOUNT_MODAL_TITLE,
          successTitle: ACTIVATE_ACCOUNT_SUCCESS_MODAL_TITLE,
          successContent: ACTIVATE_ACCOUNT_SUCCESS_MODAL_CONTENT,
        }}
        isOpen={activateAccountModalVisible}
      />
      <OktaSignInWidget
        termsOfUseDisclaimer={LOGIN_DISCLAIMER_TEXT}
        activateAccount={() => {
          setActivateAccountModalVisible(true);
        }}
        config={oktaSignInWidgetConfiguration}
        onError={onError}
        onSuccess={onSuccess}
        onSessionExpired={onSessionExpired}
        setEmail={() => setFailedAttempts(0)}
        page={Page.LOGIN}
        resetPassword={() => {
          setResetPasswordModalVisible(true);
        }}
      />
    </ContentLayout>
  );
};

export default Login;
export { LOGIN_DISCLAIMER_TEXT };
