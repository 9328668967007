import ResetPassword from '../screens/auth/ResetPassword/ResetPassword';
import CreatePassword from '../screens/auth/CreatePassword/CreatePassword';
import TermsOfUse from '../screens/disclaimers/termsOfUse/TermsOfUse';
import FAQ from '../screens/disclaimers/faq/FAQ';
import ConsentReceipt from '../screens/consent/ConsentReceipt';
import IdentityVerification from '../screens/identityVerification/IdentityVerificationWrapper';
import Account from '../screens/account/Account';
import ConsentScreen from '../screens/consent/ConsentScreen';
import PhysicianInfoFormWrapper from '../screens/consent/PhysicianInfoFormWrapper';
import { HeaderType } from '../app/components/header/headerType';
import Result from '../screens/result';
import StatusScreen from '../screens/orders/StatusScreen';

export type NavigationRoute = {
  component: React.ComponentType;
  header?: HeaderType;
  path: NavigationPath | OktaAuthNavigationPath;
  authState: AuthenticationState;
};

export type AppNavigationType = {
  [key in AuthenticationState]: NavigationRoute[];
};

enum NavigationPath {
  RESET_PASSWORD = '/resetPassword',
  REGISTER = '/register',
  TERMS = '/terms',
  FAQ = '/faq',
  VERIFY = '/verify',
  CONSENT = '/consent',
  CONSENT_RECEIPT = '/consent-receipt',
  ACCOUNT = '/account',
  PCP = '/pcp',
  RESULT = '/result',
  ORDERS = '/orders',
}

enum AuthenticationState {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
  VERIFIED = 'VERIFIED',
}

enum OktaAuthNavigationPath {
  LOGIN = '/',
  CALLBACK = '/callback',
}

const AppNavigationRoutes: AppNavigationType = {
  [AuthenticationState.VERIFIED]: [
    {
      component: ConsentScreen,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.CONSENT,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: ConsentReceipt,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.CONSENT_RECEIPT,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: Account,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.ACCOUNT,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: PhysicianInfoFormWrapper,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.PCP,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: StatusScreen,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.ORDERS,
      authState: AuthenticationState.VERIFIED,
    },
    {
      component: Result,
      header: HeaderType.AUTHENTICATED_HEADER,
      path: NavigationPath.RESULT,
      authState: AuthenticationState.VERIFIED,
    },
  ],
  [AuthenticationState.AUTHENTICATED]: [
    {
      component: IdentityVerification,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.VERIFY,
      authState: AuthenticationState.AUTHENTICATED,
    },
  ],
  [AuthenticationState.UNAUTHENTICATED]: [
    {
      component: ResetPassword,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.RESET_PASSWORD,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
    {
      component: CreatePassword,
      header: HeaderType.UNAUTHENTICATED_HEADER,
      path: NavigationPath.REGISTER,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
    {
      component: TermsOfUse,
      path: NavigationPath.TERMS,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
    {
      component: FAQ,
      path: NavigationPath.FAQ,
      authState: AuthenticationState.UNAUTHENTICATED,
    },
  ],
};

export { AppNavigationRoutes, AuthenticationState, NavigationPath, OktaAuthNavigationPath };
