import React from 'react';
import { Route } from 'react-router-dom';
import ScreenLayout from '../screen/ScreenLayout';
import { NavigationRoute } from '../../../api/Navigation';

export type RouteWrapperProps = NavigationRoute;

const UnauthenticatedRoute: React.FC<RouteWrapperProps> = (props) => {
  return (
    <Route
      path={props.path as string}
      render={() => {
        return (
          <ScreenLayout header={props.header}>
            <props.component />
          </ScreenLayout>
        );
      }}
    />
  );
};

export default UnauthenticatedRoute;
