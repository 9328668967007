import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { Fail } from '@tcl-boron-icons/icons';
import { Alert } from '@tcl-boron-prefabs/alert';
import React from 'react';
import styles from './FormError.module.css';

const FormError: React.FC = () => {
  return (
    <Alert data-test-id="error-alert" status="error" className={styles.alert}>
      <div className={styles.error}>
        <Fail className={styles.icon} height={20} width={20} color={GrayscalePalette[100]} />
        <p className={styles.text}>
          Incorrect date of birth. Please try again. If you believe something is wrong, please call us at 312.598.9961
        </p>
      </div>
    </Alert>
  );
};

export default FormError;
