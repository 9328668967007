import { Banner } from '@tcl-boron-prefabs/banner';
import { Fail } from '@tcl-boron-icons/icons';
import React from 'react';
import styles from './styles.module.css';

type ErrorBannerProps = {
  errorMessage?: string;
};

const FormBannerError: React.FunctionComponent<ErrorBannerProps> = ({ errorMessage }) => {
  return (
    <div className={styles.formBanner}>
      <Banner
        status="error"
        ariaLabel="Submission Failed"
        message={errorMessage ?? 'Submission failed. Please try again.'}
        Icon={Fail}
      />
    </div>
  );
};

export default FormBannerError;
