import React, { Fragment, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import { ChevronRight } from '@tcl-argon-icons/icons';
import { Card } from '@tcl-boron-prefabs/card';
import { Assay } from '../../../api/result/ResultType';
import { Order } from '../../../api/Orders';
import { useQuery } from '../../../api/hooks';

type FactSheetProps = {
  order: Order;
};

const FDA_FACTSHEET_DISCLAIMER = 'For more information, please read the FDA fact sheet.';
const FDA_FACTSHEET_RESOURCE_TEXT = 'View FDA fact sheet';

const FDA_LINK_MAP = {
  [Assay.SarsCov2]: 'https://www.fda.gov/media/142595/download',
  [Assay.SalivaDirect]: 'https://www.fda.gov/media/142595/download',
};

const FactSheet: FunctionComponent<FactSheetProps> = (props) => {
  const reportId = useQuery('report');
  const selectedReport = props.order.reports.find((report) => report.manifestId === reportId);
  return (
    <Fragment>
      <div className={css(styles.disclaimer)}>{FDA_FACTSHEET_DISCLAIMER}</div>
      <div className={css(styles.cardContainer)}>
        <a target="_blank" rel="noreferrer" className={css(styles.link)} href={FDA_LINK_MAP[selectedReport!.assayName]}>
          <Card>
            <div className={css(styles.content)}>
              <div className={css(styles.resource)}>{FDA_FACTSHEET_RESOURCE_TEXT}</div>
              <ChevronRight className={css(styles.resourceIcon)} />
            </div>
          </Card>
        </a>
      </div>
    </Fragment>
  );
};

export default FactSheet;

const styles = StyleSheet.create({
  cardContainer: {
    maxWidth: '50em',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  disclaimer: {
    color: grayPalette.gray70,
    fontSize: '12px',
    lineHeight: '14px',
    paddingBottom: '1.071em',
  },
  link: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  },
  resource: {
    display: 'inline',
    fontSize: '14px',
    lineHeight: '16px',
  },
  resourceIcon: {
    height: '1em',
    width: '1em',
  },
});
