import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from '@tcl-argon-icons/icons';
import { css, StyleSheet } from 'aphrodite/no-important';
import { NavigationPath } from '../../../api/Navigation';
import { fonts } from '../../../constants/styles';

const BACK_BUTTON_TEXT = 'my results';

const BackButton: FunctionComponent = () => {
  const history = useHistory();

  const navigateToOrdersScreen = () => {
    history.push(NavigationPath.ORDERS);
  };

  return (
    <div className={css(styles.container, styles.link)} onClick={navigateToOrdersScreen}>
      <ArrowLeft className={css(styles.arrowLeft)} />
      <div className={css(fonts.ibmPlexSansBold, styles.sectionHeaderBlack, styles.text)}>{BACK_BUTTON_TEXT}</div>
    </div>
  );
};

export default BackButton;

const styles = StyleSheet.create({
  arrowLeft: {
    height: '0.875em',
    width: '0.875em',
    marginRight: '0.849em',
  },
  container: {
    display: 'inline-block',
  },
  link: {
    cursor: 'pointer',
  },
  sectionHeaderBlack: {
    fontSize: '14px',
    letterSpacing: '2px',
    lineHeight: '16px',
  },
  text: {
    display: 'inline-block',
    textTransform: 'uppercase',
  },
});
