import React from 'react';
import DisclaimerNoticeHeader from './DisclaimerNoticeHeader';
import DisclaimerNoticeFooter from './DisclaimerNoticeFooter';
import PropTypes from 'prop-types';

const DisclaimerNoticeWrapper: React.FC = (props) => (
  <React.Fragment>
    <DisclaimerNoticeHeader /> {props.children} <DisclaimerNoticeFooter />
  </React.Fragment>
);

DisclaimerNoticeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DisclaimerNoticeWrapper;
