import React from 'react';
import svg from './NotFound.svg';
import styles from './styles.module.css';

const NotFound = () => (
  <div className={styles.NotFound}>
    <img src={svg} alt="Page Not Found" />
    <h2>Page Not Found</h2>
    <span>The page was deleted, the URL is incorrect, or some other error occured.</span>
  </div>
);

export default NotFound;
