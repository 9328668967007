import React from 'react';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  FULL_NAME_CHARACTER_LIMIT,
  MEDIUM_TEXT_CHARACTER_LIMIT,
  orderingPhysicianV2Fields as orderingPhysicianV2FieldsBase,
} from '@tempus/patient-forms-service-shared';

import FormTable, { FormTableFieldFrontend } from '../shared/FormTable';
import { combineFormConfigs } from '../shared/utils';
import { CHARACTER_LIMIT_WARNING, REQUIRED_FIELD_WARNING } from '../shared/validation-utils';

export const orderingPhysicianV2FieldsFrontend: FormTableFieldFrontend[] = [
  {
    name: 'institution',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > MEDIUM_TEXT_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
  {
    name: 'physicianName',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > FULL_NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
];

export const orderingPhysicianV2Fields = combineFormConfigs(
  orderingPhysicianV2FieldsFrontend,
  orderingPhysicianV2FieldsBase,
);

const OrderingPhysicianV2Table: React.FunctionComponent = () => {
  return (
    <>
      <div className={typography.subSectionHeader}>Ordering Physician and Institution</div>
      <FormTable fields={orderingPhysicianV2Fields} />
    </>
  );
};

export default OrderingPhysicianV2Table;
