import React from 'react';
import styles from './styles.module.css';
import Logo from './Logo';

const LogoBanner: React.FunctionComponent = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLogo}>
        <Logo />
      </div>
    </div>
  );
};

export default LogoBanner;
