import React, { FunctionComponent, useCallback, useState } from 'react';
import AppInput from './AppInput';
import isEmail from 'validator/lib/isEmail';
import { css, StyleSheet } from 'aphrodite/no-important';
import { useAsync } from 'react-async';
import { grayPalette } from '@tcl-argon-colors/colors';
import { Button } from '@tcl-argon-prefabs/button';
import { Modal as TCLModal } from '@tcl-argon-prefabs/modal';
import { fonts, styles, text } from '../../../constants/styles';
import { fetchHelper } from '../../../api/fetchHelper/fetchHelper';
import ErrorBanner from '../../../app/components/screen/ErrorBanner';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';
import Config from '../../../../config';
import { AuthenticationType } from '../../../api/AuthenticationType';

type ModalContentProps = {
  close: () => void;
  content: {
    content: string;
    submitButton: string;
    title: string;
    successTitle: string;
    successContent: string;
  };
  isOpen: boolean;
};

const CANCEL_BUTTON_TEXT = 'Cancel';
const CLOSE_BUTTON_TEXT = 'Okay';
const EMAIL_INPUT_LABEL = 'Email*';
const EMAIL_INPUT_PLACEHOLDER = 'Enter email';
const ERROR_TEXT = 'Something went wrong. Please try again later';
const MODAL_SIZE_BROWSER = 'medium';
const MODAL_SIZE_MOBILE = 'small';

const ModalContent: FunctionComponent<ModalContentProps> = (props) => {
  const [email, setEmail] = useState<string>('');
  const { run: submit, isLoading, isRejected, isFulfilled } = useAsync({
    deferFn: useCallback(async () => {
      await fetchHelper('/support/notifyUserByAccountStatus', {
        method: 'POST',
        body: { email },
        authenticationType: AuthenticationType.NONE,
      });
    }, [email]),
  });

  const content = props.content;
  const submitEnabled = !isLoading && isEmail(email);

  if (isFulfilled) {
    return (
      <>
        <p className={css(styles.sectionHeader)}>{content.successTitle}</p>
        <p className={css(styles.bodyText)}>{content.successContent}</p>
        <div className={css(MODAL_STYLES.buttonContainer)}>
          <Button
            className={css(
              MODAL_STYLES.button,
              MODAL_STYLES.submitButton,
              fonts.ibmPlexSansMedium,
              text.supportingBodyText,
            )}
            disabled={!submitEnabled}
            onClick={props.close}
          >
            {CLOSE_BUTTON_TEXT}
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <p className={css(styles.sectionHeader)}>{content.title}</p>
      <p className={css(styles.bodyText)}>{content.content}</p>
      <AppInput
        label={EMAIL_INPUT_LABEL}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        placeholder={EMAIL_INPUT_PLACEHOLDER}
        value={email}
      />
      {isRejected && (
        <div className={css(MODAL_STYLES.errorContainer)}>
          <ErrorBanner title={<div className={css(MODAL_STYLES.errorText, styles.error)}>{ERROR_TEXT}</div>} />
        </div>
      )}
      <div className={css(MODAL_STYLES.buttonContainer)}>
        <Button
          className={css(
            MODAL_STYLES.button,
            MODAL_STYLES.cancelButton,
            fonts.ibmPlexSansMedium,
            text.supportingBodyText,
          )}
          disabled={isLoading}
          onClick={props.close}
        >
          {CANCEL_BUTTON_TEXT}
        </Button>
        <Button
          className={css(
            MODAL_STYLES.button,
            MODAL_STYLES.submitButton,
            fonts.ibmPlexSansMedium,
            text.supportingBodyText,
          )}
          disabled={!submitEnabled}
          onClick={submit}
        >
          {content.submitButton}
        </Button>
      </div>
    </>
  );
};

const Modal: FunctionComponent<ModalContentProps> = (props) => {
  const window = useWindowDimensions();
  const isMobile = isMobileDevice(window);
  const isTestEnvironment = Config.environment === 'test';

  return (
    <TCLModal
      ariaHideApp={!isTestEnvironment}
      classes={{ content: css(MODAL_STYLES.modalContentContainer), title: css(styles.sectionHeader) }}
      className={css(isMobile && MODAL_STYLES.modalContainerMobile)}
      isOpen={props.isOpen}
      size={isMobile ? MODAL_SIZE_MOBILE : MODAL_SIZE_BROWSER}
      title={''}
    >
      <ModalContent {...props} />
    </TCLModal>
  );
};

const MODAL_STYLES = StyleSheet.create({
  button: {
    marginLeft: '1.07em',
  },
  buttonContainer: {
    marginTop: '2.14em',
    textAlign: 'right',
    width: '100%',
  },
  cancelButton: {
    border: '0px',
    borderRadius: '0.21em',
    backgroundColor: grayPalette.gray40,
    color: '#000000',
    ':active': {
      border: '0px',
      boxShadow: 'none',
      backgroundColor: grayPalette.gray20,
      color: '#000000',
    },
    ':focus': {
      border: '0px',
      backgroundColor: grayPalette.gray40,
      color: '#000000',
    },
    ':hover': {
      border: '0px',
      backgroundColor: grayPalette.gray20,
      color: '#000000',
    },
  },
  errorContainer: {
    marginTop: '1.07em',
  },
  errorText: {
    marginLeft: '0.36em',
    margintop: '0.14em',
  },
  modalContainerMobile: {
    maxWidth: '20em',
  },
  modalContentContainer: {
    marginBottom: '0px',
  },
  submitButton: {
    border: '0px',
    borderRadius: '0.21em',
    backgroundColor: grayPalette.gray100,
    color: '#FFFFFF',
    ':active': {
      border: '0px',
      boxShadow: 'none',
      backgroundColor: grayPalette.gray80,
    },
    ':focus': {
      border: '0px',
      backgroundColor: grayPalette.gray80,
    },
    ':hover': {
      border: '0px',
      backgroundColor: grayPalette.gray80,
    },
    ':disabled': {
      opacity: 1,
      backgroundColor: grayPalette.gray80,
    },
  },
});

export default Modal;
