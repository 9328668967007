import { css, StyleSheet } from 'aphrodite/no-important';
import React, { FunctionComponent } from 'react';
import { createRef } from 'react';
import PDFViewer from './PDFViewer';

type PDFReportProps = {
  pdf: Blob;
};

const PDFReport: FunctionComponent<PDFReportProps> = (props) => {
  const containerRef = createRef<HTMLDivElement>();
  return (
    <div className={css(styles.container)} ref={containerRef}>
      <PDFViewer containerRef={containerRef} pdf={props.pdf} />
    </div>
  );
};

export default PDFReport;

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
  },
});
