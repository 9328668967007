import moment from 'moment';
import React, { Fragment, FunctionComponent } from 'react';
import { InputTheme } from '@tcl-argon-models/models';
import { Button } from '@tcl-argon-prefabs/button';
import { css, StyleSheet } from 'aphrodite';
import PDFReport from './PDFReport';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';
import { Order } from '../../../api/Orders';
import { ResultType } from '../../../api/result/ResultType';
import { ICResultWrapper } from '../../../api/result/Result';
import { fonts } from '../../../constants/styles';

type ReportProps = {
  order: Order;
  result?: ICResultWrapper;
  results: ResultType[];
  pdf: Blob;
};

const DOWNLOAD_REPORT = 'Download Report';
const DOWNLOAD_AMENDMENT = 'Download Amendment';

const Report: FunctionComponent<ReportProps> = (props) => {
  const windowDemension = useWindowDimensions();

  const downloadUrl = window.URL.createObjectURL(props.pdf);
  const buttonContainer = isMobileDevice(windowDemension)
    ? styles.mobileButtonContainer
    : styles.browserButtonContainer;

  return (
    <Fragment>
      <div className={css(buttonContainer)}>
        <a
          href={downloadUrl}
          download={`result_${moment(
            props.result ? props.result.getCollectionDate() : props.order.sampleCollectionDate,
          ).format('YYYY-MM-DD')}`}
        >
          <Button className={css(styles.button)} theme={InputTheme.Dark}>
            <div className={css(styles.buttonText, fonts.ibmPlexSansMedium)}>
              {props.results.length > 1 ? DOWNLOAD_AMENDMENT : DOWNLOAD_REPORT}
            </div>
          </Button>
        </a>
      </div>
      <PDFReport pdf={props.pdf} />
    </Fragment>
  );
};

export default Report;

const styles = StyleSheet.create({
  browserButtonContainer: {
    padding: '2.143em 2.857em',
    textAlign: 'right',
  },
  button: {
    backgroundColor: 'black',
  },
  buttonText: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  mobileButtonContainer: {
    padding: '1.429em 2.143em',
    textAlign: 'center',
  },
});
