import React from 'react';

export const ErrorIcon: React.FC = () => (
  <svg
    width="103px"
    height="103px"
    viewBox="0 0 103 103"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Illustration/Web/Error</title>
    <defs>
      <ellipse id="path-1" cx="54.8947724" cy="61.720621" rx="19.6412488" ry="19.569953"></ellipse>
      <filter x="-67.5%" y="-34.5%" width="234.9%" height="238.0%" filterUnits="objectBoundingBox" id="filter-2">
        <feOffset dx="0" dy="14" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="6.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.07 0"
          type="matrix"
          in="shadowBlurOuter1"
        ></feColorMatrix>
      </filter>
    </defs>
    <g id="MVP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="TempusPRO-Onboarding" transform="translate(-1031.000000, -1153.000000)">
        <g id="Illustration/Web/Error" transform="translate(1021.500000, 1134.000000)">
          <rect id="Rectangle" x="0" y="0" width="122" height="122"></rect>
          <g id="Group-7-Copy-2" transform="translate(5.674419, 14.186047)">
            <g id="Group-7-Copy" transform="translate(-0.000000, 0.000000)">
              <path
                d="M60.2251516,8.35439528 L105.634864,85.7837772 C107.221817,88.4897351 106.314684,91.9698267 103.608726,93.5567795 C102.737355,94.0678093 101.745462,94.3372093 100.735294,94.3372093 L9.91586871,94.3372093 C6.77889133,94.3372093 4.23586871,91.7941867 4.23586871,88.6572093 C4.23586871,87.6470411 4.50526868,86.6551486 5.01629854,85.7837772 L50.4260112,8.35439528 C52.012964,5.6484374 55.4930556,4.7413045 58.1990135,6.32825724 C59.0364484,6.81938446 59.7340243,7.51696041 60.2251516,8.35439528 Z"
                id="Triangle"
                fill="#F7F8FA"
              ></path>
              <g id="Oval">
                <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-1"></use>
              </g>
              <path
                d="M54.8947724,69.247526 C56.0073429,69.247526 56.9092595,70.1461687 56.9092595,71.2547006 C56.9092595,72.3632326 56.0073429,73.2618753 54.8947724,73.2618753 C53.7822019,73.2618753 52.8802854,72.3632326 52.8802854,71.2547006 C52.8802854,70.1461687 53.7822019,69.247526 54.8947724,69.247526 Z M54.8947724,50.1793667 C55.6801163,50.1793667 56.3255102,50.7763888 56.3987214,51.5397694 L56.4056377,51.6847476 L56.4056377,64.731383 C56.4056377,65.5627819 55.7292003,66.236764 54.8947724,66.236764 C54.1094285,66.236764 53.4640347,65.6397418 53.3908234,64.8763612 L53.3839071,64.731383 L53.3839071,51.6847476 C53.3839071,50.8533487 54.0603446,50.1793667 54.8947724,50.1793667 Z"
                id="Combined-Shape"
                fill="#29293D"
              ></path>
            </g>
          </g>
          <rect id="Rectangle" x="0" y="0" width="122" height="122"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default ErrorIcon;
