import React from 'react';
import Suspended from '../identityVerification/components/Suspended';
import { OktaSignOutState, getOktaSignOutState, removeOktaSignOutState } from '../../api/Okta';
import Login from './Login';
import SignedOut from '../account/component/SignedOut';
import Success from '../../app/components/screen/Success';

const LOGIN_SIGN_OUT_SCREENS: Record<OktaSignOutState, JSX.Element> = {
  [OktaSignOutState.SUCCESS]: <Success />,
  [OktaSignOutState.SUSPENDED]: <Suspended />,
  [OktaSignOutState.SIGNED_OUT]: <SignedOut />,
};

const LoginWrapper = () => {
  const state = getOktaSignOutState();

  React.useEffect(() => {
    if (state) {
      removeOktaSignOutState();
    }
  }, []);

  if (state) {
    return LOGIN_SIGN_OUT_SCREENS[state as OktaSignOutState];
  } else {
    return <Login />;
  }
};

export default LoginWrapper;
