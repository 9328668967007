import React from 'react';
import { AppContext, AppContextType } from '../../App/AppContext';
import styles from './Header.module.css';

const Header: React.FC = () => {
  const { currentAssessment } = React.useContext(AppContext) as AppContextType;
  return (
    <div className={styles.headerContainer}>
      <div className={styles.infoContainer}>
        <div className={styles.info}>
          <h1 className={styles.title}>{currentAssessment?.name || ''}</h1>
          <h2 className={styles.numQuestions}>{currentAssessment?.numQuestions || 0} Questions</h2>
        </div>
      </div>
      <p className={styles.text}>{currentAssessment?.sections[0].sectionTitle || ''}</p>
    </div>
  );
};

export default Header;
