import React from 'react';
import Header from './Header';
import QuestionList from './QuestionList';
import SuccessAlert from './SuccessAlert';
import SubmitButton from './SubmitButton';

const Form: React.FC = () => {
  return (
    <>
      <SuccessAlert />
      <Header />
      <QuestionList />
      <SubmitButton />
    </>
  );
};

export default Form;
