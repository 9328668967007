import React from 'react';

export const ThankYouCheckmark = () => {
    return (
        <svg width="112" height="120" viewBox="0 0 112 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_dd_73_815)">
                <path d="M101 52C101 27.1472 80.8528 7 56 7C31.1472 7 11 27.1472 11 52C11 76.8528 31.1472 97 56 97C80.8528 97 101 76.8528 101 52Z" fill="white"/>
            </g>
            <g clipPath="url(#clip0_73_815)">
                <path fillRule="evenodd" clipRule="evenodd" d="M41.5306 54.7037H41.5323H41.5306ZM51.5655 69C51.5497 69 51.5356 69 51.5198 68.9983C50.7382 68.9791 50.0447 68.5066 49.749 67.7918C48.0979 63.7976 42.9299 55.6574 41.4214 54.6636C40.4656 54.2853 39.9587 53.227 40.2879 52.2455C40.6293 51.2273 41.7383 50.6764 42.7698 51.0129C45.1566 51.7974 49.1488 58.2551 51.5339 62.6382C55.7337 54.1859 63.2041 40.2523 67.2701 34.7918C67.9126 33.9253 69.143 33.7423 70.0143 34.3804C70.8874 35.0167 71.0722 36.2337 70.4297 37.0984C66.0486 42.9826 56.8796 60.3699 53.3451 67.8772C53.0212 68.5624 52.3277 69 51.5655 69Z" fill="#29293D"/>
            </g>
            <defs>
                <filter id="filter0_dd_73_815" x="0.148438" y="0.971354" width="111.703" height="118.938" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="12.0573"/>
                    <feGaussianBlur stdDeviation="5.42578"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_73_815"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="-1.20573"/>
                    <feGaussianBlur stdDeviation="2.41146"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_73_815" result="effect2_dropShadow_73_815"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_73_815" result="shape"/>
                </filter>
                <clipPath id="clip0_73_815">
                    <rect width="35" height="35" fill="white" transform="translate(38 34)"/>
                </clipPath>
            </defs>
        </svg>
    );
};