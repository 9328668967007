import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { isMobileDevice } from '../../../utils';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import Logo from '../header/Logo';
import { Breakpoints } from '../../../constants/styles';

const ContentLayout: React.FC = (props) => {
  const window = useWindowDimensions();

  return (
    <div className={css(AuthScreenLayoutStyles.flexContainer, AuthScreenLayoutStyles.screenContainer)}>
      <div className={css(AuthScreenLayoutStyles.flexContainer, AuthScreenLayoutStyles.contentContainer)}>
        {props.children}
      </div>
      {!isMobileDevice(window) ? (
        <div className={css(AuthScreenLayoutStyles.flexContainer, AuthScreenLayoutStyles.logoWrapper)}>
          <div className={css(AuthScreenLayoutStyles.logoContainer)}>
            <Logo
              style={{
                image: AuthScreenLayoutStyles.image,
                text: AuthScreenLayoutStyles.text,
                textContainer: AuthScreenLayoutStyles.textContainer,
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ContentLayout;

const AuthScreenLayoutStyles = StyleSheet.create({
  contentContainer: {
    flex: '1',
    minWidth: '400px',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      minWidth: '0px',
    },
    overflow: 'auto',
    alignItems: 'center',
    flexDirection: 'column',
  },
  flexContainer: { display: 'flex' },
  logoContainer: {
    marginTop: '20em',
  },
  logoWrapper: {
    flex: '2',
    background: 'linear-gradient(241.5deg, #FFFFFF 0%, #F5F5F5 100%)',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  screenContainer: { flex: '1 1 auto', flexDirection: 'row', overflow: 'hidden' },
  image: {
    height: '2.11em',
    width: '14.68em',
    overflow: 'inherit',
    [Breakpoints.LARGE_DEVICE_BREAKPOINT]: {
      height: '1.54em',
      width: '110.71em',
    },
  },
  text: {
    fontSize: '30px',
    letterSpacing: '3px',
    fontWeight: 500,
    lineHeight: '19px',
    [Breakpoints.LARGE_DEVICE_BREAKPOINT]: {
      fontSize: '23px',
    },
  },
  textContainer: {
    marginLeft: '1.07em',
  },
});
