import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';
import { grayPalette } from '@tcl-argon-colors/colors';
import { Card } from '@tcl-boron-prefabs/card';
import { StyleSheet, css, StyleDeclarationValue } from 'aphrodite/no-important';
import { styles, fonts } from '../../constants/styles';
import { Order } from '../../api/Orders';

type ReportCardType = {
  assayName: string;
  status: string;
  action?: () => void;
  actionText?: string;
  statusStyles?: StyleDeclarationValue;
};

const OrderInfo = (props: { order: Order }) => {
  const { sampleCollectionDate, orderingInstitution, orderCity, orderState } = props.order;
  const formattedDate = moment(sampleCollectionDate).format('MMM DD, YYYY');
  return (
    <div>
      <div className={css([styles.sectionHeaderBlack, orderInfoStyles.collectedDate])}>
        {sampleCollectionDate ? `Sample Collected: ${formattedDate}` : 'Sample collected date not available'}
      </div>
      <div className={css(styles.bodyHeader, orderInfoStyles.collectedSite)}>
        {`${orderingInstitution} - `}
        {orderCity && `${capitalize(orderCity)}, `}
        {orderState}
      </div>
    </div>
  );
};

const ReportCard: FunctionComponent<ReportCardType> = (props) => {
  const { assayName, status, action, actionText, statusStyles } = props;

  return (
    <Card className={css(reportCardStyles.card)}>
      <div className={css(reportCardStyles.reportCard)}>
        <div className={css(styles.bodyHeader, reportCardStyles.reportInfo)}>
          <span className={css(styles.bodyHeader, fonts.ibmPlexSansBold)}>{assayName}</span>
          <span> | </span>
          <span className={css(statusStyles)}>{status}</span>
        </div>
        {actionText && (
          <div onClick={action} className={css(styles.link, reportCardStyles.viewButton)}>
            {actionText}
          </div>
        )}
      </div>
    </Card>
  );
};

const reportCardStyles = StyleSheet.create({
  card: {
    marginTop: '1.25em',
    paddingBottom: '0.75em',
    paddingRight: '0.625em',
    backgroundColor: 'white',
  },
  viewButton: {
    marginBottom: '12px',
    marginTop: '2px',
  },
  reportCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  reportInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '1.42em',
    marginBottom: '12px',
    ':nth-child(1n) > span': {
      marginRight: '10px',
    },
  },
});

const orderInfoStyles = StyleSheet.create({
  collectedDate: {
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  collectedSite: {
    lineHeight: '16px',
    color: grayPalette.gray70,
    marginTop: '10px',
  },
});

export default ReportCard;
export { OrderInfo };
