import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import ReactSignatureCanvas from 'react-signature-canvas';

import styles from './styles.module.css';

type FormSignatureCanvasProps = Pick<FieldRenderProps<any>['input'], 'onChange'> & Pick<FieldRenderProps<any>, 'meta'>;

const FormSignatureCanvas: React.FunctionComponent<FormSignatureCanvasProps> = ({ onChange, meta }) => {
  const signatureRef = React.useRef<any>();

  useEffect(() => {
    if (!meta.visited) {
      signatureRef.current.clear();
    }
  }, [meta.visited]);

  useEffect(() => {
    if (meta.submitting) {
      signatureRef.current.off();
    } else {
      signatureRef.current.on();
    }
  }, [meta.submitting]);

  return (
    <ReactSignatureCanvas
      ref={signatureRef}
      canvasProps={{ className: styles.signatureCanvas }}
      onEnd={() => {
        onChange(signatureRef.current?.toDataURL());
      }}
    />
  );
};

export default FormSignatureCanvas;
