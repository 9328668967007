import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button } from '@tcl-boron-prefabs/button';
import Logo from '../../../../shared/LogoLarge';
import NumericInput from './NumericInput';
import FormError from '../FormError';
import styles from './Form.module.css';
import client from '../../../client';
import { TEMPUS_PRO_PATH } from '../../..';
import { emptyResponsesForAssessment, useQueryParameter } from '../../../utils';
import LoadingPage from '../../LoadingPage/LoadingPage';
import { AppContext, AppContextType } from '../../App/AppContext';
import { PRO_TERMS_OF_USE_URL, PRO_PRIVACY_POLICY_URL, PRO_NOTICE_OF_PRIVACY_PRACTICES_URL } from '../../TermsOfUse';
import { getLink } from '../../TermsOfUse/TermsOfUse';

type InputConfig = {
  [key: string]: {
    label: string;
    placeholder: string;
    length: number;
    enablePadding?: boolean;
  };
};

const inputConfig: InputConfig = {
  month: {
    label: 'Month',
    placeholder: 'MM',
    length: 2,
    enablePadding: true,
  },
  day: {
    label: 'Day',
    placeholder: 'DD',
    length: 2,
    enablePadding: true,
  },
  year: {
    label: 'Year',
    placeholder: 'YYYY',
    length: 4,
  },
};

const VerificationForm: React.FC<RouteComponentProps> = (props) => {
  const { setVerified, setAssessments, setResponses, setOrderId, setNotificationId, orderId } = React.useContext(
    AppContext,
  ) as AppContextType;
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  // If the notification id is missing, direct to the error page
  const notificationId = useQueryParameter('id');

  React.useEffect(() => {
    if (!notificationId) {
      props.history.push(`${TEMPUS_PRO_PATH}/invalid`);
    } else {
      setNotificationId(notificationId);
      client
        .getNotificationStatus(notificationId)
        .then((data: any) => {
          if (data.status !== 'unused') {
            props.history.push(`${TEMPUS_PRO_PATH}/invalid`);
          }
          setOrderId(data.orderId);
          setIsLoading(false);
        })
        .catch(() => {
          props.history.push(`${TEMPUS_PRO_PATH}/error?id=${notificationId}`);
        });
    }
  }, []);

  const [month, setMonth] = React.useState<string>('');
  const [day, setDay] = React.useState<string>('');
  const [year, setYear] = React.useState<string>('');

  const [hasError, setHasError] = React.useState<boolean>(false);

  const isDisabled = React.useMemo(() => {
    return !(
      month.length === inputConfig.month.length &&
      day.length === inputConfig.day.length &&
      year.length === inputConfig.year.length
    );
  }, [month, day, year]);

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
        setIsLoading(true);
        if (!orderId) {
          throw new Error('orderId is required but was not provided');
        }
        await client.verifyIdentityV3(month, day, year, orderId);
        setVerified(true);

        const assessmentsResponse = await client.getAssessments(orderId);
        setAssessments(assessmentsResponse);

        if (assessmentsResponse.length > 0) {
          setResponses(emptyResponsesForAssessment(assessmentsResponse[0]));
        }
        props.history.push(`${TEMPUS_PRO_PATH}/start?id=${notificationId}`);
      } catch (err) {
        if (err.status === 401) {
          setIsLoading(false);
          setHasError(true);
        } else {
          props.history.push(`${TEMPUS_PRO_PATH}/error?id=${notificationId}`);
        }
      }
    },
    [setHasError, year, month, day],
  );

  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.errorBanner}>{hasError && <FormError />}</div>
      <form className={styles.form} onSubmit={handleSubmit}>
        <h1 className={styles.title}>Welcome!</h1>
        <p className={styles.text}>Please verify your date of birth</p>
        <div className={styles.inputs}>
          <NumericInput className={styles.monthInput} value={month} onChange={setMonth} {...inputConfig.month} />
          <NumericInput className={styles.dayInput} value={day} onChange={setDay} {...inputConfig.day} />
          <NumericInput className={styles.yearInput} value={year} onChange={setYear} {...inputConfig.year} />
        </div>
        <Button
          data-testid="dob-submit"
          type="submit"
          className={styles.submitButton}
          disabled={isDisabled}
          ariaLabel="Submit"
        >
          Submit
        </Button>
        <div data-testid="tou-browsewrap" className={styles.browseWrap}>
          By authenticating, you acknowledge and agree to our {getLink('Terms of Use', PRO_TERMS_OF_USE_URL)},{' '}
          {getLink('Privacy Policy', PRO_PRIVACY_POLICY_URL)}, and{' '}
          {getLink('Notice of Privacy Practices', PRO_NOTICE_OF_PRIVACY_PRACTICES_URL)}.
        </div>
      </form>
    </>
  );
};

export default VerificationForm;
