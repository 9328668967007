import React, { FunctionComponent, ReactNode } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import ContentLayout from './ContentLayout';
import ErrorBanner from './ErrorBanner';
import { styles } from '../../../constants/styles';

type ErrorProps = {
  disclaimer?: ReactNode;
  title?: ReactNode;
};

const DEFAULT_ERROR_TEXT = 'An error has occurred. Please try again.';

const ERROR_STYLES = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '6.07em',
    flex: 1,
    width: '100%',
  },
  errorText: {
    marginTop: '1.07em',
    padding: '0px 1.875em',
    textAlign: 'center',
  },
});

const Error: FunctionComponent<ErrorProps> = (props) => {
  return (
    <ContentLayout>
      <div className={css(ERROR_STYLES.container)}>
        <ErrorBanner title={props.title ?? undefined} />
        {props.disclaimer ?? <div className={css(ERROR_STYLES.errorText, styles.bodyText)}>{DEFAULT_ERROR_TEXT}</div>}
      </div>
    </ContentLayout>
  );
};

export default Error;
export { ERROR_STYLES };
