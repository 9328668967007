import React from 'react';
import _ from 'lodash';
import { AnswerOption, Question } from '@tempus/patient-report-outcome-service-shared';
import { AppContext, AppContextType } from '../../App/AppContext';
import { joinClasses } from '../../../utils';
import styles from './AnswerOptions.module.css';

type AnswerSetProps = {
  question: Question;
  questionIndex: number;
};

const AnswerSet: React.FC<AnswerSetProps> = ({ question, questionIndex }) => {
  const { responses, updateResponse, currentAssessment } = React.useContext(AppContext) as AppContextType;

  // If the question has its own set of answers, use that instead of the defaults
  const defaultAnswers = currentAssessment.sections[0].answerOptions;
  const answerOptions = _.get(question, 'answers', defaultAnswers);

  const questionKey = responses[questionIndex].key;
  const currentValue = _.find(responses, { key: questionKey })?.value;

  return (
    <div className={styles.options}>
      {answerOptions.map((option: AnswerOption, index: number) => {
        const optionIndex = answerOptions.findIndex((option: AnswerOption) => option.value === currentValue);
        const selectedClass = optionIndex === index ? styles.selected : '';
        const key = `answer-${questionKey}-${index}`;
        return (
          <label className={joinClasses(styles.answer, selectedClass)} key={key} htmlFor={key}>
            <input
              data-test-id={key}
              type="radio"
              id={key}
              value={option.value}
              className={styles.answerInput}
              hidden
              onClick={() => {
                updateResponse(questionKey, option.value);
              }}
            />
            {option.title}
          </label>
        );
      })}
    </div>
  );
};

export default AnswerSet;
