import React from 'react';
import { css } from 'aphrodite';
import Error, { ERROR_STYLES } from './Error';
import { ERROR_BANNER_STYLES } from './ErrorBanner';
import { styles } from '../../../constants/styles';

const INVALID_TOKEN_BANNER = 'Link Expired';
const INVALID_TOKEN_ERROR =
  'The link you used has expired. Please ensure you are using the most recent email from Tempus or request a new link from the app.';

const InvalidTokenError = () => {
  return (
    <Error
      title={<div className={css(ERROR_BANNER_STYLES.text, styles.sectionHeaderBlack)}>{INVALID_TOKEN_BANNER}</div>}
      disclaimer={<div className={css(ERROR_STYLES.errorText, styles.bodyText)}>{INVALID_TOKEN_ERROR}</div>}
    />
  );
};

export default InvalidTokenError;
