import SignatureCanvas from 'react-signature-canvas';
import React, { useState, useRef, useEffect, FunctionComponent } from 'react';
import moment from 'moment';
import phone from 'phone';
import { StyleSheet, css } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import { TextInput } from '@tcl-argon-prefabs/text-input';
import { useAsync } from 'react-async';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { DropdownOption } from '@tcl-argon-models/models';
import { RichTextLines } from '../../app/components/richtext/RichText';
import { styles, fonts } from '../../constants/styles';
import { commonStyles } from '../../constants/commonStyles';
import { ConsentFooter } from './commonComponents';
import { usePatientInfo, usePatientInfoState, MyInfo, formConstants } from '../../api/PatientInfo';
import { InputTheme } from '@tcl-argon-models/models';
import ContentLayout from '../../app/components/screen/ContentLayout';
import { getContent } from '../../../content';
import Error from '../../app/components/screen/Error';
import { setConsentForm } from '../../api/PatientConsent';

// Labels
const ADDRESS_ONE_LABEL = 'Address 1*';
const ADDRESS_TWO_LABEL = 'Address 2';
const CITY_LABEL = 'City*';
const DATE_OF_BIRTH_LABEL = 'Date of Birth*';
const EMAIL_LABEL = 'Email*';
const FIRST_NAME_LABEL = 'First name*';
const GENDER_LABEL = 'Sex*';
const LAST_NAME_LABEL = 'Last name*';
const MIDDLE_NAME_LABEL = 'Middle name';
const PHONE_NUMBER_LABEL = 'Phone number*';
const STATE_LABEL = 'State*';
const TODAY_DATE_LABEL = "Today's Date*";

// Gender
const FEMALE_LABEL = 'Female';
const FEMALE_VALUE = 'female';
const MALE_LABEL = 'Male';
const MALE_VALUE = 'male';
const GENDER_PICKER_ITEMS = [
  { label: MALE_LABEL, value: MALE_VALUE },
  { label: FEMALE_LABEL, value: FEMALE_VALUE },
];

// Test IDs
const ADDRESS_ONE_TEST_ID = 'consent-hipaa-address-one-field';
const ADDRESS_TWO_TEST_ID = 'consent-hipaa-address-two-field';
const CITY_TEST_ID = 'consent-hipaa-city-field';
const DATE_OF_BIRTH_TEST_ID = 'consent-hipaa-date-of-birth-field';
const EMAIL_TEST_ID = 'consent-hipaa-email-field';
const FIRST_NAME_TEST_ID = 'consent-hipaa-first-name-field';
const LAST_NAME_TEST_ID = 'consent-hipaa-last-name-field';
const MIDDLE_NAME_TEST_ID = 'consent-hipaa-middle-name-field';
const PHONE_NUMBER_TEST_ID = 'consent-hipaa-phone-number-field';
const STATE_TEST_ID = 'consent-hipaa-state-field';
const TODAYS_DATE_TEST_ID = 'covid-hipaa-todays-date-field';
const GENDER_TEST_ID = 'gender-field';

// Content
const DECLINE_AND_SKIP = 'Decline and skip';
const HEADER = 'HIPAA Authorization';
const NOT_INTERESTED = 'Not interested?';
const SUBMIT = 'Submit';

// State
const ALABAMA = 'AL';
const ALASKA = 'AK';
const AMERICAN_SAMOA = 'AS';
const ARIZONA = 'AZ';
const ARKANSAS = 'AR';
const CALIFORNIA = 'CA';
const COLORADO = 'CO';
const CONNECTICUT = 'CT';
const DELAWARE = 'DE';
const DISTRICT_OF_COLUMBIA = 'DC';
const FEDERATED_STATES_OF_MICRONESIA = 'FM';
const FLORIDA = 'FL';
const GEORGIA = 'GA';
const GUAM = 'GU';
const HAWAII = 'HI';
const IDAHO = 'ID';
const ILLINOIS = 'IL';
const INDIANA = 'IN';
const IOWA = 'IA';
const KANSAS = 'KS';
const KENTUCKY = 'KY';
const LOUISIANA = 'LA';
const MAINE = 'ME';
const MARSHALL_ISLANDS = 'MH';
const MARYLAND = 'MD';
const MASSACHUSETTS = 'MA';
const MICHIGAN = 'MI';
const MINNESOTA = 'MN';
const MISSISSIPPI = 'MS';
const MISSOURI = 'MO';
const MONTANA = 'MT';
const NEBRASKA = 'NE';
const NEVADA = 'NV';
const NEW_HAMPSHIRE = 'NH';
const NEW_JERSEY = 'NJ';
const NEW_MEXICO = 'NM';
const NEW_YORK = 'NY';
const NORTH_CAROLINA = 'NC';
const NORTH_DAKOTA = 'ND';
const NORTHERN_MARIANA_ISLANDS = 'MP';
const OHIO = 'OH';
const OKLAHOMA = 'OK';
const OREGON = 'OR';
const PALAU = 'PW';
const PENNSYLVANIA = 'PA';
const PUERTO_RICO = 'PR';
const RHODE_ISLAND = 'RI';
const SOUTH_CAROLINA = 'SC';
const SOUTH_DAKOTA = 'SD';
const TENNESSEE = 'TN';
const TEXAS = 'TX';
const UTAH = 'UT';
const VERMONT = 'VT';
const VIRGIN_ISLANDS = 'VI';
const VIRGINIA = 'VA';
const WASHINGTON = 'WA';
const WEST_VIRGINIA = 'WV';
const WISCONSIN = 'WI';
const WYOMING = 'WY';
const STATE_PICKER_ITEMS = [
  { label: ALABAMA, value: ALABAMA },
  { label: ALASKA, value: ALASKA },
  { label: AMERICAN_SAMOA, value: AMERICAN_SAMOA },
  { label: ARIZONA, value: ARIZONA },
  { label: ARKANSAS, value: ARKANSAS },
  { label: CALIFORNIA, value: CALIFORNIA },
  { label: COLORADO, value: COLORADO },
  { label: CONNECTICUT, value: CONNECTICUT },
  { label: DELAWARE, value: DELAWARE },
  { label: DISTRICT_OF_COLUMBIA, value: DISTRICT_OF_COLUMBIA },
  { label: FEDERATED_STATES_OF_MICRONESIA, value: FEDERATED_STATES_OF_MICRONESIA },
  { label: FLORIDA, value: FLORIDA },
  { label: GEORGIA, value: GEORGIA },
  { label: GUAM, value: GUAM },
  { label: HAWAII, value: HAWAII },
  { label: IDAHO, value: IDAHO },
  { label: ILLINOIS, value: ILLINOIS },
  { label: INDIANA, value: INDIANA },
  { label: IOWA, value: IOWA },
  { label: KANSAS, value: KANSAS },
  { label: KENTUCKY, value: KENTUCKY },
  { label: LOUISIANA, value: LOUISIANA },
  { label: MAINE, value: MAINE },
  { label: MARSHALL_ISLANDS, value: MARSHALL_ISLANDS },
  { label: MARYLAND, value: MARYLAND },
  { label: MASSACHUSETTS, value: MASSACHUSETTS },
  { label: MICHIGAN, value: MICHIGAN },
  { label: MINNESOTA, value: MINNESOTA },
  { label: MISSISSIPPI, value: MISSISSIPPI },
  { label: MISSOURI, value: MISSOURI },
  { label: MONTANA, value: MONTANA },
  { label: NEBRASKA, value: NEBRASKA },
  { label: NEVADA, value: NEVADA },
  { label: NEW_HAMPSHIRE, value: NEW_HAMPSHIRE },
  { label: NEW_JERSEY, value: NEW_JERSEY },
  { label: NEW_MEXICO, value: NEW_MEXICO },
  { label: NEW_YORK, value: NEW_YORK },
  { label: NORTH_CAROLINA, value: NORTH_CAROLINA },
  { label: NORTH_DAKOTA, value: NORTH_DAKOTA },
  { label: NORTHERN_MARIANA_ISLANDS, value: NORTHERN_MARIANA_ISLANDS },
  { label: OHIO, value: OHIO },
  { label: OKLAHOMA, value: OKLAHOMA },
  { label: OREGON, value: OREGON },
  { label: PALAU, value: PALAU },
  { label: PENNSYLVANIA, value: PENNSYLVANIA },
  { label: PUERTO_RICO, value: PUERTO_RICO },
  { label: RHODE_ISLAND, value: RHODE_ISLAND },
  { label: SOUTH_CAROLINA, value: SOUTH_CAROLINA },
  { label: SOUTH_DAKOTA, value: SOUTH_DAKOTA },
  { label: TENNESSEE, value: TENNESSEE },
  { label: TEXAS, value: TEXAS },
  { label: UTAH, value: UTAH },
  { label: VERMONT, value: VERMONT },
  { label: VIRGIN_ISLANDS, value: VIRGIN_ISLANDS },
  { label: VIRGINIA, value: VIRGINIA },
  { label: WASHINGTON, value: WASHINGTON },
  { label: WEST_VIRGINIA, value: WEST_VIRGINIA },
  { label: WISCONSIN, value: WISCONSIN },
  { label: WYOMING, value: WYOMING },
];

//Error
const INVALID_PHONE_NUMBER = 'Invalid phone number';

const consentFormDeferFn = ([consent]: any[]) => setConsentForm(consent);

type ConsentHipaaFormProps = {
  next: (param: boolean) => void;
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (phoneNumber) {
    const formattedNumber = phone(phoneNumber).phoneNumber;
    const matches = formattedNumber?.match(/.*(\d{3})(\d{3})(\d{4})$/);
    if (matches?.length) {
      const [, areaCode, exchangeCode, lineNumber] = matches;
      return `(${areaCode}) ${exchangeCode}-${lineNumber}`;
    }
  }
};

const useConsentHipaaFormV2 = (next: (consented: boolean) => void, initial: MyInfo) => {
  const signatureRef = useRef<any>();

  const [addressOne, setAddressOne] = useState<string | undefined>(initial.street1 ?? undefined);
  const [addressTwo, setAddressTwo] = useState<string | undefined>(initial.street2 ?? undefined);
  const [city, setCity] = useState<string | undefined>(initial.city ?? undefined);
  const [gender, setGender] = useState<DropdownOption | undefined>(
    GENDER_PICKER_ITEMS.find((item) => item.label.toLowerCase() === initial.gender?.toLowerCase()) ?? undefined,
  );
  const [state, setState] = useState<DropdownOption | undefined>(
    STATE_PICKER_ITEMS.find((item) => item.value === initial.state) ?? undefined,
  );
  const [middleName, setMiddleName] = useState<string | undefined>(initial.middleName ?? undefined);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(formatPhoneNumber(initial.phone) ?? undefined);
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [scrollEnabled, setScrollEnabled] = useState<boolean>(true);
  const [signature, setSignature] = useState<string | undefined>(initial.consent?.signature);

  const [isConsented, setIsConsented] = useState<boolean>(false);
  const createFormAsyncState = useAsync({ deferFn: consentFormDeferFn });

  const todaysDate = moment().format(formConstants.DATE_FORMAT);
  const dateOfBirth = moment(initial.dateOfBirth).format(formConstants.DATE_FORMAT);

  const declineForm = () => {
    setIsConsented(false);
    createFormAsyncState.run({
      consented: false,
      diseaseType: formConstants.DEFAULT_DISEASE_TYPE,
      formType: formConstants.DEFAULT_FORM_TYPE,
      formVersion: formConstants.DEFAULT_FORM_VERSION,
    });
  };

  const isFormValid = () => {
    if (!phoneNumber || !phoneNumber.length) {
      return false;
    }
    if (phoneNumberError.length) {
      return false;
    }
    return true;
  };

  const submitForm = () => {
    if (!isFormValid()) {
      return;
    }
    setIsConsented(true);
    createFormAsyncState.run({
      consented: true,
      diseaseType: formConstants.DEFAULT_DISEASE_TYPE,
      formType: formConstants.DEFAULT_FORM_TYPE,
      formVersion: formConstants.DEFAULT_FORM_VERSION,
      form: {
        city,
        dateActive: moment().toDate(),
        dateExpired: moment().add(1, 'year').toDate(),
        dateSubmitted: todaysDate,
        dateOfBirth: dateOfBirth,
        diseaseType: formConstants.DEFAULT_DISEASE_TYPE,
        email: initial.email,
        firstName: initial.firstName,
        formType: formConstants.DEFAULT_FORM_TYPE,
        formVersion: formConstants.DEFAULT_FORM_VERSION,
        gender: gender?.value,
        lastName: initial.lastName,
        middleName,
        phoneNumbers: phoneNumber ? [phoneNumber] : [],
        signature,
        signatureType: formConstants.DEFAULT_SIGNATURE_TYPE,
        state: state?.value,
        street1: addressOne,
        street2: addressTwo,
      },
    });
  };

  useEffect(() => {
    function resizeCanvas() {
      if (!signatureRef.current) {
        return;
      }
      const canvas = signatureRef.current.getCanvas();
      const signaturePad = signatureRef.current.getSignaturePad();
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.width * 0.4;
      canvas.getContext('2d').scale(ratio, ratio);
      signaturePad.clear();
    }

    resizeCanvas();

    window.addEventListener('resize', resizeCanvas);
    return () => window.removeEventListener('resize', resizeCanvas);
  }, []);

  useEffect(() => {
    if (createFormAsyncState.isFulfilled) {
      next(isConsented);
    }
  }, [createFormAsyncState.isFulfilled, isConsented]);

  const submitDisabled =
    createFormAsyncState.isLoading ||
    !signature ||
    !addressOne ||
    !city ||
    !gender ||
    !phoneNumber ||
    !!phoneNumberError;

  return {
    addressOne: { disabled: !!initial.street1, setter: setAddressOne, value: addressOne },
    addressTwo: { disabled: !!initial.street2, setter: setAddressTwo, value: addressTwo },
    city: { disabled: !!initial.city, setter: setCity, value: city },
    dateOfBirth: { value: dateOfBirth },
    email: { value: initial.email },
    firstName: { value: initial.firstName },
    gender: {
      disabled: !!initial.gender,
      setter: setGender,
      value: gender,
    },
    lastName: { value: initial.lastName },
    middleName: { disabled: !!initial.middleName, setter: setMiddleName, value: middleName },
    phoneNumber: {
      disabled: !!formatPhoneNumber(initial.phone),
      setter: setPhoneNumber,
      value: phoneNumber,
      error: phoneNumberError,
      setError: setPhoneNumberError,
    },
    scroll: { disabled: false, setter: setScrollEnabled, value: scrollEnabled },
    signature: {
      disabled: false,
      ref: signatureRef,
      setter: setSignature,
      value: signature,
    },
    submit: { disabled: submitDisabled, decline: declineForm, submit: submitForm, state: createFormAsyncState },
    state: { disabled: !!initial.state, setter: setState, value: state },
    todayDate: { value: todaysDate },
  };
};

const ConsentHipaaForm: FunctionComponent<ConsentHipaaFormProps> = ({ next }) => {
  const { patientInfo } = usePatientInfo();
  const consentHipaaForm = useConsentHipaaFormV2(next, patientInfo!);
  const content = getContent(formConstants.DEFAULT_FORM_VERSION);
  const { hipaaAuthorization } = content;
  const { isFirstTimeLogin, setIsFirstTimeLogin } = usePatientInfoState();

  useEffect(() => {
    if (!isFirstTimeLogin) {
      setIsFirstTimeLogin(true);
    }
  }, []);

  if (consentHipaaForm.submit.state.error || consentHipaaForm.submit.state.isRejected) {
    return <Error />;
  }

  return (
    <ContentLayout>
      <div className={css(commonStyles.outerContainer, ConsentHipaaStyles.hipaaContainer)}>
        <div className={css(commonStyles.contentContainer)}>
          <div className={css([ConsentHipaaStyles.headerText, styles.title])}>{HEADER}</div>
          <RichTextLines
            style={ConsentHipaaStyles.hipaaContentContainer}
            lines={hipaaAuthorization ?? []}
            lineStyle={[ConsentHipaaStyles.hipaaContent, styles.bodyText]}
          />
          <div className={css(ConsentHipaaStyles.signatureWrapper)}>
            <label className={css(styles.supportingBodyText, fonts.ibmPlexSansMedium, ConsentHipaaStyles.labelText)}>
              Signature*
            </label>
            <SignatureCanvas
              ref={consentHipaaForm.signature.ref}
              penColor={grayPalette.gray100}
              canvasProps={{
                className: css(ConsentHipaaStyles.signatureCanvas),
              }}
              onEnd={() => {
                if (consentHipaaForm.signature.ref.current) {
                  const signatureURL = consentHipaaForm.signature.ref.current.toDataURL();
                  consentHipaaForm.signature.setter(signatureURL);
                }
              }}
            />
          </div>
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentHipaaStyles.textInputWrapper, ConsentHipaaStyles.textInputDisabled),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            required
            disabled={true}
            label={TODAY_DATE_LABEL}
            data-testid={TODAYS_DATE_TEST_ID}
            onChange={() => null}
            value={consentHipaaForm.todayDate.value}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentHipaaStyles.textInputWrapper, ConsentHipaaStyles.textInputDisabled),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            disabled={true}
            label={FIRST_NAME_LABEL}
            data-testid={FIRST_NAME_TEST_ID}
            required
            onChange={() => null}
            value={consentHipaaForm.firstName.value}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(
                ConsentHipaaStyles.textInputWrapper,
                consentHipaaForm.middleName.disabled ? ConsentHipaaStyles.textInputDisabled : null,
              ),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            disabled={consentHipaaForm.middleName.disabled}
            label={MIDDLE_NAME_LABEL}
            data-testid={MIDDLE_NAME_TEST_ID}
            onChange={consentHipaaForm.middleName.setter}
            value={consentHipaaForm.middleName.value ?? ''}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentHipaaStyles.textInputWrapper, ConsentHipaaStyles.textInputDisabled),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            disabled={true}
            label={LAST_NAME_LABEL}
            data-testid={LAST_NAME_TEST_ID}
            required
            onChange={() => null}
            value={consentHipaaForm.lastName.value}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentHipaaStyles.textInputWrapper, ConsentHipaaStyles.textInputDisabled),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            required
            label={DATE_OF_BIRTH_LABEL}
            data-testid={DATE_OF_BIRTH_TEST_ID}
            onChange={() => null}
            disabled={true}
            value={consentHipaaForm.dateOfBirth.value}
          />
          {consentHipaaForm.gender.disabled ? (
            <TextInput
              classes={{
                root: css(ConsentHipaaStyles.textInputContainer),
                label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
                inputWrapper: css(ConsentHipaaStyles.textInputWrapper, ConsentHipaaStyles.textInputDisabled),
                input: css(ConsentHipaaStyles.inputStyle),
              }}
              required
              disabled={true}
              label={GENDER_LABEL}
              onChange={() => null}
              value={consentHipaaForm.gender.value!.label}
            />
          ) : (
            <SingleSelectDropdown
              classes={{
                root: css(ConsentHipaaStyles.textInputContainer),
                label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
                valueContainer: css(ConsentHipaaStyles.dropdownInputWrapper),
              }}
              label={GENDER_LABEL}
              options={GENDER_PICKER_ITEMS}
              onChange={(item) => consentHipaaForm.gender.setter(item ?? undefined)}
              value={consentHipaaForm.gender.value}
            />
          )}
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(ConsentHipaaStyles.textInputWrapper, ConsentHipaaStyles.textInputDisabled),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            required
            disabled={true}
            label={EMAIL_LABEL}
            data-testid={EMAIL_TEST_ID}
            onChange={() => null}
            value={consentHipaaForm.email.value}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(
                ConsentHipaaStyles.textInputWrapper,
                consentHipaaForm.phoneNumber.disabled ? ConsentHipaaStyles.textInputDisabled : null,
              ),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            required
            disabled={consentHipaaForm.phoneNumber.disabled}
            label={PHONE_NUMBER_LABEL}
            data-testid={PHONE_NUMBER_TEST_ID}
            onChange={(value) => {
              consentHipaaForm.phoneNumber.setter(value);
            }}
            onBlur={() => {
              const formattedNumber = formatPhoneNumber(consentHipaaForm.phoneNumber.value);
              if (!formattedNumber) {
                consentHipaaForm.phoneNumber.setError(INVALID_PHONE_NUMBER);
              } else {
                consentHipaaForm.phoneNumber.setter(formattedNumber);
                consentHipaaForm.phoneNumber.setError('');
              }
            }}
            subContent={consentHipaaForm.phoneNumber.error}
            theme={consentHipaaForm.phoneNumber.error ? InputTheme.Error : InputTheme.Default}
            value={consentHipaaForm.phoneNumber.value ?? ''}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(
                ConsentHipaaStyles.textInputWrapper,
                consentHipaaForm.addressOne.disabled ? ConsentHipaaStyles.textInputDisabled : null,
              ),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            required
            disabled={consentHipaaForm.addressOne.disabled}
            label={ADDRESS_ONE_LABEL}
            data-testid={ADDRESS_ONE_TEST_ID}
            onChange={consentHipaaForm.addressOne.setter}
            value={consentHipaaForm.addressOne.value ?? ''}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(
                ConsentHipaaStyles.textInputWrapper,
                consentHipaaForm.addressTwo.disabled ? ConsentHipaaStyles.textInputDisabled : null,
              ),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            disabled={consentHipaaForm.addressTwo.disabled}
            label={ADDRESS_TWO_LABEL}
            data-testid={ADDRESS_TWO_TEST_ID}
            onChange={consentHipaaForm.addressTwo.setter}
            value={consentHipaaForm.addressTwo.value ?? ''}
          />
          <TextInput
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              inputWrapper: css(
                ConsentHipaaStyles.textInputWrapper,
                consentHipaaForm.city.disabled ? ConsentHipaaStyles.textInputDisabled : null,
              ),
              input: css(ConsentHipaaStyles.inputStyle),
            }}
            required
            disabled={consentHipaaForm.city.disabled}
            label={CITY_LABEL}
            data-testid={CITY_TEST_ID}
            onChange={consentHipaaForm.city.setter}
            value={consentHipaaForm.city.value ?? ''}
          />
          <SingleSelectDropdown
            classes={{
              root: css(ConsentHipaaStyles.textInputContainer),
              label: css(styles.supportingBodyText, fonts.ibmPlexSansMedium),
              valueContainer: css(
                ConsentHipaaStyles.dropdownInputWrapper,
                consentHipaaForm.state.disabled ? ConsentHipaaStyles.textInputDisabled : null,
              ),
            }}
            disabled={consentHipaaForm.state.disabled}
            label={STATE_LABEL}
            options={STATE_PICKER_ITEMS}
            onChange={(item) => consentHipaaForm.state.setter(item ?? undefined)}
            value={consentHipaaForm.state.value}
          />
          <ConsentFooter
            accept={consentHipaaForm.submit.submit}
            decline={consentHipaaForm.submit.decline}
            submitButtonText={SUBMIT}
            supportingText={NOT_INTERESTED}
            declineText={DECLINE_AND_SKIP}
            declineButtonDisabled={consentHipaaForm.submit.state.isLoading}
            submitButtonDisabled={consentHipaaForm.submit.disabled}
            submitButtonLoading={consentHipaaForm.submit.state.isLoading}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default ConsentHipaaForm;
export {
  DATE_OF_BIRTH_TEST_ID,
  ADDRESS_ONE_TEST_ID,
  ADDRESS_TWO_TEST_ID,
  CITY_TEST_ID,
  EMAIL_TEST_ID,
  FIRST_NAME_TEST_ID,
  LAST_NAME_TEST_ID,
  MIDDLE_NAME_TEST_ID,
  PHONE_NUMBER_TEST_ID,
  STATE_TEST_ID,
  TODAYS_DATE_TEST_ID,
  GENDER_TEST_ID,
  ADDRESS_ONE_LABEL,
  ADDRESS_TWO_LABEL,
  CITY_LABEL,
  DATE_OF_BIRTH_LABEL,
  EMAIL_LABEL,
  FIRST_NAME_LABEL,
  GENDER_LABEL,
  LAST_NAME_LABEL,
  MIDDLE_NAME_LABEL,
  PHONE_NUMBER_LABEL,
  STATE_LABEL,
  TODAY_DATE_LABEL,
  DECLINE_AND_SKIP,
  HEADER,
  NOT_INTERESTED,
  SUBMIT,
};

const ConsentHipaaStyles = StyleSheet.create({
  inputStyle: {
    fontFamily: 'IBM Plex Sans',
    color: grayPalette.gray100,
  },
  headerText: {
    marginBottom: '1.42em',
  },
  hipaaContentContainer: {
    marginBottom: '0.71em',
  },
  hipaaContainer: {
    maxWidth: '27.14em',
  },
  hipaaContent: { color: grayPalette.gray100, marginBottom: '1.42em' },
  signatureWrapper: {
    marginBottom: '1.42em',
  },
  signatureCanvas: {
    width: '100%',
    borderRadius: '0.21em',
    boxShadow: `0 0.14em 0.29em 0 ${grayPalette.gray10}`,
    border: `0.07em solid ${grayPalette.gray30}`,
    marginTop: '0.71em',
  },
  textInputContainer: {
    width: '100%',
    marginBottom: '1.42em',
  },
  textInputDisabled: {
    backgroundColor: grayPalette.gray10,
    opacity: 'unset',
  },
  textInputWrapper: {
    border: `0.07em solid ${grayPalette.gray30}`,
    boxShadow: `0 0.14em 0.29em 0 ${grayPalette.gray10}`,
  },
  dropdownInputWrapper: {
    border: `0.07em solid ${grayPalette.gray30}`,
    boxShadow: `0 0.14em 0.29em 0 ${grayPalette.gray10}`,
    borderRadius: '3px',
    paddingLeft: '10px',
  },
  labelText: {
    marginBottom: '1.42em',
  },
});
