import React from 'react';
import { joinClasses } from '../../utils';
import styles from './LoadingPage.module.css';

const LoadingPage: React.FC = () => {
  return (
    <div className={styles.loadingContainer}>
      <p className={joinClasses(styles.loadingText, styles.breatheIn)}>Breathe in...</p>
      <p className={joinClasses(styles.loadingText, styles.breatheOut)}>Breathe out...</p>
    </div>
  );
};

export default LoadingPage;
