import React from 'react';
import { Assessment, QuestionResponse } from './types';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

export const joinClasses = (...classes: string[]): string => {
  return classes.join(' ');
};

export const emptyResponsesForAssessment = (assessment: Assessment): QuestionResponse[] => {
  const questions = assessment.sections[0].questions;
  return Object.keys(questions).reduce((responses: QuestionResponse[], nextKey: string) => {
    return [...responses, { key: nextKey, value: -1 }];
  }, []);
};

export const useQueryParameter = (key: string): string => {
  const { search } = useLocation();
  return React.useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get(key) ?? '';
  }, [search]);
};

function getStorageValue(key: string, defaultValue: any) {
  const saved = localStorage.getItem(key);
  if (saved) {
    return JSON.parse(saved);
  } else {
    return defaultValue;
  }
}

export const useLocalStorage = (key: string, defaultValue: any) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
