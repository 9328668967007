import React, { ErrorInfo } from 'react';
import { HeaderType } from '../header/headerType';
import Error from './Error';
import ScreenLayout from './ScreenLayout';
import logger from '../../../../utils/logger';

type Props = { fallback?: JSX.Element };
type State = { hasError: boolean };

class ErrorBoundary extends React.Component<Props, State> {
  state = { hasError: false };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Display fallback UI
    const componentStack = errorInfo.componentStack;
    const sentryContexts = { react: { componentStack } };
    logger.error(error, { contexts: sentryContexts });
    this.setState({ hasError: true });
  }
  render() {
    const { children, fallback } = this.props;
    if (this.state.hasError) {
      return (
        fallback ?? (
          <ScreenLayout header={HeaderType.UNAUTHENTICATED_HEADER}>
            <Error />
          </ScreenLayout>
        )
      );
    }
    return children;
  }
}

export default ErrorBoundary;
