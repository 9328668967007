import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = (queryString: string): string | null => {
  return new URLSearchParams(useLocation().search).get(queryString);
};

export const useTimeout = (callback: () => void, delay: number) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    const id = setTimeout(tick, delay);

    return () => clearTimeout(id);
  }, []);
};

/**
 *
 * DO NOT USE: This mounted function is designed to help handle the okta sign in widget which triggers listeners after
 * the component has already unmounted.
 *
 **/
export function useIsMounted() {
  const isMountedRef = useRef(true);
  const isMounted = useCallback(() => isMountedRef.current, []);

  useEffect(() => {
    return () => void (isMountedRef.current = false);
  }, []);

  return isMounted;
}
