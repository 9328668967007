import React, { useEffect } from 'react';
import Success from '../../../app/components/screen/Success';
import { Button } from '@tcl-argon-prefabs/button';
import { InputTheme } from '@tcl-argon-models/models';
import { css, StyleSheet } from 'aphrodite/no-important';
import { styles as textStyles } from '../../../constants/styles';
import { useHistory } from 'react-router-dom';
import { OktaAuthNavigationPath } from '../../../api/Navigation';

const TITLE = 'SIGNED OUT';
const CONTENT = [
  'You have been signed out of your account.',
  'In a few seconds, you will be redirected back to our Sign In screen.',
];
const RETURN_TO_LOGIN = 'Return to login';
const TIME_OUT = 5000; // 5s

const SignedOut = () => {
  const { push: navigate } = useHistory();

  useEffect(() => {
    const id = setTimeout(() => {
      navigate(OktaAuthNavigationPath.LOGIN);
    }, TIME_OUT);

    return () => clearTimeout(id);
  }, []);

  return (
    <Success title={TITLE}>
      <div className={css(styles.container)}>
        <div className={css(styles.contentContainer)}>
          {CONTENT.map((text, index) => {
            return (
              <p className={css(textStyles.bodyText)} key={index}>
                {text}
              </p>
            );
          })}
        </div>
        <Button
          className={css(styles.button)}
          onClick={() => navigate(OktaAuthNavigationPath.LOGIN)}
          theme={InputTheme.Dark}
        >
          <p className={css(styles.buttonText, textStyles.bodyHeader)}>{RETURN_TO_LOGIN}</p>
        </Button>
      </div>
    </Success>
  );
};

export default SignedOut;

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#000000',
    width: '200px',
  },
  buttonText: {
    margin: 0,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    marginBottom: '50px',
  },
});
