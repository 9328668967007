import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import Loader from '@tcl-argon-prefabs/loader';

type LoadingIndicatorProps = {
  scale: number;
};

const LoadingIndicator: React.FunctionComponent<LoadingIndicatorProps> = (props) => {
  return (
    <div className={css(styles.container)}>
      <Loader color="black" scale={props.scale} />
    </div>
  );
};

export default LoadingIndicator;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    height: '100%',
  },
});
