import React, { Fragment, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import BackButton from './BackButton';
import FactSheet from './FactSheet';
import { AssayName, Order } from '../../../api/Orders';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';
import moment from 'moment';
import { ICResultWrapper } from '../../../api/result/Result';
import { useQuery } from '../../../api/hooks';
import { grayPalette } from '@tcl-argon-colors/colors';
import { usePatientInfo } from '../../../api/PatientInfo';
import { fonts } from '../../../constants/styles';

type ResultHeaderProps = {
  result?: ICResultWrapper;
  order: Order;
};

export const nextStepsHeader = "What's Next?";
export const nextStepsContent =
  'Please discuss your test results with your healthcare provider. Together, you can determine the best next steps based on your results, medical history, and symptoms.';

const ResultHeader: FunctionComponent<ResultHeaderProps> = (props) => {
  const { patientInfo } = usePatientInfo();
  const windowDemension = useWindowDimensions();
  const reportId = useQuery('report');
  const selectedReport = props.order.reports.find((report) => report.manifestId === reportId);

  const container = isMobileDevice(windowDemension) ? styles.mobileContainer : styles.browserContainer;
  const fullName = `${patientInfo?.firstName} ${patientInfo?.lastName}`;
  const collectionDate = props.result
    ? `Sample collected ${moment(props.result.getCollectionDate()).format('MMM D, YYYY')}`
    : `Sample collected ${moment(props.order.sampleCollectionDate).format('MMM D, YYYY')}`;

  return (
    <Fragment>
      <div className={css(container, styles.patientContainerBottomPadding)}>
        <div className={css(styles.backButtonContainer)}>
          <BackButton />
        </div>
        <div className={css(styles.name, fonts.ibmPlexSansLight)}>{fullName}</div>
        <div className={css(styles.assay, fonts.ibmPlexSansMedium)}>{AssayName[selectedReport!.assayName]}</div>
        <div>{collectionDate}</div>
      </div>
      <div className={css(styles.divider)} />
      <div className={css(container, styles.factSheetTopPadding)}>
        <div className={css(fonts.ibmPlexSansBold, styles.nextStepsHeader)}>{nextStepsHeader}</div>
        <div className={css(styles.nextStepsContent)}>{nextStepsContent}</div>
        <FactSheet order={props.order} />
      </div>
    </Fragment>
  );
};

export default ResultHeader;

const styles = StyleSheet.create({
  assay: {
    paddingBottom: '0.357em',
  },
  backButtonContainer: {
    paddingBottom: '2.857em',
  },
  browserContainer: {
    padding: '2.143em 2.857em',
  },
  divider: {
    borderTop: `1px solid ${grayPalette.gray30}`,
    width: '100%',
  },
  factSheetTopPadding: {
    paddingTop: '2.857em',
  },
  mobileContainer: {
    padding: '1.429em 2.143em',
  },
  name: {
    fontSize: '24px',
    lineHeight: '29px',
    paddingBottom: '1.429em',
  },
  nextStepsContent: {
    color: grayPalette.gray60,
    fontSize: '14px',
    lineHeight: '20px',
    paddingBottom: '2.857em',
  },
  nextStepsHeader: {
    color: grayPalette.gray60,
    fontSize: '14px',
    letterSpacing: '2px',
    lineHeight: '20px',
    paddingBottom: '1.071em',
    textTransform: 'uppercase',
  },
  patientContainerBottomPadding: {
    paddingBottom: '2.857em',
  },
});
