import versions from './versions';

const getContent = (version: string) => {
  const specificVersion = versions.find((v) => v.version === version);

  if (!specificVersion) throw Error('content version not found');
  return specificVersion;
};

export { getContent };
