import React, { useEffect, useState } from 'react';
import { FieldState } from 'final-form';
import { Field, FieldRenderProps, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { ErrorPalette, GrayscalePalette } from '@tcl-boron-colors/colors';
import { FormTableFieldBase, FormTableFieldType } from '@tempus/patient-forms-service-shared';

import FormCheckbox from './FormCheckbox';
import FormDatePicker from './FormDatePicker';
import FormMultiSelectCheckbox from './FormMultiSelectCheckbox';
import FormSignature from './FormSignature';
import FormSignatureWithDate from './FormSignatureWithDate';
import FormSingleSelectDropdown from './FormSingleSelectDropdown';
import FormSingleSelectTypeahead from './FormSingleSelectTypeahead';
import FormTextArea from './FormTextArea';
import FormTextInput from './FormTextInput';

import styles from './styles.module.css';

export type FormTableFieldFrontend = {
  name: string;
  props?: any;
  validate?: (value: any | undefined, allValues: any, meta: FieldState<any> | undefined) => any | undefined;
  readOnly?: boolean;
  conditionalValueConfig?: ConditionalValueConfig;
  validatedValueConfig?: ValidatedValueConfig;
};

export type FormTableField = FormTableFieldBase & FormTableFieldFrontend;

export type ConditionalValueConfig = {
  valueToCheck: any;
  fieldNameToCheck: any;
  conditionalLogic: any;
};

export type ValidatedValueConfig = {
  validatedValueNames: string[];
  readOnly: boolean;
  concatenator?: string;
};

type FormTableProps = {
  fields: FormTableField[];
  values?: [any];
};

const fieldTypeMapper: Record<FormTableFieldType, React.ComponentType<FormTableField & FieldRenderProps<any>> & any> = {
  [FormTableFieldType.CHECK_BOX]: FormCheckbox,
  [FormTableFieldType.SIGNATURE]: FormSignature,
  [FormTableFieldType.SIGNATURE_WITH_DATE]: FormSignatureWithDate,
  [FormTableFieldType.TEXT_INPUT]: FormTextInput,
  [FormTableFieldType.SINGLE_SELECT_DROPDOWN]: FormSingleSelectDropdown,
  [FormTableFieldType.SINGLE_SELECT_TYPEAHEAD]: FormSingleSelectTypeahead,
  [FormTableFieldType.DATE_PICKER]: FormDatePicker,
  [FormTableFieldType.MULTI_SELECT_CHECKBOX]: FormMultiSelectCheckbox,
  [FormTableFieldType.TEXT_AREA]: FormTextArea,
};

const renderFormField = (field: FormTableField, index: number) => {
  return (
    <Field
      name={field.name}
      initialValue={field.props ? (field.props.initialValue ? field.props.initialValue : undefined) : undefined}
      render={(props) => {
        const [isError, setIsError] = useState<boolean>();

        useEffect(() => {
          const container = document.getElementById(`field-type-${props.input.name}`);
          setIsError(
            container
              ? !!props.meta.error && !!props.meta.touched && !container.contains(document.activeElement)
              : true,
          );
        }, [props.meta]);

        const Component = fieldTypeMapper[field.type];
        return (
          <tr
            key={index}
            tabIndex={0}
            className={styles.tableRow}
            id={`field-type-${props.input.name}`}
            onFocus={props.input.onFocus}
            onBlur={props.input.onBlur}
          >
            <td className={styles.tableRowTitle}>
              <div className={styles.tableRowLabelContainer}>
                <span className={styles.tableRowTitleText}>{field.label} </span>
                {field.subText ? <span className={styles.tableRowSubText}>{field.subText}</span> : null}
              </div>
            </td>
            <td
              className={styles.tableRowContent}
              style={{
                backgroundColor: !isError ? '#FFFFFF' : ErrorPalette[100],
              }}
            >
              <div>
                <Component isError={isError} {...props} {...field.props} />
              </div>
            </td>
          </tr>
        );
      }}
      validate={field.validate}
      format={(value) => {
        if (typeof value === 'string') {
          return value.trim();
        }

        return value;
      }}
      formatOnBlur
    />
  );
};

const Condition = ({ conditionalName, conditionalValue, conditionalLogic, children }: any) => {
  return (
    <Field name={conditionalName}>
      {({ input: { value } }) => (conditionalLogic(value, conditionalValue) ? children : null)}
    </Field>
  );
};

const FormTable: React.FunctionComponent<FormTableProps> = ({ fields }) => {
  const form = useForm();
  return (
    <table
      className={styles.table}
      style={{
        borderColor: GrayscalePalette[300],
      }}
    >
      <tbody>
        {fields.map((field, index) => {
          if (field.conditionalValueConfig) {
            return (
              <>
                <Condition
                  conditionalName={field.conditionalValueConfig.fieldNameToCheck}
                  conditionalValue={field.conditionalValueConfig.valueToCheck}
                  conditionalLogic={field.conditionalValueConfig.conditionalLogic}
                  key={index}
                >
                  {renderFormField(field, index)}
                </Condition>
                <OnChange name={field.conditionalValueConfig.fieldNameToCheck}>
                  {(value) => {
                    if (
                      value !== field.conditionalValueConfig!.valueToCheck &&
                      form.getFieldState(field.name) &&
                      !field.props.readOnly
                    ) {
                      form.change(field.name, '');
                      form.resetFieldState(field.name);
                    }
                  }}
                </OnChange>
              </>
            );
          } else {
            return <>{renderFormField(field, index)}</>;
          }
        })}
      </tbody>
    </table>
  );
};

export default FormTable;
