import React, { useEffect, useState } from 'react';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import ScreenLayout from '../screen/ScreenLayout';
import { NavigationPath, NavigationRoute, OktaAuthNavigationPath } from '../../../api/Navigation';
import { useHistory, useLocation } from 'react-router-dom';
import { OktaGroups } from '../../../api/Okta';
import Loading from '../screen/Loading';
import { getHipaaConsentForm, usePatientInfo } from '../../../api/PatientInfo';
import { PendoUpdateAnalytics } from './PendoAnalytics';
import { CustomUserClaim } from '@okta/okta-auth-js';

export type RouteWrapperProps = NavigationRoute;

const VerifiedRoute: React.FC<RouteWrapperProps> = (props) => {
  const { push: navigate } = useHistory();
  const { authState } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const { patientInfo } = usePatientInfo();

  const formSubmission = getHipaaConsentForm(patientInfo?.forms);

  useEffect(() => {
    // navigate to login, if user is unauthenticated
    const accessToken = authState?.accessToken;
    if (!accessToken) {
      navigate(OktaAuthNavigationPath.LOGIN);
      return;
    }

    // navigate to verify identity screen, if user is not already authenticated
    const groups = ([] as CustomUserClaim[]).concat(accessToken.claims.groups);
    if (groups && groups.length && !groups.includes(OktaGroups.PATIENTS_VERIFIED)) {
      navigate(NavigationPath.VERIFY);
      return;
    }

    // navigate to consent screen, if the user has not consented
    if (!patientInfo?.consent && !formSubmission && location.pathname !== NavigationPath.CONSENT) {
      navigate(NavigationPath.CONSENT);
      return;
    }
    setLoading(false);
  }, []);

  return (
    <ScreenLayout header={props.header}>
      {loading ? (
        <Loading />
      ) : (
        <PendoUpdateAnalytics>
          <props.component />
        </PendoUpdateAnalytics>
      )}
    </ScreenLayout>
  );
};

const VerifiedRouteWrapper: React.FC<RouteWrapperProps> = (props) => {
  return (
    <SecureRoute
      path={props.path}
      render={() => {
        return <VerifiedRoute {...props} />;
      }}
    />
  );
};

export default VerifiedRouteWrapper;
