import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Checkbox } from '@tcl-boron-prefabs/checkbox';
import { FormTableField } from './FormTable';
import styles from './styles.module.css';
import FormError from './FormError';

type FormCheckboxProps = FormTableField &
  FieldRenderProps<any> & {
    checkboxLabel?: string;
  };

const FormCheckbox: React.FunctionComponent<FormCheckboxProps> = ({ input, meta, checkboxLabel, isError }) => {
  return (
    <div>
      <Checkbox
        {...input}
        disabled={meta.submitting}
        label={checkboxLabel ?? ''}
        isSelected={input.value}
        onChange={() => input.onChange(!input.value)}
        classes={{ checkboxContainer: styles.checkboxContainer, label: styles.checkboxLabel }}
      />
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormCheckbox;
