import React, { Fragment } from 'react';
import { AsyncState, useAsync } from 'react-async';
import { createGenericContext } from '../utils';
import { fetchHelper } from './fetchHelper/fetchHelper';
import Loading from '../app/components/screen/Loading';
import ServiceUnavailable from '../app/components/screen/ServiceUnavailable';
import { Content } from './ContentTypes';
import { AuthenticationType } from './AuthenticationType';

type ContentLoaderProps = { children: JSX.Element };

const fetchContent = (): Promise<Content> => fetchHelper('/content', { authenticationType: AuthenticationType.NONE });

export const [useContentState, ContentProvider] = createGenericContext<AsyncState<Content>>();
export const useContent = (): Content | undefined => {
  const { data: content } = useContentState();
  return content;
};

export const ContentLoader = ({ children }: ContentLoaderProps): JSX.Element => {
  const state = useAsync<Content>({ promiseFn: fetchContent });

  if (state.isRejected) {
    return <ServiceUnavailable />;
  }

  return <ContentProvider value={state}>{children}</ContentProvider>;
};

export const AwaitContent = ({ children }: ContentLoaderProps): JSX.Element | null => {
  const state = useContentState();
  return state.isSettled ? <Fragment>{children}</Fragment> : <Loading />;
};
