import React from 'react';
import { FullLockedIcon } from '../../../assets/images/FullLockedIcon';
import styles from '../../forms/shared/styles.module.css';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import classNames from 'classnames';
import checklistStyles from '../checklistStyles.module.css';

const LockedOutSection: React.FunctionComponent = () => {
  return (
    <div className={styles.lockedOutContainer}>
      <FullLockedIcon />
      <div className={classNames(typography.sectionHeader, styles.lockedOutHeader)}>Locked Account</div>
      <div className={classNames(typography.body, styles.lockedOutBody)}>
        <span>Too many failed attempts. Please call our Customer Success team at</span>
        <a className={checklistStyles.checklistLink} href="tel:800.739.4137">
          &nbsp; 800.739.4137 &nbsp;
        </a>
        <span>or email</span>
        <a className={checklistStyles.checklistLink} href="mailto:support@tempus.com">
          &nbsp; techsupport@tempus.com
        </a>
        <span>.</span>
      </div>
    </div>
  );
};

export default LockedOutSection;
