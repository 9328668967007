import React, { ReactNode } from 'react';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import { css, StyleSheet } from 'aphrodite/no-important';
import { FullOutageIcon } from '../../../assets/images/FullOutageIcon';
import { FullLockedIcon } from '../../../assets/images/FullLockedIcon';

enum FullScreenErrorType {
  LOCK = 'lock',
  OUTAGE = 'outage',
}

type ErrorStateProps = {
  type: FullScreenErrorType;
  title: string;
  message: ReactNode;
};

const ERROR_STATE_STYLES = StyleSheet.create({
  container: {
    textAlign: 'center',
    paddingTop: '80px',
  },
  errorTitle: {
    paddingBottom: '10px',
  },
  errorMessage: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
});

const getErrorElement = (type: FullScreenErrorType) => {
  if (type === FullScreenErrorType.LOCK) {
    return <FullLockedIcon />;
  } else {
    return <FullOutageIcon />;
  }
};

const FullScreenError: React.FunctionComponent<ErrorStateProps> = (props) => {
  return (
    <div className={css(ERROR_STATE_STYLES.container)}>
      {getErrorElement(props.type)}
      <div className={css(ERROR_STATE_STYLES.errorTitle)}>
        <div className={typography.header}>{props.title}</div>
      </div>
      <div className={css(ERROR_STATE_STYLES.errorMessage)}>
        <div className={typography.sectionHeader}>{props.message}</div>
      </div>
    </div>
  );
};

export { FullScreenError, FullScreenErrorType };
