import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Modal } from '@tcl-argon-prefabs/modal';
import { Button } from '@tcl-argon-prefabs/button';
import { InputTheme } from '@tcl-argon-models/models';
import HelpSection from './HelpSection';
import { fetchHelper } from '../../../api/fetchHelper/fetchHelper';
import DismissableAlert from '../../../app/components/header/DismissableAlert';
import { styles as textStyles } from '../../../constants/styles';
import { isMobileDevice } from '../../../utils';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import Config from '../../../../config';
import { AuthenticationType } from '../../../api/AuthenticationType';

const CANCEL_BUTTON_TEXT = 'Cancel';
const DISCLAIMER = 'Our customer support team will email you with any follow-up about your orders.';
const HEADER = 'Not seeing all the orders you expect?';
const LINK = 'Let us know';
const SUBMIT_BUTTON_TEXT = 'Submit';
const SUCCESS_MESSAGE = 'Message sent.';
const TITLE = 'Send us a message';

const ZenDesk: FunctionComponent = () => {
  const windowDimensions = useWindowDimensions();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [zenDeskModalIsOpen, setZenDeskModalIsOpen] = useState(false);
  const { run: createZenDeskTicket, isLoading, isSettled } = useAsync({
    deferFn: async () => {
      return fetchHelper('/support/createZenDeskTicket', {
        authenticationType: AuthenticationType.OKTA,
        body: { type: 'MISSING_RESULT_AUDIT' },
        method: 'POST',
      });
    },
  });

  useEffect(() => {
    if (isSettled) {
      setZenDeskModalIsOpen(false);
      setShowSuccessAlert(true);
    }
  }, [isSettled]);
  const isTestEnvironment = Config.environment === 'test';

  return (
    <Fragment>
      {showSuccessAlert ? (
        <DismissableAlert
          message={SUCCESS_MESSAGE}
          onDismiss={() => setShowSuccessAlert(false)}
          theme={InputTheme.Success}
        />
      ) : null}
      <Modal
        ariaHideApp={!isTestEnvironment}
        className={css(isMobileDevice(windowDimensions) && styles.modalContainerMobile)}
        isOpen={zenDeskModalIsOpen}
        size="small"
        title={TITLE}
      >
        <p>{DISCLAIMER}</p>
        <div className={css(styles.buttonContainer)}>
          <Button disabled={isLoading} onClick={() => setZenDeskModalIsOpen(false)} theme={InputTheme.Default}>
            {CANCEL_BUTTON_TEXT}
          </Button>
          <Button
            className={css(styles.submit)}
            disabled={isLoading}
            onClick={createZenDeskTicket}
            theme={InputTheme.Dark}
          >
            {SUBMIT_BUTTON_TEXT}
          </Button>
        </div>
      </Modal>
      <HelpSection
        header={HEADER}
        text={() => {
          return (
            <Fragment>
              <a
                className={css(textStyles.link, textStyles.bodyText, styles.text)}
                onClick={() => setZenDeskModalIsOpen(true)}
              >
                {LINK}
              </a>
            </Fragment>
          );
        }}
      />
    </Fragment>
  );
};

export default ZenDesk;

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: '-2.14em',
    marginTop: '2.14em',
    justifyContent: 'flex-end',
  },
  modalContainerMobile: {
    maxWidth: '20em',
  },
  submit: { backgroundColor: 'black', marginLeft: '1.43em' },
  text: { color: 'black', margin: 0, textDecoration: 'underline' },
});
