import { Assay } from './result/ResultType';

export enum Styles {
  bold = 'bold',
  header = 'header',
  underline = 'underline',
}

export enum Results {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  // All other invalid/rejected statuses get rolled up into this one.
  INCONSLUSIVE = 'INCONSLUSIVE',
  // This does not map into any result from LIMS; it is reserved for when a result is missing
  // or a result data product is unrenderable.
  ERROR = 'ERROR',
}

export enum ResultsIF {
  COMPLETE = 'COMPLETE',
  INCONSLUSIVE = 'INCONSLUSIVE',
  ERROR = 'ERROR',
}

export enum textJustificationTypes {
  CENTER = 'center',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
}

type ResultsContent = {
  [Assay.SarsCov2]: Record<Results, ResultsDto>;
};

export interface ContentFieldDto {
  paragraph: {
    text: string;
    styles?: string[];
    selectable?: boolean;
  }[];
  indent?: number;
  bulletPoint?: boolean;
  justify?: textJustificationTypes;
  letterBulletPoint?: string;
  underline?: boolean;
  singleSpace?: boolean;
}

export interface LinkedResourceDto {
  badge?: string;
  text: string;
  href: string;
  analyticsId: string;
}

export interface ResultsDto {
  disclaimer: ContentFieldDto[];
  disclaimerResources?: LinkedResourceDto[];
  nextSteps?: ContentFieldDto[];
  nextStepsResources?: LinkedResourceDto[];
}

export interface Content {
  version: string;
  linkedResources: LinkedResourceDto[];
  contactUsEmail: string;
  requestForPhysicianContactInfoText?: string;
  consentHeader: ContentFieldDto[];
  consentLanguage: ContentFieldDto[];
  results: Record<Results, ResultsDto>;
  resultsV2: ResultsContent;
  termsAndConditions: ContentFieldDto[];
  hipaaAuthorization: ContentFieldDto[];
  primaryCarePhysician: ContentFieldDto;
  assistance: ContentFieldDto[];
  privacyConfirmationText: ContentFieldDto[];
}
