import { SingleSelectTypeahead } from '@tcl-boron-prefabs/single-select-typeahead';
import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styles from './styles.module.css';
import { FormTableField } from './FormTable';
import { DropdownOption } from '@tcl-argon-models/models';
import classNames from 'classnames';
import FormError from './FormError';

type FormSingleSelectTypeaheadProps = FormTableField &
  FieldRenderProps<any> & {
    placeholder?: string;
    dropdownOptions?: DropdownOption[];
  };

const FormSingleSelectTypeahead: React.FunctionComponent<FormSingleSelectTypeaheadProps> = ({
  input,
  meta,
  placeholder,
  dropdownOptions,
  isError,
}) => {
  const [textInput, setTextInput] = useState<string>();
  const [options, setOptions] = useState<DropdownOption[]>();

  const searchOptions = (dropdownOptions: any, e: string) => {
    setTextInput(e);
    setOptions(
      e
        ? dropdownOptions.filter((option: { label: string }) =>
            option.label.toLocaleLowerCase().includes(e.toLocaleLowerCase()),
          )
        : undefined,
    );
  };

  return (
    <div>
      <SingleSelectTypeahead
        {...input}
        onChange={(value) => {
          input.onChange(value ? value.value : null);
        }}
        value={dropdownOptions?.find(({ value }) => value === input.value) ?? null}
        classes={{
          root: isError ? '' : styles.inputRoot,
          inputWrapper: isError ? classNames(styles.inputWrapper, styles.textInputError) : styles.inputWrapper,
          valueContainer: isError
            ? classNames(
                styles.singleSelectTypeaheadValueContainerError,
                styles.textInputError,
                styles.textInputInputError,
              )
            : styles.singleSelectTypeaheadValueContainer,
          value: isError ? styles.singleSelectValueError : styles.singleSelectValue,
          searchBoxPositionClosed:
            input.value === ''
              ? isError
                ? classNames(styles.singleTypeaheadSearchBoxError, styles.singleTypeaheadShowPlaceholder)
                : classNames(styles.singleTypeaheadSearchBox, styles.singleTypeaheadShowPlaceholder)
              : classNames(styles.singleTypeaheadSearchBox, styles.singleTypeaheadHidePlaceholder),
        }}
        label={input.name}
        disabled={meta.submitting}
        placeholder={placeholder}
        hideLabel
        options={options ?? dropdownOptions ?? []}
        dropdownType="secondary"
        onInputValueChange={(e) => searchOptions(dropdownOptions, e)}
        inputValue={textInput}
      />
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormSingleSelectTypeahead;
