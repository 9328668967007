import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { TempusLogo } from '@tcl-argon-icons/icons';

type LogoProps = {
  style: {
    image: any;
    text: any;
    textContainer: any;
  };
};

const LOGO_TEXT = ' | COVID-19';
const LOGO_TEST_ID = 'logo-test-id';

const Logo: React.FC<LogoProps> = (props) => {
  return (
    <div className={css(LogoStyles.container)}>
      <TempusLogo className={css(props.style.image)} data-testid={LOGO_TEST_ID} />
      <div className={css(props.style.textContainer)}>
        <p className={css(props.style.text)}>{LOGO_TEXT}</p>
      </div>
    </div>
  );
};

export default Logo;
export { LOGO_TEST_ID };

const LogoStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    display: 'flex',
  },
});
