import React, { useState } from 'react';
import styles from './styles.module.css';
import { Badge } from '@tcl-boron-prefabs/badge';
import { Tooltip } from '@tcl-boron-prefabs/tooltip';
import { Information } from '@tcl-boron-icons/icons';
import { FinancialAssistanceCalculationStatusV2 } from '@tempus/patient-forms-service-shared';

export type StatusBadgeProps = {
  title: string;
  text: string;
  type: FinancialAssistanceCalculationStatusV2;
};

const FINANCIAL_ASSISTANCE_STATUS_BADGE_VARIANT_MAP: Record<FinancialAssistanceCalculationStatusV2, any> = {
  [FinancialAssistanceCalculationStatusV2.APPROVED]: 6,
  [FinancialAssistanceCalculationStatusV2.DENIED]: 8,
};

const StatusBadge: React.FunctionComponent<StatusBadgeProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tooltip isOpen={isOpen} position="below-right" classes={{ tooltip: styles.badgeTooltip }}>
      {{
        tooltip: props.text,
        base: (
          <div
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}
            className={styles.statusBadge}
          >
            <Badge
              badgeType="secondary"
              small
              text={props.title}
              Icon={() => <Information className={styles.badgeIcon} />}
              badgeVariant={FINANCIAL_ASSISTANCE_STATUS_BADGE_VARIANT_MAP[props.type]}
              className={styles.badgeRoot}
            />
          </div>
        ),
      }}
    </Tooltip>
  );
};

export default StatusBadge;
