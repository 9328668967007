import React from 'react';

const Logo: React.FunctionComponent = () => (
  <svg width="100" height="15" viewBox="0 0 100 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.1712 10.5652V13.7399H19.1755V0.32428H31.0486V3.48191H23.1507V5.32528H30.4357V8.55119H23.1507V10.5652H31.1712Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.2905 0.32428V13.7399H48.3504V6.5542L45.5309 13.7399H41.153L38.3336 6.5542V13.7399H34.3934V0.32428H39.717L43.3595 9.38753L47.0019 0.32428H52.2905Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.3599 9.66063H59.628V13.7399H55.6528V0.32428H62.3774C66.195 0.32428 68.2964 1.96283 68.2964 4.98392C68.2964 8.005 66.1775 9.66063 62.3599 9.66063ZM62.4299 3.48191H59.628V6.48593H62.4825C63.7258 6.48593 64.2512 6.11042 64.2512 4.98392C64.2512 3.85741 63.6733 3.48191 62.4299 3.48191Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.8882 0.32428H74.8634V8.005C74.8634 8.73894 75.091 9.38753 75.5464 9.96785C76.0017 10.5311 76.7722 10.8213 77.8404 10.8213C78.9261 10.8213 79.7142 10.5311 80.152 9.96785C80.6073 9.4046 80.8349 8.75601 80.8349 8.005V0.32428H84.8102V7.71484C84.8102 9.88251 84.1622 11.4699 82.8838 12.511C81.623 13.5351 79.9243 14.0472 77.8404 14.0472C75.7565 14.0472 74.0578 13.5351 72.797 12.511C71.5361 11.4869 70.8882 9.88251 70.8882 7.71484V0.32428H70.8882Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.4001 9.71183C89.013 10.002 89.7485 10.258 90.6416 10.497C91.5523 10.7018 92.4103 10.8213 93.2334 10.8213C94.8095 10.8213 95.5975 10.5823 95.5975 10.0873C95.5975 9.54115 94.7569 9.26805 93.2159 8.96083C93.0057 8.94376 92.7606 8.89255 92.4804 8.80721C89.2407 8.09034 87.437 7.21986 87.3669 4.28412C87.3669 2.86745 87.9448 1.79215 89.1356 1.09235C90.3264 0.392552 91.8675 0.0341187 93.7588 0.0341187C95.7201 0.0341187 97.9266 0.51203 99.5027 1.33131L98.2418 4.16464C96.8233 3.61846 94.9846 3.29416 93.6537 3.29416C92.2002 3.29416 91.3771 3.60139 91.3771 4.1305C91.3771 4.67669 92.1652 4.91564 93.5836 5.1546L94.144 5.27408C95.8777 5.63251 97.2261 6.12749 98.1718 6.79315C99.1349 7.44175 99.6077 8.41464 99.6077 9.69476C99.6077 11.1626 99.0123 12.255 97.7865 12.9889C96.5782 13.7058 95.0021 14.0642 93.0583 14.0642C92.0076 14.0642 90.9393 13.9277 89.8361 13.6546C88.7504 13.3644 87.8047 13.006 87.0517 12.5452L88.4001 9.71183Z"
      fill="black"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.122559 0.32428H1.87375V3.44777H0.122559V0.32428Z" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M2.8194 0.32428H6.04159V3.46484H2.8194V0.32428Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35498 3.46484V13.7399H11.3302V3.46484H13.6242V0.32428H7.35498V3.46484Z"
      fill="black"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M14.5524 0.32428H15.8833V3.46484H14.5524V0.32428Z" fill="black" />
  </svg>
);

export default Logo;
