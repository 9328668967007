import React, { Fragment, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { styles as textStyles } from '../../../constants/styles';
import HelpSection from './HelpSection';
import { tempusPalette } from '@tcl-argon-colors/colors';
import { useContent } from '../../../api/Content';

const HEADER = 'Feedback or technical issue with this app?';
const TEXT = 'Please email Tempus at ';

const Feedback: FunctionComponent = () => {
  const content = useContent()!;

  return (
    <HelpSection
      header={HEADER}
      text={() => {
        return (
          <Fragment>
            <p className={css(styles.text, textStyles.bodyText)}>
              {TEXT}
              <a
                className={css(styles.text, styles.link, textStyles.bodyText)}
                href={`mailto:${content.contactUsEmail}`}
                target="_blank"
                rel="noreferrer"
              >
                {content.contactUsEmail}
              </a>
            </p>
          </Fragment>
        );
      }}
    />
  );
};

export default Feedback;

const styles = StyleSheet.create({
  text: { margin: 0 },
  link: {
    color: tempusPalette.tempusPrimary,
    textDecoration: 'none',
  },
});
