import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import IdentityVerification from './IdentityVerification';
import { OktaAuthNavigationPath, NavigationPath } from '../../api/Navigation';
import { OktaGroups, OktaSignOutState, logOutAndClearStorage } from '../../api/Okta';
import { useAsync } from 'react-async';
import Loading from '../../app/components/screen/Loading';
import { CustomUserClaim } from '@okta/okta-auth-js';

const IdentityVerificationWrapper: FunctionComponent = () => {
  const { push: navigate } = useHistory();
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { run: refreshUserClaims, isRejected, isFulfilled } = useAsync({
    deferFn: async () => {
      const token = await oktaAuth.token.getWithoutPrompt();
      if (
        !token.tokens.accessToken ||
        !([] as CustomUserClaim[]).concat(token.tokens.accessToken.claims.groups).includes(OktaGroups.PATIENTS_VERIFIED)
      ) {
        throw Error('Invalid Claims');
      }
      await oktaAuth.handleLoginRedirect(token.tokens);
    },
  });

  const onSuccess = useCallback(() => {
    setLoading(true);
    refreshUserClaims();
  }, [refreshUserClaims]);

  useEffect(() => {
    if (isRejected) {
      logOutAndClearStorage(OktaSignOutState.SUCCESS).catch(() => {
        navigate(OktaAuthNavigationPath.LOGIN);
      });
    } else if (isFulfilled) {
      navigate(NavigationPath.CONSENT);
      setLoading(true);
    }
  }, [isRejected, isFulfilled]);

  return loading ? <Loading /> : <IdentityVerification onSuccess={onSuccess} />;
};

export default IdentityVerificationWrapper;
