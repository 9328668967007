import { Fail } from '@tcl-argon-icons/icons';
import React from 'react';
import styles from './styles.module.css';

type FormErrorProps = {
  error: string;
};

const FormError: React.FunctionComponent<FormErrorProps> = ({ error }) => {
  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorIcon}>
        <Fail />
      </div>
      <p className={styles.errorText}>{error}</p>
    </div>
  );
};

export default FormError;
