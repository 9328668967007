import React from 'react';
import { AppContext, AppContextType } from '../../App/AppContext';
import { QuestionResponse } from '../../../types';
import AnswerSet from '../AnswerOptions';
import styles from './QuestionList.module.css';

const QuestionList: React.FC = () => {
  const { currentAssessment, responses } = React.useContext(AppContext) as AppContextType;

  return (
    <div className={styles.questionList}>
      {responses.map((response: QuestionResponse, index: number) => {
        const question = currentAssessment.sections[0].questions[response.key];
        return (
          <div id={`question-${index}`} key={response.key} className={styles.container}>
            <p className={styles.title}>
              {index + 1}. {question.title}
            </p>
            <AnswerSet question={question} questionIndex={index} />
          </div>
        );
      })}
    </div>
  );
};

export default QuestionList;
