import * as Sentry from '@sentry/react';
import config from '../config';
import packageJson from '../../package.json';

if (config.sentry.enabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.sentry.environment,
    release: `${packageJson.name}@${packageJson.version}`,
  });
}

export class Logger {
  private readonly name: string;

  constructor(name: string) {
    this.name = name;
  }
  info(...args: any[]): void {
    console.log(...args);
  }
  warn(...args: any[]): void {
    console.warn(...args);
  }
  error(error: Error, params?: any): void {
    console.error(error, params);

    // Allow callers to specify values for contexts explicitly; any remaining
    // freeform values will be included as tags.
    // Tags must be primitive values.
    const { contexts, ...extraParams } = params ?? {};
    Sentry.captureException(error, {
      tags: { ...extraParams, loggerName: this.name },
      contexts,
    });
  }
}

const logger = new Logger('default');

window.addEventListener('error', (event) => {
  logger.error(event.error);
});

export default logger;
