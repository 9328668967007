import React from 'react';

export const NoFormsIcon = () => {
    return (
        <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.0456032" x="6.69727" y="89.3023" width="72.1152" height="66.9003" rx="9.59412" transform="rotate(-90 6.69727 89.3023)" fill="#042261" fillOpacity="0.73"/>
            <rect opacity="0.0456032" x="22.4014" y="78.8128" width="72.1152" height="66.9003" rx="9.59412" transform="rotate(-90 22.4014 78.8128)" fill="#042261" fillOpacity="0.73"/>
            <g filter="url(#filter0_dd_757_7621)">
                <rect x="80.0361" y="24.5903" width="46.3392" height="64.0736" rx="8.4654" transform="rotate(90 80.0361 24.5903)" fill="white"/>
            </g>
            <g filter="url(#filter1_d_757_7621)">
                <path fillRule="evenodd" clipRule="evenodd" d="M22.2549 32.8051C22.2549 31.8779 23.0066 31.1262 23.9338 31.1262H72.8492C73.7764 31.1262 74.5281 31.8779 74.5281 32.8051C74.5281 33.7324 73.7764 34.4841 72.8492 34.4841H23.9338C23.0066 34.4841 22.2549 33.7324 22.2549 32.8051Z" fill="white" fillOpacity="0.59"/>
                <path d="M23.9338 30.5618C22.6949 30.5618 21.6905 31.5662 21.6905 32.8051C21.6905 34.0441 22.6949 35.0484 23.9338 35.0484H72.8492C74.0881 35.0484 75.0925 34.0441 75.0925 32.8051C75.0925 31.5662 74.0881 30.5618 72.8492 30.5618H23.9338Z" stroke="#A0A0A3" strokeWidth="1.12872"/>
            </g>
            <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M32.1885 43.2989H74.7815V49.3723H32.1885V43.2989Z" fill="#5A6479"/>
            <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M32.1885 54.6866H74.7815V60.7601H32.1885V54.6866Z" fill="#5A6479"/>
            <ellipse cx="25.4129" cy="46.321" rx="2.90407" ry="3.02212" fill="#A0A0A3"/>
            <ellipse cx="25.4129" cy="57.7379" rx="2.90407" ry="3.02212" fill="#A0A0A3"/>
            <defs>
                <filter id="filter0_dd_757_7621" x="11.9629" y="21.5903" width="72.0732" height="59.3392" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="6"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_757_7621"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="1.5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                    <feBlend mode="normal" in2="effect1_dropShadow_757_7621" result="effect2_dropShadow_757_7621"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_757_7621" result="shape"/>
                </filter>
                <filter id="filter1_d_757_7621" x="17.126" y="28.9974" width="62.5312" height="13.6154" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.24446 0 0 0 0 0.270514 0 0 0 0 0.341231 0 0 0 0.369373 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_757_7621"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_757_7621" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
};