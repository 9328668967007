import React from 'react';
import { Assessment, QuestionResponse } from '../../types';

export type AppContextType = {
  verified: boolean;
  setVerified: (verified: boolean) => void;
  orderId: string;
  setOrderId: (orderId: string) => void;
  confirmationId: string;
  setConfirmationId: (confirmationId: string) => void;
  notificationId: string;
  setNotificationId: (notificationId: string) => void;
  assessments: Assessment[];
  setAssessments: (assessments: Assessment[]) => void;
  currentIndex: number;
  currentAssessment: Assessment;
  responses: QuestionResponse[];
  setResponses: (questionResponses: QuestionResponse[]) => void;
  updateResponse: (questionKey: string, value: number) => void;
  submitResponses: () => void;
  isAlertVisible: boolean;
  displaySuccessAlert: () => void;
  dismissSuccessAlert: () => void;
};

export const AppContext = React.createContext<AppContextType | null>(null);
