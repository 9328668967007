import * as _ from 'lodash';
import moment from 'moment';
import phone from 'phone';
import { FormTableField, FormTableFieldFrontend, ValidatedValueConfig } from './FormTable';
import { FormTableFieldBase } from '@tempus/patient-forms-service-shared';

export const DATE_FILTER_MIN = moment('1900-01-01T06:00:00.000Z').toDate();

export const LEAVE_FORM_WARNING = 'You have unsaved changes, are you sure you want to leave?';

export const isValidUSZip = (zipCode: any) => {
  //regex check for 5 digit zip OR 5 digit & '-' & 4 digits
  return /^\d{5}(-\d{4})?$/.test(zipCode);
};

export const isValidDateOfBirth = (dob: any) => {
  const doesMatchBasicDateFormat = moment(dob, 'MM/DD/YYYY', true).isValid();
  const momentDateDob = moment(dob, 'MM/DD/YYYY').toDate();
  const isValidDate = !(momentDateDob > moment().toDate()) && !(momentDateDob < DATE_FILTER_MIN);

  return doesMatchBasicDateFormat && isValidDate;
};

export const isValidPhoneNumber = (phoneNumber: string | undefined): boolean => {
  return !!phoneNumber && phone(phoneNumber, { country: 'USA' }).isValid;
};

/**
 * Checks all form values and deletes falsy string values. Per react-final-form, we should be
 * treating empty string the same as undefined. Undefined values are not set as properties on
 * the form, so we should not be submitting any falsy string fields
 * @param values - form values object
 */
export const deleteFalsyStringFields = (values: any) => {
  for (const name in values) {
    const value = values[name];
    if (typeof value === 'string') {
      if (!value) {
        delete values[name];
      }
    }
  }
};

/**
 * Checks all conditional fields and deletes any properties from the values object for fields where conditionals are not met
 * @param values - form values object containing properties that match with the array of FormTableFields
 * @param formTableFields - the form table fields object containing the property name to match on the values object
 */
export const deleteUnmetConditionalFields = (values: any, formTableFields: FormTableField[]) => {
  for (const formTableField of formTableFields) {
    if (!formTableField.conditionalValueConfig) {
      continue;
    }

    const {
      fieldNameToCheck: name,
      valueToCheck: value,
      conditionalLogic: logic,
    } = formTableField.conditionalValueConfig;
    if (
      formTableField.name in values && //property exists on values for field
      !logic(values[name], value) //conditions are not met for field
    ) {
      delete values[formTableField.name];
    }
  }
};

/**
 * Helper function to set initial values in a form via the validated values
 * The FormTableField must have the ValidatedValueConfig property set for each field that should have a default value
 * @param formTableFields - form fields object with the ValidatedValueConfig set
 * @param validatedValues - validated values object containing properties that match with the ValidatedValueConfig
 */
export const setInitialFieldsWithValidatedValues = (
  formTableFields: FormTableField[],
  validatedValues: Record<string, any>,
) => {
  for (const formTableField of formTableFields) {
    const validationConfig = formTableField.validatedValueConfig;
    if (validationConfig) {
      formTableField.props.initialValue = getValidatedValueFromConfig(validationConfig, validatedValues);
      formTableField.props.readOnly = validationConfig.readOnly;
    }
  }
};

/**
 * Helper function to set the validated values on form submission to prevent any manipulation of read only fields
 * @param values - form values object containing properties that match with the array of FormTableFields
 * @param formTableFields - the form table fields object containing the property name to match on the values object
 * @param validatedValues - validated values object containing properties that match with the ValidatedValueConfig
 */
export const setReadOnlyValidatedValuesOnSubmit = (
  values: any,
  formTableFields: FormTableField[],
  validatedValues: Record<string, any>,
) => {
  for (const formTableField of formTableFields) {
    const validationConfig = formTableField.validatedValueConfig;
    if (validationConfig && validationConfig.readOnly) {
      values[formTableField.name] = getValidatedValueFromConfig(validationConfig, validatedValues);
    }
  }
};

/**
 * Helper function to get the validated value from the config with concatenation of values as necessary
 * @param validationConfig - config of the validated value to get
 * @param validatedValues - validated values object containing properties that match with the ValidatedValueConfig
 * @returns
 */
export const getValidatedValueFromConfig = (
  validationConfig: ValidatedValueConfig,
  validatedValues: Record<string, any>,
) => {
  const validatedValuesAry = [];
  for (const validatedValueName of validationConfig.validatedValueNames) {
    const validatedValue = validatedValues[validatedValueName];
    validatedValuesAry.push(validatedValue);
  }
  return validatedValuesAry.join(validationConfig.concatenator);
};

/**
 * Helper function to add frontend logic to the base form configuration stored in patient-form-service-shared
 * @param { FormTableFieldFrontend[] } frontendConfig - config of frontend logic for forms
 * @param { FormTableFieldBase[] } baseConfig - config stored in patient-form-service-shared for a given form
 * @returns { FormTableField[] } - combined form config containing both frontend and backend logic
 */
export const combineFormConfigs = (
  frontendConfig: FormTableFieldFrontend[],
  baseConfig: FormTableFieldBase[],
): FormTableField[] => {
  const baseConfigNameMap = baseConfig.reduce((prev, curr) => {
    prev[curr.name] = _.cloneDeep(curr);
    return prev;
  }, {} as Record<string, FormTableFieldBase>);

  frontendConfig.forEach((config) => {
    const baseConfig = baseConfigNameMap[config.name];
    if (baseConfig) {
      const { props, ...frontendConfigWithoutProps } = config;
      baseConfigNameMap[config.name] = {
        ...baseConfig,
        ...frontendConfigWithoutProps,
        props: {
          ...baseConfig.props,
          ...props,
        },
      };
    }
  });

  return baseConfig.map((config) => baseConfigNameMap[config.name]);
};
