import React, { useState } from 'react';
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { useFormState } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  FINANCIAL_ASSISTANCE_FORM_V2_KEY,
  FinancialAssistanceCalculationStatusV2,
} from '@tempus/patient-forms-service-shared';
import { NavigationFormProps } from '../../custom-to-do-list/components/NavigatorChecklist';
import { FinancialAidDto, FormProps, submitFormGeneric } from '../forms';
import Form from '../shared/Form';
import FormAlert, { AlertStatus } from '../shared/FormAlert';
import FormBannerError from '../shared/FormBannerError';
import { FormTableField } from '../shared/FormTable';
import Logo from '../shared/Logo';
import { StatusBadgeProps } from '../shared/StatusBadge';
import {
  LEAVE_FORM_WARNING,
  deleteFalsyStringFields,
  deleteUnmetConditionalFields,
  setReadOnlyValidatedValuesOnSubmit,
} from '../shared/utils';
import ConsentToApplicationTableV2, { consentToApplicationV2Fields } from './ConsentToApplicationV2Table';
import ExtenuatingCircumstancesTable, { extenuatingCircumstancesV2Fields } from './ExtenuatingCircumstancesV2Table';
import OrderingPhysicianTableV2, { orderingPhysicianV2Fields } from './OrderingPhysicianV2Table';
import PatientInformationTable, { patientInformationV2Fields } from './PatientInformationV2Table';

import styles from '../shared/styles.module.css';
import { useQuery } from '../../../api/hooks';

export const FINANCIAL_ASSISTANCE_V2_KEY = FINANCIAL_ASSISTANCE_FORM_V2_KEY;

type FinancialAidV2FormProps = FormProps & {
  statement: string;
  status: FinancialAssistanceCalculationStatusV2;
  title: string;
};

const FinancialAssistanceV2: React.FunctionComponent<FinancialAidV2FormProps> = (props) => {
  const formState = useFormState();

  return (
    <div>
      {props.handleNavigationBack && (
        <Prompt when={formState.dirty && !formState.submitSucceeded} message={LEAVE_FORM_WARNING} />
      )}
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>Financial Assistance</div>
          {formState.submitFailed ? <FormBannerError /> : undefined}
          {formState.submitSucceeded && !props.handleNavigationSubmit ? (
            props.showAlert ? (
              <FormAlert
                setShowAlert={props.setShowAlert}
                message={props.statement}
                title={props.title}
                status={
                  props.status === FinancialAssistanceCalculationStatusV2.APPROVED
                    ? AlertStatus.SUCCESS
                    : AlertStatus.WARNING
                }
              />
            ) : undefined
          ) : undefined}
          <div className={styles.subtitle}>Please complete all fields</div>
          <PatientInformationTable validatedValues={props.validatedValues} />
          <OrderingPhysicianTableV2 />
          <ExtenuatingCircumstancesTable />
          <ConsentToApplicationTableV2 />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="financial-assistance-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="financial-assistance-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const getAllFinancialAssistanceV2TableFormFields = (): FormTableField[] => {
  return patientInformationV2Fields.concat(
    orderingPhysicianV2Fields,
    consentToApplicationV2Fields,
    extenuatingCircumstancesV2Fields,
  );
};

const FinancialAssistanceV2Wrapper: React.FunctionComponent<NavigationFormProps> = (props) => {
  const selectedId = props.handleNavigationSubmit ? useQuery('orderId') : null;
  const [financialAidStatus, setFinancialAidStatus] = useState<FinancialAssistanceCalculationStatusV2>(
    FinancialAssistanceCalculationStatusV2.DENIED,
  );
  const [financialAidStatement, setFinancialAidStatement] = useState<string>('');
  const [financialAidTitle, setFinancialAidTitle] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const onSubmit = React.useCallback(async (values: any) => {
    try {
      if (props.validatedValues) {
        setReadOnlyValidatedValuesOnSubmit(values, patientInformationV2Fields, props.validatedValues);
      }
      deleteUnmetConditionalFields(values, getAllFinancialAssistanceV2TableFormFields());
      deleteFalsyStringFields(values);

      const { statement, status, title } = await submitFormGeneric<
        FinancialAidDto<FinancialAssistanceCalculationStatusV2>
      >(FINANCIAL_ASSISTANCE_V2_KEY, selectedId, values);

      if (props.handleNavigationSubmit) {
        const badgeValues: StatusBadgeProps = {
          text: statement,
          title: title,
          type: status,
        };
        props.handleNavigationSubmit(FINANCIAL_ASSISTANCE_V2_KEY, badgeValues);
      } else {
        setFinancialAidStatus(status);
        setFinancialAidStatement(statement);
        setFinancialAidTitle(title);
        setShowAlert(true);
      }
    } catch (e) {
      setShowAlert(false);
      return { [FORM_ERROR]: 'Submission Failed' };
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <FinancialAssistanceV2
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        title={financialAidTitle}
        statement={financialAidStatement}
        status={financialAidStatus}
        {...props}
      />
    </Form>
  );
};

export default FinancialAssistanceV2Wrapper;
