import config from '.';

const featureEnabledInEnvironment = (toggle: string): boolean => {
  let frontendToggles;
  try {
    frontendToggles = JSON.parse(config.frontendTogglesJson);
  } catch (err) {
    frontendToggles = {};
  }
  return !!frontendToggles[toggle];
};

export { featureEnabledInEnvironment };
