import { grayPalette } from '@tcl-argon-colors/colors';
import { StyleSheet } from 'aphrodite/no-important';
import { Breakpoints } from './styles';

export const commonStyles = StyleSheet.create({
  outerContainer: {
    paddingLeft: '1.43em',
    paddingRight: '1.43em',
    marginTop: '6.07em',
    marginBottom: '1.875em',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      marginTop: '3.035em',
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  primaryButton: {
    height: '2.86em',
    borderRadius: '0.21em',
    width: '100%',
    fontSize: '0.86em',
    fontWeight: 500,
    color: grayPalette.gray0,
  },
});
