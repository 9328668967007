import React, { Fragment, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { styles as textStyles } from '../../../constants/styles';

type HelpSectionProps = {
  header: string;
  text: React.ComponentType;
};

const HelpSection: FunctionComponent<HelpSectionProps> = (props) => {
  return (
    <Fragment>
      <p className={css(styles.text, textStyles.bodyHeader)}>{props.header}</p>
      <props.text />
    </Fragment>
  );
};

export default HelpSection;

const styles = StyleSheet.create({
  text: {
    margin: 0,
  },
});
