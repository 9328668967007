import { StyleSheet } from 'aphrodite/no-important';
import { tempusPalette, grayPalette, primaryPalette } from '@tcl-argon-colors/colors';

enum Breakpoints {
  LARGE_DEVICE_BREAKPOINT = '@media (max-width: 1200px)',
  MEDIUM_DEVICE_BREAKPOINT = '@media (max-width: 800px)',
  SMALL_DEVICE_BREAKPOINT = '@media (max-width: 420px)',
}

const fonts = StyleSheet.create({
  ibmPlexSansBook: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 300,
  },
  ibmPlexSansBold: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontWeight: 700,
  },
  ibmPlexSansLight: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 200,
  },
  ibmPlexSansLightItalic: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'italic',
    fontWeight: 200,
  },
  ibmPlexSansItalic: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'italic',
    fontWeight: 400,
  },
  ibmPlexSansMedium: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  ibmPlexSansRegular: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  ibmPlexSansThin: {
    fontFamily: 'IBM Plex Sans, sans-serif',
    fontStyle: 'normal',
    fontWeight: 100,
  },
});

const text = StyleSheet.create({
  bodyHeader: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  bodyText: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  error: {
    color: primaryPalette.errorPrimary,
    fontSize: '12px',
    lineHeight: '14px',
  },
  footerText: {
    color: grayPalette.gray70,
    fontSize: '12px',
    lineHeight: '14px',
  },
  inlineLink: {
    fontSize: '12px',
    lineHeight: '14px',
    textDecoration: 'underline',
  },
  link: {
    color: tempusPalette.tempusPrimary,
    fontSize: '12px',
    lineHeight: '14px',
    cursor: 'pointer',
  },
  sectionHeader: {
    fontSize: '18px',
    lineHeight: '25px',
  },
  sectionHeaderBlack: {
    fontSize: '14px',
    letterSpacing: '2px',
    lineHeight: '20px',
  },
  sectionHeaderGrey: {
    color: grayPalette.gray60,
    fontSize: '14px',
    letterSpacing: '2px',
    lineHeight: '20px',
  },
  supportingBodyText: {
    fontSize: '12px',
    lineHeight: '14px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '29px',
  },
});

const styles = {
  bodyHeader: [fonts.ibmPlexSansMedium, text.bodyHeader],
  bodyText: [fonts.ibmPlexSansRegular, text.bodyText],
  error: [fonts.ibmPlexSansMedium, text.error],
  footerText: [fonts.ibmPlexSansRegular, text.footerText],
  inlineLink: [fonts.ibmPlexSansMedium, text.inlineLink],
  link: [fonts.ibmPlexSansMedium, text.link],
  sectionHeader: [fonts.ibmPlexSansRegular, text.sectionHeader],
  sectionHeaderBlack: [fonts.ibmPlexSansBold, text.sectionHeaderBlack],
  sectionHeaderGrey: [fonts.ibmPlexSansBold, text.sectionHeaderGrey],
  supportingBodyText: [fonts.ibmPlexSansRegular, text.supportingBodyText],
  title: [fonts.ibmPlexSansBook, text.title],
};

export { Breakpoints, fonts, styles, text };
