import React, { FocusEvent } from 'react';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import styles from './NumericInput.module.css';

type NumericInputProps = {
  value: string;
  onChange: (value: string) => void;
  label: string;
  placeholder: string;
  className: string;
  length: number;
  enablePadding?: boolean;
};

const NumericInput: React.FC<NumericInputProps> = ({
  value,
  onChange,
  label,
  placeholder,
  className,
  length,
  enablePadding,
}) => {
  const updateValue = React.useCallback(
    (value: string) => {
      if (value.length <= length) {
        onChange(value);
      }
    },
    [value, onChange],
  );

  const handleBlur = React.useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (enablePadding) {
        const stringValue = event.target.value;
        if (stringValue.length > 0 && stringValue.length < length) {
          onChange(stringValue.padStart(length, '0'));
        }
      }
    },
    [onChange],
  );

  return (
    <TextInput
      data-test-id={label}
      className={className}
      classes={{ input: styles.dobInput, label: styles.inputLabel }}
      inputType="secondary"
      value={value}
      onChange={updateValue}
      label={label}
      inputMode="numeric"
      placeholder={placeholder}
      onBlur={handleBlur}
    />
  );
};

export default NumericInput;
