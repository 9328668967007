import React, { useCallback, useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import WelcomeHeader from './WelcomeHeader';
import TermsOfUseDisclaimer from './TermsOfUseDisclaimer';
import PasswordComplexityDisclaimer from './PasswordComplexityDisclaimer';
import { NavigationPath } from '../../../api/Navigation';
import { oktaWidgetDefaultConfiguration } from '../../../api/Okta';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './OktaSignInWidgetStyles.scss';
import ResetPasswordLink from './ResetPasswordLink';
import { primaryPalette } from '@tcl-argon-colors/colors';
import ActivateAccountLink from './ActivateAccountLink';
import { useIsMounted } from '../../../api/hooks';

export enum Page {
  LOGIN = 'primary-auth',
  RECOVERY_LOADING = 'recovery-loading',
  PASSWORD_RESET = 'password-reset',
  REGISTRATION = 'password-reset',
  PRIMARY_AUTH = 'primary-auth',
}

export type OktaSignInWidgetProps = {
  activateAccount?: () => void;
  config?: OktaSignInConfig;
  onSuccess: (tokens?: Tokens) => void;
  onError: (error: OktaError) => void;
  onSessionExpired: () => void;
  setEmail?: (event: Event) => void;
  user?: Record<string, any>;
  termsOfUseDisclaimer?: string;
  passwordComplexityDisclaimer?: boolean;
  welcomeHeader?: boolean;
  signOut?: boolean;
  resetPassword?: () => void;
  page: Page;
};

const OKTA_WIDGET_CONTAINER_ID = 'okta-widget-container';
const TERMS_OF_USE_DISCLAMIER_ID = 'terms-of-use-disclaimer';
const TERMS_OF_USE_LOGIN_DISCLAIMER_ID = 'terms-of-use-login-disclaimer';
const PASSWORD_COMPLEXITY_DISCLAMIER_ID = 'password-complexity-disclaimer';
const WELCOME_HEADER_ID = 'welcome-header';
const ACTIVATE_ACCOUNT_LINK_ID = 'activate-account-link';
const RESET_PASSWORD_LINK_ID = 'reset-password-link';
const PASSWORD_INPUT_PLACEHOLDER = 'Enter password';
const EMAIL_INPUT_PLACEHOLDER = 'Enter email';
const SUBMIT_CONTAINER_SELECTOR = '[data-type=save]';
const HEADER_CONTAINER_SELECTOR = '[data-se=o-form-head]';
const PASSWORD_COMPLEXITY_SELECTOR = '[data-se=o-form-explain]';
const SIGN_OUT_LINK_SELECTOR = '[data-se=signout-link]';
const FORGOT_PASSWORD_LINK_SELECTOR = '[data-se=forgot-password]';
const INPUT_CONTAINER_SELECTOR = '[data-se=o-form-fieldset-container]';
const PASSWORD_INPUTS_SELECTOR = 'input[type=password]';
const EMAIL_INPUTS_SELECTOR = 'input[type=text]';
const ERROR_CONTAINER_SELECTOR = '[data-se=o-form-error-container]';
const INVALID_SESSION_ERROR = 'E0000011';
const LOGIN_FAILED_ERROR_MESSAGE =
  'Incorrect password. Too many incorrect attempts will lock your account. Consider resetting your password below.';

const isFailedLogin = (error?: Error) => error?.message?.includes('Authentication failed');

const OktaSignInWidget: React.FC<OktaSignInWidgetProps> = (props) => {
  const widgetRef = useRef(null);

  const isMounted = useIsMounted();

  const renderTermsOfUseDisclaimer = useCallback((disclaimerText) => {
    const isLoginDisclaimer = !disclaimerText.includes('creating an account');
    const submitContainer = document.querySelector(SUBMIT_CONTAINER_SELECTOR);
    const termOfUseDisclaimerQuery = isLoginDisclaimer
      ? document.getElementById(TERMS_OF_USE_LOGIN_DISCLAIMER_ID)
      : document.getElementById(TERMS_OF_USE_DISCLAMIER_ID);

    if (submitContainer && !termOfUseDisclaimerQuery) {
      const termOfUseDisclaimer = TermsOfUseDisclaimer({
        id: isLoginDisclaimer ? TERMS_OF_USE_LOGIN_DISCLAIMER_ID : TERMS_OF_USE_DISCLAMIER_ID,
        href: NavigationPath.TERMS,
        disclaimerText,
      });
      isLoginDisclaimer ? submitContainer.after(termOfUseDisclaimer) : submitContainer.before(termOfUseDisclaimer);
    }
  }, []);

  const renderPasswordComplexityDisclaimer = useCallback(() => {
    const inputContainer = document.querySelector(INPUT_CONTAINER_SELECTOR);
    const complexityDisclaimerContainer = document.querySelector(PASSWORD_COMPLEXITY_SELECTOR);
    const passwordComplexityDisclaimerQuery = document.getElementById(PASSWORD_COMPLEXITY_DISCLAMIER_ID);
    if (inputContainer && complexityDisclaimerContainer && !passwordComplexityDisclaimerQuery) {
      const passwordComplexityDisclaimer = PasswordComplexityDisclaimer({
        id: PASSWORD_COMPLEXITY_DISCLAMIER_ID,
        passwordComplexity: complexityDisclaimerContainer.innerHTML,
      });
      inputContainer.after(passwordComplexityDisclaimer);
      complexityDisclaimerContainer.remove();
    }
  }, []);

  const removeSignOutLink = useCallback(() => {
    const signOutLink = document.querySelector(SIGN_OUT_LINK_SELECTOR);
    if (signOutLink) {
      signOutLink.remove();
    }
  }, []);

  const insertPasswordInputLabels = useCallback(() => {
    const passwordInputs = document.querySelectorAll<HTMLInputElement>(PASSWORD_INPUTS_SELECTOR);
    if (passwordInputs.length === 2) {
      passwordInputs.forEach((input: HTMLInputElement) => {
        input.setAttribute('placeholder', PASSWORD_INPUT_PLACEHOLDER);
      });
    }
  }, []);

  const removeForgotPasswordLink = useCallback(() => {
    const forgotPasswordLink = document.querySelector(FORGOT_PASSWORD_LINK_SELECTOR);
    if (forgotPasswordLink) {
      forgotPasswordLink.remove();
    }
  }, []);

  const insertSignInInputLabels = useCallback(() => {
    const emailInput = document.querySelector<HTMLInputElement>(EMAIL_INPUTS_SELECTOR);
    const passwordInput = document.querySelector<HTMLInputElement>(PASSWORD_INPUTS_SELECTOR);
    if (emailInput && passwordInput) {
      passwordInput.setAttribute('placeholder', PASSWORD_INPUT_PLACEHOLDER);
      emailInput.setAttribute('placeholder', EMAIL_INPUT_PLACEHOLDER);
    }
  }, []);

  const renderWelcomeHeader = useCallback(() => {
    const header = document.querySelector(HEADER_CONTAINER_SELECTOR);
    const welcomeHeader = document.getElementById(WELCOME_HEADER_ID);
    if (header && !welcomeHeader) {
      const welcomeHeaderElement = WelcomeHeader({
        id: WELCOME_HEADER_ID,
        user: props.user,
      });
      header.after(welcomeHeaderElement);
    }
  }, []);

  const renderResetPasswordLink = useCallback(() => {
    const submitContainer = document.querySelector(SUBMIT_CONTAINER_SELECTOR);
    const resetPasswordLink = document.getElementById(RESET_PASSWORD_LINK_ID);
    if (submitContainer && !resetPasswordLink && props.resetPassword) {
      const resetPasswordLink = ResetPasswordLink({
        id: RESET_PASSWORD_LINK_ID,
        open: props.resetPassword,
      });
      submitContainer.before(resetPasswordLink);
    }
  }, []);

  const renderActivateAccountLink = useCallback(() => {
    const submitContainer = document.querySelector(SUBMIT_CONTAINER_SELECTOR);
    const activateAccountLink = document.getElementById(ACTIVATE_ACCOUNT_LINK_ID);
    if (submitContainer && !activateAccountLink && props.activateAccount) {
      const activateAccountLink = ActivateAccountLink({
        id: ACTIVATE_ACCOUNT_LINK_ID,
        open: props.activateAccount,
      });
      submitContainer.before(activateAccountLink);
    }
  }, []);

  // On a mobile browser the device will focus on the widget input which zooms in cutting off the screen. This resets the screen view.
  const unFocusPasswordInput = useCallback(() => {
    const passwordInputs = document.querySelectorAll<HTMLInputElement>(PASSWORD_INPUTS_SELECTOR);

    if (passwordInputs.length === 2) {
      passwordInputs[0].blur();
      window.scrollTo(0, 0);
    }
  }, []);

  const unFocusEmailInput = useCallback(() => {
    const emailInput = document.querySelector<HTMLInputElement>(EMAIL_INPUTS_SELECTOR);

    if (emailInput) {
      if (props.setEmail) {
        emailInput.onchange = props.setEmail;
      }
      const unFocus = () => {
        emailInput.blur();
        window.scrollTo(0, 0);
        emailInput.removeEventListener('focus', unFocus);
      };

      emailInput.addEventListener('focus', unFocus);
    }
  }, []);

  const clearExistingRenderedDOMElements = useCallback(() => {
    const renderedDomElements: { [key: string]: HTMLElement | null } = {
      termOfUseDisclaimerQueryLogin: document.getElementById(TERMS_OF_USE_LOGIN_DISCLAIMER_ID),
      termOfUseDisclaimerQueryRecovery: document.getElementById(TERMS_OF_USE_DISCLAMIER_ID),
      passwordComplexityDisclaimerQuery: document.getElementById(PASSWORD_COMPLEXITY_DISCLAMIER_ID),
      welcomeHeader: document.getElementById(WELCOME_HEADER_ID),
      resetPasswordLink: document.getElementById(RESET_PASSWORD_LINK_ID),
      activateAccountLink: document.getElementById(ACTIVATE_ACCOUNT_LINK_ID),
    };

    Object.keys(renderedDomElements).forEach((key) => {
      if (renderedDomElements[key]) {
        renderedDomElements[key]?.remove();
      }
    });
  }, []);

  const renderDOMComponents = useCallback(() => {
    if (props.termsOfUseDisclaimer) {
      renderTermsOfUseDisclaimer(props.termsOfUseDisclaimer);
    }
    if (props.passwordComplexityDisclaimer) {
      renderPasswordComplexityDisclaimer();
    }
    if (!props.signOut) {
      removeSignOutLink();
    }
    if (props.welcomeHeader) {
      renderWelcomeHeader();
    }
    if (props.config?.recoveryToken) {
      insertPasswordInputLabels();
    }
    if (props.resetPassword) {
      renderResetPasswordLink();
    }
    if (props.activateAccount) {
      renderActivateAccountLink();
    }
    if (props.page === Page.LOGIN) {
      removeForgotPasswordLink();
      insertSignInInputLabels();
    }
    unFocusEmailInput();
    unFocusPasswordInput();
  }, [
    renderTermsOfUseDisclaimer,
    renderPasswordComplexityDisclaimer,
    removeSignOutLink,
    insertPasswordInputLabels,
    insertSignInInputLabels,
    renderWelcomeHeader,
    unFocusEmailInput,
    unFocusPasswordInput,
  ]);

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const widget = new OktaSignIn(props.config ?? oktaWidgetDefaultConfiguration);

    widget.showSignInToGetTokens({ el: widgetRef.current as any }).then(props.onSuccess);

    clearExistingRenderedDOMElements();

    widget.on('afterRender', async (context: any) => {
      if (isMounted()) {
        if (context.controller !== props.page && context.controller !== Page.RECOVERY_LOADING) {
          props.onSessionExpired();
        } else if (context.controller === props.page) {
          renderDOMComponents();
        }
      }
    });

    widget.on('afterError', (context: any, error: OktaError) => {
      if (isMounted()) {
        if (isFailedLogin(error)) {
          const errorContainer = document.querySelector<HTMLInputElement>(ERROR_CONTAINER_SELECTOR);
          const errorContainerText = errorContainer?.getElementsByTagName('p')[0];
          if (errorContainerText) {
            errorContainerText.style.color = primaryPalette.errorPrimary;
            errorContainerText.innerText = LOGIN_FAILED_ERROR_MESSAGE;
          }
        }
        props.onError(error);
      }
    });

    return () => {
      widget.remove();
    };
  }, [widgetRef]);

  return (
    <React.Fragment>
      <div id={OKTA_WIDGET_CONTAINER_ID} ref={widgetRef as any} />
    </React.Fragment>
  );
};

export default OktaSignInWidget;
export {
  OKTA_WIDGET_CONTAINER_ID,
  TERMS_OF_USE_DISCLAMIER_ID,
  TERMS_OF_USE_LOGIN_DISCLAIMER_ID,
  WELCOME_HEADER_ID,
  PASSWORD_COMPLEXITY_DISCLAMIER_ID,
  SUBMIT_CONTAINER_SELECTOR,
  RESET_PASSWORD_LINK_ID,
  isFailedLogin,
  ACTIVATE_ACCOUNT_LINK_ID,
  INVALID_SESSION_ERROR,
};
