import React from 'react';
import { Success } from '@tcl-boron-icons/icons';
import styles from './SuccessPage.module.css';

const SuccessPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <Success classes={{ root: styles.icon }} />
      <h1 className={styles.header}>Great job!</h1>
      <p className={styles.text}>You are all done.</p>
      <p className={styles.text}>It&apos;s safe to close this tab now.</p>
    </div>
  );
};

export default SuccessPage;
