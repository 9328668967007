import { Assessment, QuestionResponse } from './types';
import { OverdueAssessmentsResponse } from '@tempus/patient-report-outcome-service-shared';
import { AuthenticationType } from '../../../api/AuthenticationType';
import fetchHelper, { setIdentityTokenV3AndGetLockoutStatus } from '../../../utils';
import { ConsentAppVerify } from '@tempus/patient-forms-service-shared';

const client = {
  async getAssessments(orderId: string): Promise<Assessment[]> {
    const response: OverdueAssessmentsResponse = await fetchHelper(
      `/v1/neuro/assessments/overdue-assessments/${orderId}`,
      {
        authenticationType: AuthenticationType.IDENTITY,
        method: 'GET',
      },
    );
    return response.assessmentDetails;
  },

  async submitAssessment(
    assessment: Assessment,
    userResponse: QuestionResponse[],
    notificationId: string,
    orderId: string,
  ): Promise<void> {
    const transformedAssessment = {
      assessmentName: assessment.fullName,
      assessmentId: assessment.id,
      questions: assessment.sections.flatMap((section) =>
        Object.values(section.questions).map((question) => ({
          title: question.title,
          answerOptions: question.answers ? question.answers : section.answerOptions,
        })),
      ),
      answers: userResponse.map((response: any) => ({
        questionId: response.key,
        value: response.value,
      })),
    };

    try {
      return fetchHelper(`/v4/form/verified`, {
        authenticationType: AuthenticationType.IDENTITY,
        method: 'POST',
        body: {
          formType: 'NEURO_OUTCOME_MEASURE',
          orderId,
          data: { assessment: transformedAssessment, notificationId },
        },
      });
    } catch (error) {
      // Catch the error so we don't show anything to the user
    }
  },

  async setTermsOfUse(notificationId: string, orderId: string, accepted: boolean): Promise<any> {
    const response: any = await fetchHelper(`/v1/neuro/terms-of-use`, {
      authenticationType: AuthenticationType.NONE,
      method: 'POST',
      body: {
        notificationId,
        orderId,
        accepted,
      },
    });
    return response;
  },

  async getNotificationStatus(notificationId: string): Promise<any> {
    const response: any = await fetchHelper(`/v1/neuro/assessments/status/${notificationId}`, {
      authenticationType: AuthenticationType.NONE,
      method: 'GET',
    });
    return response;
  },

  async verifyIdentityV3(month: string, day: string, year: string, orderId: string): Promise<ConsentAppVerify> {
    return await setIdentityTokenV3AndGetLockoutStatus({ dateOfBirth: { month, day, year } }, orderId);
  },
};

export default client;
