import React from 'react';
import { Form as ReactFinalForm, FormProps as ReactFinalFormProps } from 'react-final-form';

const Form: React.FunctionComponent<ReactFinalFormProps> = ({ children, onSubmit }) => {
  const onSubmitWrapper: typeof onSubmit = async (...args) => {
    const result = await onSubmit.apply(this, args);
    window.scrollTo(0, 0);
    return result;
  };

  return (
    <ReactFinalForm
      onSubmit={onSubmitWrapper}
      render={({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
    />
  );
};

export default Form;
