type ActivateAccountLinkProps = {
  id: string;
  open: () => void;
};

const ACTIVATE_ACCOUNT_LINK_TEXT = "Don't have an account?";

const ActivateAccountLink = (props: ActivateAccountLinkProps): HTMLDivElement => {
  const activateAccountContainer = document.createElement('div');
  const activateAccountLink = document.createElement('a');
  activateAccountContainer.id = props.id;
  const activateAccountLinkText = document.createTextNode(ACTIVATE_ACCOUNT_LINK_TEXT);
  activateAccountLink.appendChild(activateAccountLinkText);
  activateAccountLink.onclick = props.open;
  activateAccountContainer.appendChild(activateAccountLink);
  return activateAccountContainer;
};

export default ActivateAccountLink;
