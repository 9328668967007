type UserId = {
  userId: string;
  email: string;
};

type UserData = {
  institution: string;
  zip: string;
};

const initAnalytics = (): void => {
  if (pendo) {
    pendo.initialize();
  }
};

const identifyAnalytics = (userId: UserId): void => {
  if (pendo) {
    const visitor: Visitor = {
      email: userId.email,
      id: userId.userId,
    };
    pendo.identify({ visitor });
  }
};

const updateAnalytics = (userData: UserData) => {
  if (pendo) {
    const visitorMetadata: Visitor = {
      orderingInstitution: userData.institution,
      zip: userData.zip,
    };
    pendo.updateOptions({
      visitor: visitorMetadata,
    });
  }
};

export { initAnalytics, identifyAnalytics, updateAnalytics };
