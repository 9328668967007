import React, { FunctionComponent } from 'react';
import AccountContent from './component/AccountContent';
import AccountLayout from './component/AccountLayout';
import Footer from './component/Footer';
import UserContent from './component/UserContent';

const Account: FunctionComponent = () => {
  return <AccountLayout content={AccountContent} footer={Footer} userContent={UserContent} />;
};

export default Account;
