import { useAsync } from 'react-async';
import { isICResult, ResultType } from './result/ResultType';
import { fetchHelper } from './fetchHelper/fetchHelper';
import { ICResultWrapper } from './result/Result';

type FetchResultOptions = {
  orderId: string;
  manifestId: string;
};

type FetchResultPDFOptions = {
  orderId: string;
  manifestId: string;
};

type ResultState = {
  isLoading: boolean;
  error?: Error;
  data: {
    result?: ICResultWrapper;
    results?: ResultType[];
    pdf?: Blob;
  };
};

// Typed as unknown due to an existing useAsync issue (https://github.com/async-library/react-async/issues/256).
const fetchResult = async (options: unknown): Promise<ResultType[]> => {
  const optionsTyped = options as FetchResultOptions;

  const url = `/v2/orders/${optionsTyped.orderId}/reports/${optionsTyped.manifestId}`;

  const result = await fetchHelper<ResultType[]>(url);

  return result;
};

// Typed as unknown due to an existing useAsync issue (https://github.com/async-library/react-async/issues/256).
const fetchResultPDF = async (options: unknown): Promise<Blob> => {
  const optionsTyped = options as FetchResultPDFOptions;

  const url = `/v2/pdf/orders/${optionsTyped.orderId}/reports/${optionsTyped.manifestId}`;

  const pdf = await fetchHelper<Blob>(url);

  return pdf;
};

const useResult = (orderId: string, manifestId: string): ResultState => {
  const fetchResultState = useAsync(fetchResult, { orderId, manifestId });
  const fetchResultPDFState = useAsync(fetchResultPDF, { orderId, manifestId });

  const result =
    !fetchResultState.isLoading &&
    fetchResultState.data &&
    fetchResultState.data[0] &&
    isICResult(fetchResultState.data[0])
      ? new ICResultWrapper(fetchResultState.data[0])
      : undefined;

  return {
    isLoading: fetchResultState.isLoading || fetchResultPDFState.isLoading,
    error: fetchResultState.error ?? fetchResultPDFState.error,
    data: {
      result,
      results: fetchResultState.data,
      pdf: fetchResultPDFState.data,
    },
  };
};

export { fetchResult, fetchResultPDF, useResult };
