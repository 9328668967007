import React, { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { LoadingIndicator, Report, ResultHeader, ResultScreenLayout } from './components';
import { useResult } from '../../api/Result';
import ResultUnavailable from './ResultUnavailable';
import { useOrder } from '../../api/Orders';
import { fonts } from '../../constants/styles';

type ResultProps = {
  orderId: string;
  manifestId: string;
};

const Result: FunctionComponent<ResultProps> = (props) => {
  const orderState = useOrder(props.orderId);
  const resultState = useResult(props.orderId, props.manifestId);

  if (orderState.isLoading || resultState.isLoading) {
    return <LoadingIndicator scale={2} />;
  } else if (orderState.error || resultState.error) {
    return <ResultUnavailable />;
  } else if (orderState.data && resultState.data.pdf && resultState.data.results) {
    return (
      <div className={css(styles.bodyText, styles.container, fonts.ibmPlexSansRegular)}>
        <ResultScreenLayout
          header={<ResultHeader result={resultState.data.result} order={orderState.data} />}
          report={
            <Report
              order={orderState.data}
              result={resultState.data.result}
              results={resultState.data.results}
              pdf={resultState.data.pdf}
            />
          }
        />
      </div>
    );
  }

  return <ResultUnavailable />;
};

export default Result;

const styles = StyleSheet.create({
  bodyText: {
    color: 'black',
    fontSize: '14px',
    lineHeight: '20px',
  },
  container: {
    display: 'flex',
    flex: '1',
  },
});
