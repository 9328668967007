import React, { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import { BackButton, ResultUnavailableBanner } from './components';
import { isMobileDevice } from '../../utils';
import { useWindowDimensions } from '../../api/WindowDimensions';
import { styles as textStyles } from '../../constants/styles';

const CONTENT =
  'An error has occured when fetching your result. Please try again later, or contact your ordering physician.';

const ResultUnavailable: FunctionComponent = () => {
  const windowDemension = useWindowDimensions();

  const layoutStyles = isMobileDevice(windowDemension) ? styles.mobileLayout : styles.browserLayout;
  const paddingHorizontalStyles = isMobileDevice(windowDemension)
    ? styles.mobilePaddingHorizontal
    : styles.browserPaddingHorizontal;
  const paddingVerticalStyles = isMobileDevice(windowDemension)
    ? styles.mobilePaddingVertical
    : styles.browserPaddingVertical;

  return (
    <div className={css(layoutStyles, styles.container)}>
      <div className={css(paddingVerticalStyles, styles.header)}>
        <div className={css(paddingHorizontalStyles, styles.backButtonContainer)}>
          <BackButton />
        </div>
        <ResultUnavailableBanner />
        <p className={css([paddingHorizontalStyles, textStyles.bodyText, styles.content])}>{CONTENT}</p>
      </div>
      {!isMobileDevice(windowDemension) ? <div className={css(styles.layoutPadding)} /> : null}
    </div>
  );
};

export default ResultUnavailable;

const styles = StyleSheet.create({
  content: {
    textAlign: 'center',
    marginTop: '1.42em',
  },
  backButtonContainer: {
    marginBottom: '2.857em',
  },
  browserLayout: {
    flexDirection: 'row',
  },
  browserPaddingVertical: {
    padding: '2.143em 0',
  },
  browserPaddingHorizontal: {
    padding: '0 2.857em',
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  header: {
    backgroundColor: 'white',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  layoutPadding: {
    backgroundColor: grayPalette.gray5,
    display: 'flex',
    flex: '2',
    flexDirection: 'column',
  },
  mobileLayout: {
    flexDirection: 'column',
  },
  mobilePaddingVertical: {
    padding: '1.429em 0',
  },
  mobilePaddingHorizontal: {
    padding: '0 2.143em',
  },
});
