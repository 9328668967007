import React from 'react';

export const GrayCheckmark = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="gray-checkmark">
            <circle cx="10" cy="10" r="9.5" stroke="#C4C4C5"/>
                <g clipPath="url(#clip0_97_5679)">
                    <path fillRule="evenodd" clipRule="evenodd" 
                    d="M5.25058 10.6652H5.25114H5.25058ZM8.41431 15.1724C8.40932 15.1724 8.40488 15.1724 
                    8.39988 15.1719C8.15349 15.1658 7.93484 15.0169 7.84161 14.7915C7.32107 13.5322 5.69176 
                    10.9659 5.21617 10.6526C4.91484 10.5333 4.75502 10.1997 4.85879 9.89021C4.96645 9.56921 
                    5.31606 9.39552 5.64126 9.5016C6.39376 9.74895 7.65238 11.7849 8.40432 13.1667C9.72842 
                    10.502 12.0836 6.10911 13.3655 4.38758C13.5681 4.1144 13.956 4.05669 14.2307 4.25786C14.5059 
                    4.45849 14.5642 4.84215 14.3616 5.11478C12.9804 6.96988 10.0897 12.4516 8.97536 14.8184C8.87325 
                    15.0345 8.6546 15.1724 8.41431 15.1724Z" 
                    fill="#C4C4C5"/>
                </g>
                <defs>
                <clipPath id="clip0_97_5679">
                <rect width="11.0345" height="11.0345" fill="white" transform="translate(4.1377 4.13794)"/>
                </clipPath>
            </defs>
        </svg>
    );
}