import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import { styles, fonts } from '../../../../constants/styles';

const TABS_LIST = ['Tempus Labs, Inc.', '600 W. Chicago Ave.', 'Chicago, IL 60654', 'support@tempus.com'];
const TEMPUS_LINK = 'https://www.tempus.com';
const FOOTER_TEXT = `More information about Tempus is available at `;
const CONTACT_US_TEXT = 'How to contact us';

const DisclaimerNoticeFooter: React.FC = () => {
  const isLastTab = (i: number) => i === TABS_LIST.length - 1;

  return (
    <div className={css(FooterStyles.container)}>
      <div className={css([FooterStyles.footerCard, styles.footerText])}>
        <div className={css([fonts.ibmPlexSansBold, FooterStyles.footerHeader])}>{CONTACT_US_TEXT}</div>
        <div className={css(FooterStyles.tabs)}>
          {TABS_LIST.map((tab, i) =>
            isLastTab(i) ? (
              <a key={i} className={css(styles.link)} href={`mailto:${tab}`}>
                {tab}
              </a>
            ) : (
              <div key={i}>{tab} &nbsp;|&nbsp;&nbsp;</div>
            ),
          )}
        </div>
        <div>
          {FOOTER_TEXT}
          <a className={css(styles.link)} href={TEMPUS_LINK}>
            {TEMPUS_LINK}
          </a>
        </div>
      </div>
    </div>
  );
};

const FooterStyles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1.875em',
    paddingBottom: '3.125em',
  },
  footerCard: {
    width: '35.71em',
    textAlign: 'center',
    borderTop: `0.07em solid ${grayPalette.gray45}`,
    paddingTop: '1.875em',
    ':nth-child(1n) > div': {
      marginBottom: '1.07em',
    },
    '@media (max-width: 800px)': {
      width: '27.36em',
    },
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  footerHeader: {
    color: grayPalette.gray100,
  },
});

export { DisclaimerNoticeFooter as default, CONTACT_US_TEXT };
