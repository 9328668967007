import React from 'react';
import _ from 'lodash';
import { Route, Switch, useHistory } from 'react-router-dom';
import { NavigationFormProps } from '../../../../custom-to-do-list/components/NavigatorChecklist';
import { featureEnabledInEnvironment } from '../../../../../../config/feature-toggles';
import NotFound from '../NotFound';
import VerificationForm from '../Verification';
import AssessmentsForm from '../AssessmentsForm';
import LandingPage from '../LandingPage';
import SuccessPage from '../SuccessPage';
import { AppContext } from './AppContext';
import { Assessment, QuestionResponse } from '../../types';
import styles from './styles.module.css';
import { TEMPUS_PRO_PATH } from '../..';
import LinkExpiredPage from '../ErrorPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import { emptyResponsesForAssessment } from '../../utils';
import client from '../../client';
import VerifiedRoute from '../VerifiedRoute';
import TermsOfUse from '../TermsOfUse';

export const App: React.FC<NavigationFormProps> = () => {
  const history = useHistory();
  const [assessments, setAssessments] = React.useState<Assessment[]>([]);
  const [orderId, setOrderId] = React.useState<string>('');
  const [verified, setVerified] = React.useState<boolean>(false);
  const [confirmationId, setConfirmationId] = React.useState<string>('');
  const [notificationId, setNotificationId] = React.useState<string>('');
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const [responses, setResponses] = React.useState<QuestionResponse[]>([]);

  const [isAlertVisible, setIsAlertVisible] = React.useState<boolean>(false);
  const displaySuccessAlert = () => {
    setIsAlertVisible(true);
  };

  const dismissSuccessAlert = () => {
    setIsAlertVisible(false);
  };

  const currentAssessment = React.useMemo(() => {
    return assessments[currentIndex];
  }, [assessments, currentIndex]);

  const submitResponses = React.useCallback(() => {
    client.submitAssessment(assessments[currentIndex], responses, notificationId, orderId).catch(() => {
      /* TODO: NP-1687 will likely be replaced with an error handling screen or logging later */
    });

    setCurrentIndex((prevIndex: number) => prevIndex + 1);
    if (assessments.length > 1 && currentIndex < assessments.length - 1) {
      setResponses(emptyResponsesForAssessment(assessments[currentIndex + 1]));
      // After we take the first assessment, remove the notification id from the url
      // This way, users can't refresh and use an expired link
      history.replace({ search: '' });
      displaySuccessAlert();
    } else {
      setResponses([]);
      history.push(`${TEMPUS_PRO_PATH}/complete`);
    }
  }, [responses, assessments]);

  const scrollToNextQuestion = () => {
    const nextIndex = responses.findIndex((response: QuestionResponse) => response.value === -1);
    if (nextIndex === -1) {
      const submitButton = document.getElementById('assessmentSubmit');
      if (submitButton) {
        window.scrollTo({ top: submitButton.offsetTop, behavior: 'smooth' });
      }
    } else {
      const nextQuestion = document.getElementById(`question-${nextIndex}`);
      if (nextQuestion) {
        window.scrollTo({ top: nextQuestion.offsetTop, behavior: 'smooth' });
      }
    }
  };

  const updateResponse = React.useCallback(
    (questionKey: string, value: number) => {
      const newResponses = [...responses];
      const responseToUpdate = _.find(newResponses, { key: questionKey });
      if (responseToUpdate) {
        responseToUpdate.value = value;
      }
      scrollToNextQuestion();
      setResponses(newResponses);
    },
    [responses],
  );

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentIndex]);

  return (
    <div className={styles.screen}>
      <AppContext.Provider
        value={{
          verified,
          setVerified,
          confirmationId,
          setConfirmationId,
          setOrderId,
          orderId,
          notificationId,
          setNotificationId,
          assessments,
          setAssessments,
          currentIndex,
          currentAssessment,
          responses,
          setResponses,
          updateResponse,
          submitResponses,
          isAlertVisible,
          displaySuccessAlert,
          dismissSuccessAlert,
        }}
      >
        <TermsOfUse />
        <Switch>
          {featureEnabledInEnvironment('enableTempusPRO') && (
            <Route exact path={`${TEMPUS_PRO_PATH}/`} render={(routeProps) => <VerificationForm {...routeProps} />} />
          )}
          {featureEnabledInEnvironment('enableTempusPRO') && (
            <VerifiedRoute exact path={`${TEMPUS_PRO_PATH}/start`}>
              <LandingPage />
            </VerifiedRoute>
          )}
          {featureEnabledInEnvironment('enableTempusPRO') && (
            <VerifiedRoute exact path={`${TEMPUS_PRO_PATH}/form`}>
              <AssessmentsForm />
            </VerifiedRoute>
          )}
          {featureEnabledInEnvironment('enableTempusPRO') && (
            <VerifiedRoute exact path={`${TEMPUS_PRO_PATH}/complete`}>
              <SuccessPage />
            </VerifiedRoute>
          )}
          {featureEnabledInEnvironment('enableTempusPRO') && (
            <Route exact path={`${TEMPUS_PRO_PATH}/invalid`}>
              <LinkExpiredPage />
            </Route>
          )}
          {featureEnabledInEnvironment('enableTempusPRO') && (
            <Route exact path={`${TEMPUS_PRO_PATH}/error`}>
              <ErrorPage />
            </Route>
          )}
          <Route path="*" render={() => <NotFound />} />
        </Switch>
      </AppContext.Provider>
    </div>
  );
};
