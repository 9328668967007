import React, { FunctionComponent, useEffect, useState } from 'react';
import { oktaWidgetDefaultConfiguration } from '../../../api/Okta';
import OktaSignInWidget, { INVALID_SESSION_ERROR, Page } from '../../../app/components/okta/OktaSignInWidget';
import { useQuery } from '../../../api/hooks';
import { useOktaAuth } from '@okta/okta-react';
import { AuthnTransaction } from '@okta/okta-auth-js';
import Error from '../../../app/components/screen/Error';
import ContentLayout from '../../../app/components/screen/ContentLayout';
import SessionExpiredError from '../../../app/components/screen/SessionExpiredError';
import InvalidTokenError from '../../../app/components/screen/InvalidTokenError';
import Loading from '../../../app/components/screen/Loading';
import { css } from 'aphrodite';
import { commonStyles } from '../../../constants/commonStyles';

const CREATE_PASSWORD_DISCLAIMER_TEXT = 'By creating an account, you confirm that you have read and agree to the ';

const OktaSignInWidgetConfig: OktaSignInConfig = {
  ...oktaWidgetDefaultConfiguration,
  i18n: {
    en: {
      'password.newPassword.placeholder': 'Create password*',
      'password.newPassword.tooltip': ' ',
      'password.confirmPassword.placeholder': 'Confirm password*',
      'password.confirmPassword.tooltip': ' ',
      'password.reset.title.generic': 'Activate Account',
      'password.reset': 'Agree to Terms and Continue',
    },
  },
};

const CreatePassword: FunctionComponent = () => {
  const activationToken = useQuery('activationToken');
  const { oktaAuth } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [verifyTokenError, setVerifyTokenError] = useState<boolean>(false);
  const [sessionExpiredError, setSessionExpiredError] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<AuthnTransaction>();

  const onSuccess = (tokens?: Tokens) => {
    if (tokens?.accessToken?.accessToken) {
      oktaAuth.handleLoginRedirect(tokens).catch(() => {
        setError(true);
      });
    } else {
      setError(true);
    }
  };

  const onError = (error: OktaError) => {
    if (error?.xhr?.responseJSON?.errorCode === INVALID_SESSION_ERROR) {
      setSessionExpiredError(true);
    }
  };

  const onSessionExpired = () => {
    setSessionExpiredError(true);
  };

  const getTransaction = async (activationToken: string) => {
    try {
      const transaction = await oktaAuth.verifyRecoveryToken({ recoveryToken: activationToken });
      setTransaction(transaction);
      setLoading(false);
    } catch (e) {
      setVerifyTokenError(true);
    }
  };

  useEffect(() => {
    if (activationToken) {
      OktaSignInWidgetConfig.recoveryToken = activationToken;
      getTransaction(activationToken);
    } else {
      setError(true);
    }
  }, []);

  if (error) {
    return <Error />;
  }

  if (verifyTokenError) {
    return <InvalidTokenError />;
  }

  if (sessionExpiredError) {
    return <SessionExpiredError />;
  }

  return loading || !transaction || !transaction.user ? (
    <Loading />
  ) : (
    <ContentLayout>
      <div className={css(commonStyles.outerContainer)}>
        <OktaSignInWidget
          config={OktaSignInWidgetConfig}
          onSuccess={onSuccess}
          onError={onError}
          onSessionExpired={onSessionExpired}
          page={Page.REGISTRATION}
          user={transaction?.user}
          passwordComplexityDisclaimer
          termsOfUseDisclaimer={CREATE_PASSWORD_DISCLAIMER_TEXT}
          welcomeHeader
        />
      </div>
    </ContentLayout>
  );
};

export default CreatePassword;
export { CREATE_PASSWORD_DISCLAIMER_TEXT };
