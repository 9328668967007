import React, { useEffect, useState } from 'react';
import ScreenLayout from '../screen/ScreenLayout';
import { NavigationPath, NavigationRoute, OktaAuthNavigationPath } from '../../../api/Navigation';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { OktaGroups } from '../../../api/Okta';
import Loading from '../screen/Loading';
import { CustomUserClaim } from '@okta/okta-auth-js';

export type RouteWrapperProps = NavigationRoute;

const AuthenticatedRoute: React.FC<RouteWrapperProps> = (props) => {
  const { push: navigate } = useHistory();
  const { authState } = useOktaAuth();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // navigate to login, if user is unauthenticated
    const accessToken = authState?.accessToken;
    if (!accessToken) {
      navigate(OktaAuthNavigationPath.LOGIN);
      return;
    }

    // navigate to the results screen, if user is already verified
    const groups = ([] as CustomUserClaim[]).concat(accessToken.claims.groups);
    if (groups && groups.length && groups.includes(OktaGroups.PATIENTS_VERIFIED)) {
      navigate(NavigationPath.ORDERS);
      return;
    }
    setLoading(false);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <ScreenLayout header={props.header}>
      <props.component />
    </ScreenLayout>
  );
};

const AuthenticatedRouteWrapper: React.FC<RouteWrapperProps> = (props) => {
  return (
    <SecureRoute
      path={props.path}
      render={() => {
        return <AuthenticatedRoute {...props} />;
      }}
    />
  );
};

export default AuthenticatedRouteWrapper;
