import React, { useState } from 'react';
import styles from '../../forms/shared/styles.module.css';
import checklistStyles from '../checklistStyles.module.css';
import LogoBanner from '../../forms/shared/LogoBanner';
import { Banner } from '@tcl-boron-prefabs/banner';
import QuestionSection from './QuestionSection';
import { RESUBMIT_INFO_TEXT } from '../FormChecklistConfig';
import ChecklistTable from './ChecklistFormsTable';
import ThankYouSection from './ThankYouSection';
import { NavigatorFormState } from './NavigatorChecklist';
import { Alert } from '@tcl-boron-prefabs/alert';
import { Information, Success } from '@tcl-boron-icons/icons';
import NoFormsModal from './NoFormsModal';
import { ConsentAppForm } from '@tempus/patient-forms-service-shared';
import { ValidatedValues } from './Verification';

type ChecklistProps = {
  navigatorState: NavigatorFormState[];
  validatedValues: ValidatedValues;
  showSuccessBanner: boolean;
};

const areAllRequiredFormsComplete = (incompleteForms: ConsentAppForm[], completeForms: ConsentAppForm[]) => {
  if (!incompleteForms.length && !completeForms.length) {
    return false;
  }

  for (const form of incompleteForms) {
    if (form.required) {
      return false;
    }
  }
  return true;
};

const getMainComponent = (incompleteForms: ConsentAppForm[], completeForms: ConsentAppForm[]) => {
  if (!incompleteForms.length && !completeForms.length) {
    return <NoFormsModal />;
  } else {
    return (
      <>
        <div className={checklistStyles.infoBanner}>
          <Banner ariaLabel={RESUBMIT_INFO_TEXT} status={'default'} message={RESUBMIT_INFO_TEXT} Icon={Information} />
        </div>
        {incompleteForms.length > 0 && (
          <ChecklistTable forms={incompleteForms} complete={false} tableTitle={`Your Forms`} />
        )}
        {completeForms.length > 0 && (
          <ChecklistTable forms={completeForms} complete={true} tableTitle={`Completed Documents`} />
        )}
      </>
    );
  }
};

const getFormsByStatus = (complete: boolean, navigatorState: NavigatorFormState[]) => {
  const formList: ConsentAppForm[] = [];
  for (const formState of navigatorState) {
    if (formState.complete === complete) {
      formList.push(formState.formModel);
    }
  }

  return formList;
};

const Checklist: React.FunctionComponent<ChecklistProps> = (props) => {
  const [incompleteForms] = useState<ConsentAppForm[]>(getFormsByStatus(false, props.navigatorState));
  const [completeForms] = useState<ConsentAppForm[]>(getFormsByStatus(true, props.navigatorState));
  const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(props.showSuccessBanner);

  return (
    <div>
      <LogoBanner />
      <div className={styles.content}>
        <div className={checklistStyles.checklistAlert}>
          {showSuccessBanner && (
            <Alert status={'success'} isDismissable onDismiss={() => setShowSuccessBanner(false)}>
              {{
                icon: <Success />,
                content: 'Form submitted successfully.',
              }}
            </Alert>
          )}
        </div>
        {areAllRequiredFormsComplete(incompleteForms, completeForms) && <ThankYouSection />}
        <p className={styles.title}>Checklist</p>
        {getMainComponent(incompleteForms, completeForms)}
        <QuestionSection />
      </div>
    </div>
  );
};

export default Checklist;
