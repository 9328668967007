import { useState, useEffect } from 'react';
import { getWindowDimensions } from '../utils';

const DEFAULT_HEIGHT = 768;
const DEFAULT_WIDTH = 1024;
const MOBILE_WIDTH = 700;
const WEB_WIDTH = 1000;

const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

export { DEFAULT_HEIGHT, DEFAULT_WIDTH, MOBILE_WIDTH, WEB_WIDTH, useWindowDimensions };
