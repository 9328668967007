export { default } from './TermsOfUse';

export const PRO_TERMS_OF_USE_URL = `https://www.tempus.com/terms-of-use/`;
export const PRO_PRIVACY_POLICY_URL = `https://www.tempus.com/privacypolicy/`;
export const PRO_NOTICE_OF_PRIVACY_PRACTICES_URL = `https://www.tempus.com/notice-of-privacy-practices/`;
export const PRO_TERMS_OF_USE_KEY = 'PRO_TERMS_OF_USE';

export enum TermsOfUseStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Unacknowledged = 'UNACKNOWLEDGED',
}

export type TermsOfUse = {
  firstSeenDate: number;
  status: TermsOfUseStatus;
};
