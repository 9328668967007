import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { identifyAnalytics, updateAnalytics } from '../../../api/Pendo';
import { useOrders } from '../../../api/Orders';
import { CustomUserClaim } from '@okta/okta-auth-js';

const PendoIdentifyAnalytics: FunctionComponent = (props) => {
  const { authState } = useOktaAuth();

  useEffect(() => {
    const accessToken = authState?.accessToken;
    if (accessToken) {
      const uid = ([] as CustomUserClaim[]).concat(accessToken.claims.uid)[0];
      identifyAnalytics({
        email: accessToken.claims.sub,
        userId: uid as string,
      });
    }
  }, [authState?.accessToken]);

  return <Fragment>{props.children}</Fragment>;
};

const PendoUpdateAnalytics: FunctionComponent = (props) => {
  const orders = useOrders();

  useEffect(() => {
    if (orders?.length) {
      // get zip and orderingInstitution from the earliest(first) order
      const { zip, orderingInstitution } = orders[orders.length - 1];
      updateAnalytics({ zip: zip ?? '', institution: orderingInstitution });
    }
  }, [orders]);

  return <Fragment>{props.children}</Fragment>;
};

export { PendoIdentifyAnalytics, PendoUpdateAnalytics };
