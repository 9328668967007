import React from 'react';
import { TempusLogo } from '@tcl-argon-icons/icons';
import { StyleSheet, css } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';

const TEMPUS_HEADER_LOGO = 'tempus-header-logo';

const DisclaimerNoticeHeader: React.FC = () => {
  return (
    <div className={css(HeaderStyles.container)}>
      <TempusLogo data-testid={TEMPUS_HEADER_LOGO} className={css(HeaderStyles.logo)} />
    </div>
  );
};

const HeaderStyles = StyleSheet.create({
  container: {
    minHeight: '10.71em',
    boxSizing: 'border-box',
    backgroundColor: grayPalette.gray10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '2.5em',
  },
  logo: {
    width: '9.57em',
    height: '1.43em',
    overflow: 'inherit',
  },
});

export { DisclaimerNoticeHeader as default, TEMPUS_HEADER_LOGO };
