import React, { ComponentType, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';

type AccountLayoutProps = {
  content: ComponentType;
  footer: ComponentType;
  userContent: ComponentType;
};

const AccountLayoutBrowser: FunctionComponent<AccountLayoutProps> = (props) => {
  return (
    <div className={css(browserStyles.screenContainer)}>
      <div className={css(browserStyles.contentContainer)}>
        <div className={css(browserStyles.content)}>
          <props.content />
        </div>
        <div className={css(browserStyles.contentPadding)} />
      </div>
      <div className={css(browserStyles.accountManagementContainer)}>
        <div className={css(browserStyles.userContentContainer)}>
          <props.userContent />
        </div>
        <div className={css(browserStyles.footerContainer)}>
          <props.footer />
        </div>
      </div>
    </div>
  );
};

const AccountLayoutMobile: FunctionComponent<AccountLayoutProps> = (props) => {
  return (
    <div className={css(mobileStyles.screenContainer)}>
      <props.content />
      <props.userContent />
      <props.footer />
    </div>
  );
};

const AccountLayout: FunctionComponent<AccountLayoutProps> = (props) => {
  const windowDimensions = useWindowDimensions();

  const isMobile = isMobileDevice(windowDimensions);

  return isMobile ? <AccountLayoutMobile {...props} /> : <AccountLayoutBrowser {...props} />;
};

export default AccountLayout;

const browserStyles = StyleSheet.create({
  accountManagementContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flex: 2,
  },
  contentContainer: {
    backgroundColor: grayPalette.gray5,
    display: 'flex',
    flex: 2,
  },
  contentPadding: {
    display: 'flex',
    flex: 1,
  },
  footerContainer: {
    display: 'flex',
    flex: 1,
  },
  screenContainer: {
    display: 'flex',
    flex: '1 0 auto',
  },
  userContentContainer: {
    display: 'flex',
    flex: 2,
  },
});

const mobileStyles = StyleSheet.create({
  screenContainer: {
    backgroundColor: grayPalette.gray5,
    display: 'flex',
    flexDirection: 'column',
  },
});
