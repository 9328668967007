import React from 'react';
import styles from './styles.module.css';
import { Information, Success } from '@tcl-boron-icons/icons';
import { Alert } from '@tcl-boron-prefabs/alert';

export const SUBMIT_SUCCESS_MESSAGE = 'Your form has been successfully submitted.';
export const SUBMIT_SUCCESS_TITLE = 'Success';

export enum AlertStatus {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

type AlertProps = {
  setShowAlert: (value: boolean) => void;
  message: string;
  title: string;
  status: AlertStatus;
};

const FormAlert: React.FunctionComponent<AlertProps> = (props) => {
  const isSuccessful = props.status === AlertStatus.SUCCESS;

  return (
    <div className={styles.formAlert}>
      <Alert
        status={isSuccessful ? 'success' : 'warning'}
        isDismissable
        onDismiss={() => props.setShowAlert(false)}
        classes={{ root: styles.successAlertRoot }}
      >
        {{
          icon: isSuccessful ? <Success /> : <Information />,
          content: <div className={styles.alertContent}> {props.message} </div>,
          title: props.title,
        }}
      </Alert>
    </div>
  );
};

export default FormAlert;
