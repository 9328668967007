import React, { Fragment, FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite';
import Card from '@tcl-argon-prefabs/card';
import { styles as textStyles } from '../../../constants/styles';
import Resource from './Resource';
import HelpSection from './HelpSection';
import ZenDesk from './ZenDesk';
import Feedback from './Feedback';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';
import { useContent } from '../../../api/Content';

const HEADER = 'Account';
const NEED_HELP = 'need help?';
const NEED_HELP_SECTION_HEADER = 'Questions about your results?';
const NEED_HELP_SECTION_TEXT = 'Please reach out to your healthcare provider.';
const RESOURCES = 'resources';

const Resources: FunctionComponent = () => {
  const windowDimensions = useWindowDimensions();
  const content = useContent()!;

  const isMobile = isMobileDevice(windowDimensions);

  return (
    <Fragment>
      <div className={css(isMobile ? styles.resourceContainerMobile : styles.resourceContainer)}>
        {content.linkedResources.map((resource, i) => {
          return (
            <div key={i} className={css(isMobile ? styles.resourcePaddingMobile : i === 0 && styles.resourcePadding)}>
              <Resource badge={resource.badge} href={resource.href} text={resource.text} />
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

const AccountContent: FunctionComponent = () => {
  return (
    <div className={css(styles.container)}>
      <p className={css(styles.header)}>{HEADER}</p>
      <div className={css(styles.helpContainer)}>
        <Card>
          <div className={css(styles.helpContent)}>
            <div className={css(styles.helpSection)}>
              <p className={css(styles.sectionHeader, textStyles.sectionHeaderBlack)}>{NEED_HELP}</p>
            </div>
            <div className={css(styles.helpSection)}>
              <HelpSection
                header={NEED_HELP_SECTION_HEADER}
                text={() => {
                  return <p className={css(styles.text, textStyles.bodyText)}>{NEED_HELP_SECTION_TEXT}</p>;
                }}
              />
            </div>
            <div className={css(styles.helpSection)}>
              <ZenDesk />
            </div>
            <div className={css(styles.helpSection)}>
              <Feedback />
            </div>
          </div>
        </Card>
      </div>
      <p className={css(styles.sectionHeader, styles.resourceSectionHeader, textStyles.sectionHeaderBlack)}>
        {RESOURCES}
      </p>
      <Resources />
    </div>
  );
};

export default AccountContent;

const styles = StyleSheet.create({
  container: {
    margin: '2.14em',
  },
  header: {
    fontSize: '36px',
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: '43px',
  },
  helpContainer: {
    display: 'flex',
    flex: 1,
  },
  helpContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '-0.71em',
  },
  helpSection: {
    marginBottom: '1.42em',
  },
  resourceContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.07em',
  },
  resourceContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
  },
  resourcePadding: {
    marginRight: '1.42em',
  },
  resourcePaddingMobile: {
    marginTop: '1.07em',
  },
  resourceSectionHeader: {
    marginTop: '2.86em',
  },
  sectionHeader: {
    margin: 0,
    textTransform: 'uppercase',
  },
  text: {
    margin: 0,
  },
});
