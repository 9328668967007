import React, { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';
import { Breakpoints } from '../../../constants/styles';

type ScreenLayoutProps = {
  header: JSX.Element | null;
  report: JSX.Element | null;
};

const ResultScreenLayout: FunctionComponent<ScreenLayoutProps> = (props) => {
  const windowDemension = useWindowDimensions();

  const layout = isMobileDevice(windowDemension) ? styles.mobileLayout : styles.browserLayout;

  return (
    <div className={css(styles.container, layout)}>
      <div className={css(styles.header)}>{props.header}</div>
      <div className={css(styles.report)}>{props.report}</div>
    </div>
  );
};

export default ResultScreenLayout;

const styles = StyleSheet.create({
  browserLayout: {
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  header: {
    backgroundColor: 'white',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      display: 'block',
    },
  },
  mobileLayout: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  report: {
    backgroundColor: grayPalette.gray5,
    display: 'flex',
    flex: '2',
    flexDirection: 'column',
  },
});
