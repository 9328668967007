import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Success as SuccessCircle } from '@tcl-argon-icons/icons';
import { Button } from '@tcl-argon-prefabs/button';
import { primaryPalette } from '@tcl-argon-colors/colors';
import { InputTheme } from '@tcl-argon-models/models';
import { useHistory } from 'react-router-dom';
import { styles } from '../../../constants/styles';
import { useWindowDimensions } from '../../../api/WindowDimensions';
import { isMobileDevice } from '../../../utils';
import AppStoreButton from '../../../../images/appStoreButton.svg';
import GooglePlayButton from '../../../../images/googlePlayButton.png';
import {
  appleStoreButtonAltText,
  appleStoreLink,
  googlePlayButtonAltText,
  googlePlayLink,
} from '../../../constants/appStoreLinks';
import ContentLayout from './ContentLayout';
import { OktaAuthNavigationPath } from '../../../api/Navigation';

type SuccessProps = {
  title?: string;
};

const SUCCESS_HEADER_TEXT = 'SUCCESS';
const SUCCESS_BODY_TEXT = [
  'You have created your account.',
  'Please return to the Sign In screen and sign in with the credentials you just created. You will then be able to view your test results.',
  'Or, you may also download the Tempus COVID-19 mobile app and login with the credentials you just created. You will then be able to view your test results in the mobile app.',
];
const RETURN_TO_LOGIN = 'Return to login';

const DefaultContent = () => {
  const windowDimensions = useWindowDimensions();
  const { push: navigate } = useHistory();

  return (
    <React.Fragment>
      <div className={css(SuccessStyles.successTextContainer)}>
        {SUCCESS_BODY_TEXT.map((text, index) => (
          <p className={css(styles.bodyText)} key={index}>
            {text}
          </p>
        ))}
      </div>
      <Button
        className={css(SuccessStyles.button)}
        onClick={() => navigate(OktaAuthNavigationPath.LOGIN)}
        theme={InputTheme.Dark}
      >
        <p className={css(SuccessStyles.buttonText, styles.bodyHeader)}>{RETURN_TO_LOGIN}</p>
      </Button>
      {isMobileDevice(windowDimensions) ? (
        <div className={css(SuccessStyles.buttonContainer)}>
          <a href={appleStoreLink} rel="noreferrer" target="_blank">
            <img alt={appleStoreButtonAltText} className={css(SuccessStyles.appleStoreButton)} src={AppStoreButton} />
          </a>
          <a href={googlePlayLink} rel="noreferrer" target="_blank">
            <img alt={googlePlayButtonAltText} className={css(SuccessStyles.googlePlayButton)} src={GooglePlayButton} />
          </a>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const Success: React.FC<SuccessProps> = (props) => {
  return (
    <ContentLayout>
      <div className={css(SuccessStyles.container, SuccessStyles.flexContainer, SuccessStyles.flexDirectionColumn)}>
        <div className={css(SuccessStyles.wrapper)}>
          <div className={css(SuccessStyles.flexContainer, SuccessStyles.textContainer)}>
            <SuccessCircle color={primaryPalette.successPrimary} height={15} width={15} />
            <p className={css(styles.sectionHeaderBlack, SuccessStyles.headerText)}>
              {props.title ?? SUCCESS_HEADER_TEXT}
            </p>
          </div>
          {props.children ?? <DefaultContent />}
        </div>
      </div>
    </ContentLayout>
  );
};

const SuccessStyles = StyleSheet.create({
  button: {
    backgroundColor: 'black',
    width: '200px',
    marginTop: '3.57em',
  },
  buttonText: {
    margin: 0,
  },
  appleStoreButton: {
    height: '2.14em',
    width: '6.43em',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: '14px',
    width: '100%',
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '3.57rem 0 0 0.71rem',
  },
  flexContainer: { display: 'flex' },
  flexDirectionColumn: { flexDirection: 'column', alignItems: 'center' },
  googlePlayButton: {
    height: '3.14em',
    width: '7.97em',
    marginLeft: '1.07rem',
  },
  headerText: { display: 'inline-flex', margin: '0.14rem 0 0 0.71rem ' },
  successTextContainer: {
    alignItems: 'center',
    margin: '0 2.14em',
    justifyContent: 'center',
  },
  textContainer: { alignItems: 'center', marginBottom: '2.14rem', justifyContent: 'center' },
  wrapper: { textAlign: 'center', maxWidth: '21.43rem', marginTop: '7.14rem' },
});

export default Success;

export { SUCCESS_HEADER_TEXT };
