import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Card } from '@tcl-boron-prefabs/card';
import { styles, Breakpoints } from '../../constants/styles';
import AppStoreButtons from '../../app/components/commons/AppStoreButtons';
import OrdersScreenImage from '../../../images/ordersScreen.png';
import ResultsScreenImage from '../../../images/resultsScreen.png';

const DownloadAppComponent = () => {
  const title = 'Download the Tempus COVID-19 app';
  const subtitle = 'Download our app for access to features on the go, such as viewing and sharing your results.';

  return (
    <Card className={css(DownloadAppStyles.downloadAppCard)}>
      <div className={css(DownloadAppStyles.container)}>
        <div className={css(DownloadAppStyles.buttonsContainer)}>
          <p className={css(styles.bodyHeader)}>{title}</p>
          <p className={css(styles.supportingBodyText, DownloadAppStyles.subtitle)}>{subtitle}</p>
          <AppStoreButtons />
        </div>
        <div className={css(DownloadAppStyles.screenImgsContainer)}>
          <img className={css(DownloadAppStyles.resultsScreenImg)} src={ResultsScreenImage} />
          <img className={css(DownloadAppStyles.orderScreenImg)} src={OrdersScreenImage} />
        </div>
      </div>
    </Card>
  );
};

export default DownloadAppComponent;

const DownloadAppStyles = StyleSheet.create({
  downloadAppCard: {
    maxWidth: '700px',
    height: 'fit-content',
    boxSizing: 'border-box',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '1.875em',
    marginBottom: '2.81em',
  },
  subtitle: {
    marginBottom: '1.66em',
    lineHeight: '1.33em',
  },
  buttonsContainer: {
    maxWidth: '280px',
  },
  resultsScreenImg: {
    width: '8.68em',
    height: '9.05em',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      width: '5.875em',
      height: '6.125em',
    },
  },
  orderScreenImg: {
    width: '8.375em',
    height: '10em',
    marginLeft: '20px',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      width: '5.68em',
      height: '6.812em',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      flexWrap: 'wrap',
    },
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      maxWidth: '280px',
    },
  },
  screenImgsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingRight: '2.5em',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      marginTop: '1.68em',
    },
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      paddingRight: '0px',
    },
  },
});
