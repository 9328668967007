import moment from 'moment';
import { ICResult, ResultType } from './ResultType';

interface ResultBase {
  result: ResultType;

  getCollectionDate: () => string;
}

class ICResultWrapper implements ResultBase {
  result: ICResult;

  constructor(result: ICResult) {
    this.result = result;
  }

  getCollectionDate(): string {
    return moment(this.result.resultDataProduct.dateCollected).format('MMM DD, YYYY');
  }
}

export { ICResultWrapper };
