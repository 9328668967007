import React from 'react';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import styles from './ErrorPage.module.css';
import { joinClasses } from '../../utils';
import ErrorIcon from './ErrorIcon';

const LinkExpiredPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <ErrorIcon />
      <h1 className={joinClasses(styles.header, typography.subSectionHeader)}>Link expired</h1>
      <p className={joinClasses(styles.text, typography.supportingBody)}>
        The link you followed has expired. Use the link in the most recent message you received from us.
      </p>
      <p className={joinClasses(styles.text, typography.supportingBody)}>
        If the issue persists, reach out to us at <a href="mailto:help@tempus.com">help@tempus.com</a> for help!
      </p>
    </div>
  );
};

export default LinkExpiredPage;
