import React, { useEffect, useState } from 'react';
import LogoBanner from '../../forms/shared/LogoBanner';
import styles from '../../forms/shared/styles.module.css';
import checklistStyles from '../checklistStyles.module.css';
import { Alert } from '@tcl-boron-prefabs/alert';
import { Warning } from '@tcl-boron-icons/icons';
import QuestionSection from './QuestionSection';
import { DATE_FILTER_MIN, isValidDateOfBirth } from '../../forms/shared/utils';
import moment from 'moment';
import FormTable, { FormTableField } from '../../forms/shared/FormTable';
import Form from '../../forms/shared/Form';
import { Button } from '@tcl-boron-prefabs/button';
import classNames from 'classnames';
import fetchHelper, { setIdentityTokenV3AndGetLockoutStatus } from '../../../utils';
import LockedOutSection from './LockedOutSection';
import Loader from '@tcl-boron-prefabs/loader';
import { FormTableFieldType, GetFormList, NAME_CHARACTER_LIMIT } from '@tempus/patient-forms-service-shared';
import { AuthenticationType } from '../../../api/AuthenticationType';
import {
  getCharacterLimitWarning,
  INVALID_DATE_OF_BIRTH_WARNING,
  REQUIRED_FIELD_WARNING,
} from '../../forms/shared/validation-utils';
import logger from '../../../../utils/logger';

type VerificationProps = {
  id: string;
  setCustomToDoListState: any;
};

export const VALIDATED_PATIENT_FIRST_NAME_PROPERTY = 'patientFirstName';
export const VALIDATED_PATIENT_LAST_NAME_PROPERTY = 'patientLastName';
export const VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY = 'patientDateOfBirth';

export type ValidatedValues = {
  [VALIDATED_PATIENT_FIRST_NAME_PROPERTY]: string;
  [VALIDATED_PATIENT_LAST_NAME_PROPERTY]: string;
  [VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY]: string;
};

const verificationFields: FormTableField[] = [
  {
    label: 'Patient first name',
    name: 'firstName',
    props: {
      placeholder: 'Enter',
    },
    type: FormTableFieldType.TEXT_INPUT,
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, NAME_CHARACTER_LIMIT);
      }
    },
  },
  {
    label: 'Patient last name',
    name: 'lastName',
    props: {
      placeholder: 'Enter',
    },
    type: FormTableFieldType.TEXT_INPUT,
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, NAME_CHARACTER_LIMIT);
      }
    },
  },
  {
    label: 'Patient date of birth',
    name: 'dateOfBirth',
    props: {
      placeholder: 'MM/DD/YYYY',
      minDate: DATE_FILTER_MIN,
      maxDate: moment().toDate(),
    },
    type: FormTableFieldType.DATE_PICKER,
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidDateOfBirth(value)) {
        return INVALID_DATE_OF_BIRTH_WARNING;
      }
    },
  },
];

const getFormListV3 = async (orderId: string): Promise<GetFormList> => {
  return fetchHelper('/v3/consent-app/form-list', {
    authenticationType: AuthenticationType.IDENTITY,
    method: 'POST',
    body: {
      orderId,
    },
  });
};

const getLockoutStatusV3 = async (orderId: string): Promise<boolean> => {
  return fetchHelper(`/v3/consent-app/lockout-status/${orderId}`, {
    authenticationType: AuthenticationType.NONE,
    method: 'GET',
  });
};

const Verification: React.FunctionComponent<VerificationProps> = (props) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const [lockedOut, setLockedOut] = useState<boolean>(false);

  useEffect(() => {
    const onVerificationLoad = async () => {
      const lockedOut = await getLockoutStatusV3(props.id);
      setLockedOut(Boolean(JSON.parse(String(lockedOut ?? true))));
      setIsInitialized(true);
    };

    onVerificationLoad();
  }, []);

  const onVerificationSubmit = React.useCallback(async (values: any) => {
    try {
      setHasFailed(false);
      setIsLoading(true);

      const [month, day, year] = (values.dateOfBirth as string).split('/');
      const data = {
        dateOfBirth: {
          month,
          day,
          year,
        },
        firstName: values.firstName,
        lastName: values.lastName,
      };

      const { successful, lockedOut } = await setIdentityTokenV3AndGetLockoutStatus(data, props.id);

      if (successful) {
        const { patientFormsList } = await getFormListV3(props.id);
        const validatedValues: ValidatedValues = {
          patientFirstName: values.firstName,
          patientLastName: values.lastName,
          patientDateOfBirth: values.dateOfBirth,
        };
        props.setCustomToDoListState(patientFormsList, validatedValues);
      } else {
        setLockedOut(lockedOut);
        setHasFailed(!successful);
      }
      setIsLoading(false);
    } catch (e) {
      const errorMessage = `An error occurred while verifying identity, orderId: ${props.id}`;
      logger.error(e as Error, errorMessage);
      throw new Error(errorMessage);
    }
  }, []);

  return (
    <div>
      <div className={styles.screen}>
        <LogoBanner />
        <div className={styles.content}>
          <p className={classNames(styles.title, checklistStyles.title)}>Identity verification</p>
          {!isInitialized && (
            <div className={checklistStyles.centerBlock}>
              <Loader />
            </div>
          )}
          {isInitialized && lockedOut && <LockedOutSection />}
          {isInitialized && !lockedOut && (
            <>
              {hasFailed && (
                <Alert
                  status={'warning'}
                  onDismiss={() => setHasFailed(false)}
                  classes={{ root: checklistStyles.rootVerificationAlert }}
                >
                  {{
                    icon: <Warning />,
                    content: (
                      <div
                        className={styles.alertContent}
                      >{`The information doesn't match our records. Please confirm it's correct and try again.`}</div>
                    ),
                  }}
                </Alert>
              )}
              <Form onSubmit={onVerificationSubmit}>
                <FormTable fields={verificationFields} />
                <div>
                  <p className={checklistStyles.attestation}>
                    By clicking the Submit button, I attest that I am the person whose information is being submitted
                    and that all information is complete and accurate.
                  </p>
                </div>
                <div className={checklistStyles.submitButton}>
                  <Button ariaLabel="Verification submit" type="submit" loading={isLoading}>
                    Submit
                  </Button>
                </div>
              </Form>
              <QuestionSection />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Verification;
