import React from 'react';
import RouteWrapper from '../navigation/RouteWrapper';
import { LoginCallback, Security } from '@okta/okta-react';
import { Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { AuthenticationState, NavigationPath, OktaAuthNavigationPath } from '../../../api/Navigation';
import { oktaAuth } from '../../../api/Okta';
import { PendoIdentifyAnalytics } from '../navigation/PendoAnalytics';
import LoginWrapper from '../../../screens/login/LoginWrapper';
import { HeaderType } from '../header/headerType';

const OktaAuth: React.FC = (props) => {
  const history = useHistory();

  const authHandler = () => {
    history.push(OktaAuthNavigationPath.LOGIN);
  };

  const navigateToVerificationFlow = () => {
    history.push(NavigationPath.VERIFY);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={navigateToVerificationFlow} onAuthRequired={authHandler}>
      <PendoIdentifyAnalytics>
        <Switch>
          {props.children}
          <Route path={OktaAuthNavigationPath.CALLBACK} component={LoginCallback} />
          <RouteWrapper
            header={HeaderType.UNAUTHENTICATED_HEADER}
            path={OktaAuthNavigationPath.LOGIN}
            component={LoginWrapper}
            authState={AuthenticationState.UNAUTHENTICATED}
          />
        </Switch>
      </PendoIdentifyAnalytics>
    </Security>
  );
};

const OktaAuthRouter: React.FC = (props) => {
  return (
    <Router>
      <OktaAuth>{props.children}</OktaAuth>
    </Router>
  );
};

export default OktaAuthRouter;
export { OktaAuth };
