import React from 'react';
import { Alert } from '@tcl-boron-prefabs/alert';
import { AlertManager } from '@tcl-argon-prefabs/alert-manager';
import { Success } from '@tcl-boron-icons/icons';
import { AppContext, AppContextType } from '../../App/AppContext';
import styles from './SuccessAlert.module.css';

const SuccessAlert: React.FC = () => {
  const { isAlertVisible, dismissSuccessAlert, assessments, currentIndex } = React.useContext(
    AppContext,
  ) as AppContextType;
  const previousAssessmentName = assessments[currentIndex - 1]?.name ?? '';
  return previousAssessmentName.length > 0 && isAlertVisible ? (
    <AlertManager classes={{ root: styles.alertContainer }} position="center">
      <Alert
        classes={{ root: styles.alert, dismissButton: styles.dismissButton }}
        key="success-alert"
        status="success"
        isDismissable
        onDismiss={dismissSuccessAlert}
        autoDismissMs={2000}
      >
        {{
          icon: <Success classes={{ root: styles.icon }} />,
          content: `${previousAssessmentName} Submitted!`,
        }}
      </Alert>
    </AlertManager>
  ) : null;
};

export default SuccessAlert;
