enum StaticPasswordComplexityRuleName {
  LOWERCASE = 'LOWERCASE',
  UPPERCASE = 'UPPERCASE',
  NUMBER = 'NUMBER',
  SYMBOL = 'SYMBOL',
}

enum DynamicPasswordComplexityRuleName {
  LENGTH = 'LENGTH',
}

type PasswordComplexityDisclaimerProps = {
  id: string;
  passwordComplexity: string;
};

type StaticPasswordComplexityRule = {
  query: RegExp;
  parsedValue: string;
};

type StaticPasswordComplexityRules = Record<StaticPasswordComplexityRuleName, StaticPasswordComplexityRule>;

type DynamicPasswordComplexityRule = {
  parse: (passwordComplexity: string) => string | null;
};

type DynamicPasswordComplexityRules = Record<DynamicPasswordComplexityRuleName, DynamicPasswordComplexityRule>;

const parseLengthComplexityRule = (passwordComplexity: string): string | null => {
  const passwordLengthComplexityRule = new RegExp('at least (.*) characters');

  const arr = passwordLengthComplexityRule.exec(passwordComplexity);
  if (arr && arr.length === 2) {
    const length = arr[1];
    return `${length} characters`;
  } else {
    return null;
  }
};

const PASSWORD_COMPLEXITY_DISCLAIMER_TEXT = 'Password Requirements:';
const PASSWORD_COMPLEXITY_DISCLAMIER_HEADER_ID = 'password-complexity-disclaimer-header';
const PASSWORD_COMPLEXITY_DISCLAMIER_RULES_ID = 'password-complexity-disclaimer-rules';

/*
 * IMPORTANT
 *      These complexity rules are hardcoded from the okta-signin-widget package v5.5.2.
 *      If okta-signin-widget is updated this must be manually tested in a browser.
 */
const dynamicPasswordComplexityRuleQueries: DynamicPasswordComplexityRules = {
  [DynamicPasswordComplexityRuleName.LENGTH]: { parse: parseLengthComplexityRule },
};

const staticPasswordComplexityRuleQueries: StaticPasswordComplexityRules = {
  [StaticPasswordComplexityRuleName.LOWERCASE]: {
    query: new RegExp('a lowercase letter'),
    parsedValue: '1 lower case',
  },
  [StaticPasswordComplexityRuleName.UPPERCASE]: {
    query: new RegExp('an uppercase letter'),
    parsedValue: '1 upper case',
  },
  [StaticPasswordComplexityRuleName.NUMBER]: { query: new RegExp('a number'), parsedValue: '1 number' },
  [StaticPasswordComplexityRuleName.SYMBOL]: { query: new RegExp('a symbol'), parsedValue: '1 symbol' },
};

const parsePasswordComplexityRules = (passwordComplexity: string): string[] => {
  const parsedPasswordComplexityRules = [] as string[];

  (Object.keys(dynamicPasswordComplexityRuleQueries) as DynamicPasswordComplexityRuleName[]).map(
    (key: DynamicPasswordComplexityRuleName) => {
      const result = dynamicPasswordComplexityRuleQueries[key].parse(passwordComplexity);
      if (result) {
        parsedPasswordComplexityRules.push(result);
      }
    },
  );

  (Object.keys(staticPasswordComplexityRuleQueries) as StaticPasswordComplexityRuleName[]).map(
    (key: StaticPasswordComplexityRuleName) => {
      const regexp = staticPasswordComplexityRuleQueries[key].query;
      const arr = regexp.exec(passwordComplexity);
      if (arr && arr.length === 1) {
        parsedPasswordComplexityRules.push(staticPasswordComplexityRuleQueries[key].parsedValue);
      }
    },
  );

  return parsedPasswordComplexityRules;
};

const PasswordComplexityDisclaimer = (props: PasswordComplexityDisclaimerProps): HTMLDivElement => {
  const parsedRules = parsePasswordComplexityRules(props.passwordComplexity);
  const passwordComplexityDisclaimer = document.createElement('div');
  passwordComplexityDisclaimer.id = props.id;

  const passwordComplexityDisclaimerHeader = document.createElement('p');
  passwordComplexityDisclaimerHeader.id = PASSWORD_COMPLEXITY_DISCLAMIER_HEADER_ID;
  const passwordComplexityDisclaimerHeaderText = document.createTextNode(PASSWORD_COMPLEXITY_DISCLAIMER_TEXT);
  passwordComplexityDisclaimerHeader.appendChild(passwordComplexityDisclaimerHeaderText);
  passwordComplexityDisclaimer.appendChild(passwordComplexityDisclaimerHeader);

  const passwordComplexityRules = document.createElement('p');
  passwordComplexityRules.id = PASSWORD_COMPLEXITY_DISCLAMIER_RULES_ID;
  const passwordComplexityText = document.createTextNode(parsedRules.join(', '));
  passwordComplexityRules.appendChild(passwordComplexityText);

  passwordComplexityDisclaimer.appendChild(passwordComplexityRules);

  return passwordComplexityDisclaimer;
};

export default PasswordComplexityDisclaimer;
