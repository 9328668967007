import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { TEMPUS_PRO_PATH } from '../..';
import { useQueryParameter } from '../../utils';
import { AppContext, AppContextType } from '../App/AppContext';

export default function VerifiedRoute({ children, ...routeProps }: RouteProps) {
  const { verified } = React.useContext(AppContext) as AppContextType;
  const history = useHistory();
  const id = useQueryParameter('id');

  if (verified) {
    return <Route {...routeProps}>{children}</Route>;
  } else {
    if (id.length > 0) {
      history.push(`${TEMPUS_PRO_PATH}/?id=${id}`);
    } else {
      history.push(`${TEMPUS_PRO_PATH}/`);
    }
    return <></>;
  }
}
