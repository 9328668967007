import React from 'react';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import styles from '../../forms/shared/styles.module.css';
import checklistStyles from '../checklistStyles.module.css';

const QuestionSection: React.FunctionComponent = () => {
  return (
    <div className={typography.body}>
      <div className={styles.bold}>Questions?</div>
      <p>
        Our Customer Success Team is available from 7:00am-7:00pm CT Mon-Fri and can be reached at
        <a className={checklistStyles.checklistLink} href="tel:800.739.4137">
          &nbsp; 800.739.4137
        </a>
        <span>.</span>
      </p>
      <p>
        If you have general questions, please contact
        <a className={checklistStyles.checklistLink} href="mailto:support@tempus.com">
          &nbsp; support@tempus.com
        </a>
        <span>.</span>
      </p>
      <p>
        If you have questions about our billing process, please contact
        <a className={checklistStyles.checklistLink} href="mailto:billing@tempus.com">
          &nbsp; billing@tempus.com
        </a>
        <span>.</span>
      </p>
    </div>
  );
};

export default QuestionSection;
