import React from 'react';

export const FullLockedIcon = () => {
    return (
        <svg width="172" height="157" viewBox="0 0 172 157" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M80.5927 9.25371C83.0104 5.11625 88.9896 5.11625 91.4073 9.25371L166.494 137.753C168.934 141.928 165.922 147.175 161.087 147.175H10.9132C6.07754 147.175 3.06623 141.928 5.50589 137.753L80.5927 9.25371Z" fill="#F7F8FA"/>
                <g filter="url(#filter0_d_759_2942)">
                    <circle cx="85.3299" cy="96.2902" r="30.531" fill="white"/>
                </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M94.9709 90.1601H93.503V87.2243C93.503 83.1739 90.2151 79.8846 86.1633 79.8846C82.113 79.8846 78.8251 83.1739 78.8251 87.2243V90.1601H77.3572C75.7431 90.1601 74.4199 91.4806 74.4199 93.0946V107.774C74.4199 109.388 75.7431 110.71 77.3572 110.71H94.9709C96.5849 110.71 97.9053 109.388 97.9053 107.774V93.0946C97.9053 91.4806 96.5849 90.1601 94.9709 90.1601ZM86.1633 103.37C84.5493 103.37 83.2288 102.05 83.2288 100.434C83.2288 98.8201 84.5493 97.4983 86.1633 97.4983C87.7787 97.4983 89.0991 98.8201 89.0991 100.434C89.0991 102.05 87.7787 103.37 86.1633 103.37ZM90.7132 90.1601H81.6135V87.2242C81.6135 84.7141 83.6532 82.6729 86.1633 82.6729C88.6748 82.6729 90.7132 84.7141 90.7132 87.2242V90.1601Z" fill="#231F20"/>
            <defs>
                <filter id="filter0_d_759_2942" x="40.7076" y="65.7592" width="89.245" height="90.8103" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="15.6569"/>
                    <feGaussianBlur stdDeviation="7.04562"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_759_2942"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_759_2942" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
};