import React from 'react';
import { useHistory } from 'react-router-dom';
import PhysicianInfoForm from './PhysicianInfoForm';
import { NavigationPath } from '../../api/Navigation';
import { getHipaaConsentForm, usePatientInfo } from '../../api/PatientInfo';
import Loading from '../../app/components/screen/Loading';

const PhysicianInfoFormWrapper = () => {
  const { push: navigate } = useHistory();
  const { refreshPatientInfo, patientInfo } = usePatientInfo();

  const next = () => {
    refreshPatientInfo();
    navigate(NavigationPath.ACCOUNT);
  };

  const form = getHipaaConsentForm(patientInfo!.forms)?.form;

  if (
    !form &&
    (!patientInfo?.consent?.consented ||
      patientInfo?.consent?.physicianContactInfoProvided ||
      patientInfo?.consent?.neverAskForPCPAgain)
  ) {
    navigate(NavigationPath.ACCOUNT);
    return <Loading />;
  }

  return <PhysicianInfoForm next={next} />;
};

export default PhysicianInfoFormWrapper;
