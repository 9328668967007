import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import Logo from './Logo';
import VerifiedUserTabs from './VerifiedUserTabs';
import { Breakpoints } from '../../../constants/styles';
import UnverifiedUserTabs from './UnverifiedUserTabs';
import { HeaderType } from './headerType';

const navbarHeight = '60px';

type HeaderProps = {
  headerType: HeaderType;
};

const HeaderTabs = {
  [HeaderType.AUTHENTICATED_HEADER]: <VerifiedUserTabs />,
  [HeaderType.UNAUTHENTICATED_HEADER]: <UnverifiedUserTabs />,
};

const Header = (props: HeaderProps): JSX.Element => {
  return (
    <div className={css(HeaderStyles.backgroundColor, HeaderStyles.container)}>
      <Logo style={{ image: HeaderStyles.image, text: HeaderStyles.text, textContainer: HeaderStyles.textContainer }} />
      {HeaderTabs[props.headerType]}
    </div>
  );
};

export default Header;
export { navbarHeight };

const HeaderStyles = StyleSheet.create({
  backgroundColor: {
    backgroundColor: grayPalette.gray10,
  },
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    minHeight: navbarHeight,
    padding: '0px 1.875em 0px 1.875em',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      padding: '0px 1.25em 0px 1.25em',
    },
  },
  flexContainer: {
    flex: 1,
  },
  image: {
    height: '0.9375em',
    width: '6.25em',
    overflow: 'inherit',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      height: '0.75em',
      width: '5em',
    },
  },
  text: {
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '2px',
    lineHeight: '19px',
    margin: '0px',
    [Breakpoints.MEDIUM_DEVICE_BREAKPOINT]: {
      fontSize: '12px',
      letterSpacing: '1.5px',
      lineHeight: '14px',
    },
  },
  textContainer: {
    marginLeft: '0.625em',
  },
});
