import Config from '../../../config';
import packageJson from '../../../../package.json';
import { oktaAuth } from '../Okta';
import { AuthenticationType } from '../AuthenticationType';

export enum API_FAILURE_RESPONSE {
  DOB_LOCKOUT = 'DOB_LOCKOUT',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  RESULTS_NOT_FOUND = 'RESULTS_NOT_FOUND',
  NO_ORDERS_FOUND_WITH_DOB = 'NO_ORDERS_FOUND_WITH_DOB',
  USER_NOT_AVALIABLE = 'USER_NOT_AVALIABLE',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type RequestOptions = {
  method?: string;
  body?: any;
  authenticationType?: AuthenticationType;
};

export class HttpError extends Error {
  status: number;
  statusCode: API_FAILURE_RESPONSE;

  constructor(res: Response) {
    super(res.statusText);
    this.status = res.status;
    this.statusCode = res.type as API_FAILURE_RESPONSE;
  }
}

const getAccessToken = async () => {
  const tokenManager = oktaAuth.tokenManager;
  const accessToken = (await tokenManager.get('accessToken')) as AccessToken;
  if (!accessToken?.value) {
    throw new Error('Unable to fetch access token');
  } else if (tokenManager.hasExpired(accessToken)) {
    throw new Error('Access token has expired');
  }

  return accessToken.value;
};

/**
 * You probably shouldn't be using this method, use fetchHelper from patient-portal/src/core/utils/index.ts/ instead
 */
export async function fetchHelper<T>(path: string, options: RequestOptions = {}): Promise<T> {
  const { method = 'GET', body, authenticationType = AuthenticationType.OKTA } = options;
  const userAgent = navigator.userAgent;
  const headers: { [key: string]: string } = {
    'Content-Type': 'application/json',
    'X-Tempus-App-Version': packageJson.version,
    'X-User-Agent': userAgent,
  };
  if (authenticationType === AuthenticationType.OKTA) {
    const accessToken = await getAccessToken();
    if (accessToken) {
      headers.Authorization = 'Bearer ' + accessToken;
    }
  }

  const res = await fetch(Config.baseUrl + path, {
    method,
    headers,
    body: body ? JSON.stringify(body) : undefined,
  }).catch((e) => {
    throw e;
  });

  if (!res.ok) {
    const errResponse = await res.json();
    throw new HttpError({
      statusText: errResponse.message,
      status: errResponse.status,
      type: errResponse.statusCode,
    } as Response);
  }
  if (res.headers.get('content-type')?.includes('application/json')) {
    return res.json() as any;
  }
  if (res.headers.get('content-type')?.includes('application/pdf')) {
    return res.blob() as any;
  } else {
    return res.text() as any;
  }
}
