import React, { FunctionComponent } from 'react';
import { Alert } from '@tcl-argon-prefabs/alert';
import { InputTheme } from '@tcl-argon-models/models';
import { AlertManager } from '@tcl-argon-prefabs/alert-manager';
import { useTimeout } from '../../../api/hooks';
import { css, StyleSheet } from 'aphrodite/no-important';
import { navbarHeight } from './Header';
import { Success } from '@tcl-argon-icons/icons';
import { Breakpoints } from '../../../constants/styles';

const ALERT_AUTO_DISMISS = 30000; // 30s auto dismiss

type DismissableAlertProps = {
  message: string;
  onDismiss: () => void;
  theme: InputTheme.Success;
};

const ICON = {
  [InputTheme.Success]: <Success />,
};

const DismissableAlert: FunctionComponent<DismissableAlertProps> = (props) => {
  useTimeout(props.onDismiss, ALERT_AUTO_DISMISS);

  return (
    <AlertManager className={css(styles.alertManager)} position="right">
      <Alert isDismissable onDismiss={props.onDismiss} theme={props.theme} classes={{ root: css(styles.alert) }}>
        {{
          icon: ICON[props.theme],
          content: <div className={css(styles.alertContent)}>{props.message}</div>,
        }}
      </Alert>
    </AlertManager>
  );
};

export default DismissableAlert;

const styles = StyleSheet.create({
  alertManager: {
    top: `calc(${navbarHeight} + 1.42em)`,
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      maxWidth: '90%',
    },
  },
  alert: {
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      maxWidth: 'inherit',
    },
  },
  alertContent: {
    lineHeight: '18px',
  },
});
