import React from 'react';
import { NoFormsIcon } from '../assets/NoFormsIcon';
import styles from '../../forms/shared/styles.module.css';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import classNames from 'classnames';

const NoFormsModal = () => {
  return (
    <div className={styles.lockedOutContainer}>
      <NoFormsIcon />
      <div className={classNames(typography.subSectionHeader, styles.lockedOutHeader)}>No Forms Available</div>
      <p>Please try again in a few minutes. If the issue persists, contact our Customer Success Team.</p>
    </div>
  );
};

export default NoFormsModal;
