import React, { FunctionComponent } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { grayPalette } from '@tcl-argon-colors/colors';
import Card from '@tcl-argon-prefabs/card';
import { fonts, styles as textStyles, text } from '../../../constants/styles';
import { ChevronRight } from '@tcl-argon-icons/icons';

type ResourceProps = {
  badge?: string;
  href: string;
  text: string;
};

const Resource: FunctionComponent<ResourceProps> = (props) => {
  return (
    <a className={css(styles.link)} href={props.href} target="_blank" rel="noreferrer">
      <div className={css(styles.resourceContainer)}>
        <Card>
          <div className={css(styles.container)}>
            <div className={css(styles.contentContainer)}>
              {props.badge ? (
                <div className={css(styles.badge)}>
                  <p className={css(fonts.ibmPlexSansBold, text.supportingBodyText)}>{props.badge}</p>
                </div>
              ) : undefined}
              <p className={css(textStyles.supportingBodyText)}>{props.text}</p>
            </div>
            <div className={css(styles.imageContainer)}>
              <ChevronRight />
            </div>
          </div>
        </Card>
      </div>
    </a>
  );
};

export default Resource;

const styles = StyleSheet.create({
  badge: {
    alignItems: 'center',
    backgroundColor: grayPalette.gray20,
    borderRadius: 25,
    display: 'flex',
    flexShrink: 0,
    height: '50px',
    justifyContent: 'center',
    marginRight: '1.43em',
    width: '50px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '-0.36em', // TODO: fix padding on release of next update to Card component
  },
  contentContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainer: {
    margin: '0 1.79em',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  resourceContainer: {
    display: 'flex',
    maxWidth: '21.42em',
  },
  textContainer: {
    margin: '0 2.14em 0 1.43em',
  },
});
