import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import {
  appleStoreLink,
  googlePlayLink,
  appleStoreButtonAltText,
  googlePlayButtonAltText,
} from '../../../constants/appStoreLinks';
import AppStoreButton from '../../../../images/appStoreButton.svg';
import GooglePlayButton from '../../../../images/googlePlayButton.png';
import { Breakpoints } from '../../../constants/styles';

const AppStoreButtons = () => {
  return (
    <div className={css(AppStoreButtonsStyles.buttonContainer)}>
      <a href={appleStoreLink} rel="noreferrer" target="_blank">
        <img
          alt={appleStoreButtonAltText}
          className={css(AppStoreButtonsStyles.appleStoreButton)}
          src={AppStoreButton}
        />
      </a>
      <a href={googlePlayLink} rel="noreferrer" target="_blank">
        <img
          alt={googlePlayButtonAltText}
          className={css(AppStoreButtonsStyles.googlePlayButton)}
          src={GooglePlayButton}
        />
      </a>
    </div>
  );
};

const AppStoreButtonsStyles = StyleSheet.create({
  // fixed button sizes in px to ensure consistency throughout app
  appleStoreButton: {
    width: '86px',
    height: '29px',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      width: '78px',
      height: '28px',
    },
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  googlePlayButton: {
    marginLeft: '1.07rem',
    width: '98px',
    height: '41px',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      width: '93px',
      height: '40px',
    },
  },
});

export default AppStoreButtons;
