import React, { useEffect, useState } from 'react';
import { css } from 'aphrodite/no-important';
import { useOktaAuth } from '@okta/okta-react';
import { useQuery } from '../../../api/hooks';
import { oktaWidgetDefaultConfiguration } from '../../../api/Okta';
import OktaSignInWidget, { INVALID_SESSION_ERROR, Page } from '../../../app/components/okta/OktaSignInWidget';
import { AuthnTransaction } from '@okta/okta-auth-js';
import Error from '../../../app/components/screen/Error';
import SessionExpiredError from '../../../app/components/screen/SessionExpiredError';
import InvalidTokenError from '../../../app/components/screen/InvalidTokenError';
import Loading from '../../../app/components/screen/Loading';
import ContentLayout from '../../../app/components/screen/ContentLayout';
import { commonStyles } from '../../../constants/commonStyles';

const OktaSignInWidgetConfig: OktaSignInConfig = {
  ...oktaWidgetDefaultConfiguration,
  i18n: {
    en: {
      'password.newPassword.placeholder': 'New password*',
      'password.newPassword.tooltip': ' ',
      'password.confirmPassword.placeholder': 'Confirm password*',
      'password.confirmPassword.tooltip': ' ',
      'password.reset.title.generic': 'Reset password',
    },
  },
};

const ResetPassword: React.FC = () => {
  const activationToken = useQuery('activationToken');
  const { oktaAuth } = useOktaAuth();
  const [error, setError] = useState(false);
  const [verifyTokenError, setVerifyTokenError] = useState<boolean>(false);
  const [sessionExpiredError, setSessionExpiredError] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<AuthnTransaction>();
  const [loading, setLoading] = useState(true);

  const onSuccess = (tokens?: Tokens) => {
    if (tokens?.accessToken?.accessToken) {
      oktaAuth.handleLoginRedirect(tokens).catch(() => {
        setError(true);
      });
    } else {
      setError(true);
    }
  };

  const onError = (error: OktaError) => {
    if (error?.xhr?.responseJSON?.errorCode === INVALID_SESSION_ERROR) {
      setSessionExpiredError(true);
    }
  };

  const onSessionExpired = () => {
    setSessionExpiredError(true);
  };

  const getTransaction = async (activationToken: string) => {
    try {
      const transaction = await oktaAuth.verifyRecoveryToken({ recoveryToken: activationToken });
      setTransaction(transaction);
      setLoading(false);
    } catch {
      setVerifyTokenError(true);
    }
  };

  useEffect(() => {
    if (activationToken) {
      OktaSignInWidgetConfig.recoveryToken = activationToken;
      getTransaction(activationToken);
    } else {
      setError(true);
    }
  }, []);

  if (error) {
    return <Error />;
  }

  if (verifyTokenError) {
    return <InvalidTokenError />;
  }

  if (sessionExpiredError) {
    return <SessionExpiredError />;
  }

  return loading || !transaction || !transaction.user ? (
    <Loading />
  ) : (
    <ContentLayout>
      <div className={css(commonStyles.outerContainer)}>
        <OktaSignInWidget
          user={transaction?.user}
          config={OktaSignInWidgetConfig}
          onSuccess={onSuccess}
          onError={onError}
          onSessionExpired={onSessionExpired}
          page={Page.PASSWORD_RESET}
          passwordComplexityDisclaimer
        />
      </div>
    </ContentLayout>
  );
};

export default ResetPassword;
