import React, { useCallback, useEffect, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { SingleDatePicker } from '@tcl-argon-prefabs/single-date-picker';
import { Button } from '@tcl-argon-prefabs/button';
import { InputTheme } from '@tcl-argon-models/models';
import ContentLayout from '../../app/components/screen/ContentLayout';
import { Breakpoints, styles } from '../../constants/styles';
import fetchHelper from '../../utils';
import { useAsync } from 'react-async';
import { useOktaAuth } from '@okta/okta-react';
import moment from 'moment';
import ErrorBanner from '../../app/components/screen/ErrorBanner';
import { grayPalette } from '@tcl-argon-colors/colors';
import { logOutAndClearStorage, OktaSignOutState } from '../../api/Okta';
import Loading from '../../app/components/screen/Loading';
import { useHistory } from 'react-router-dom';
import { OktaAuthNavigationPath } from '../../api/Navigation';

type IdentityVerificationContent = {
  button: string;
  disclaimer: string;
  label: string;
  title: string;
};

type IdentityVerificationProps = {
  onSuccess: () => void;
};

const DOB_LOCKOUT_ERROR = 'DOB_LOCKOUT';
const ERROR_BANNER_TEXT = 'Failed to verify identity. Too many incorrect attempts will lock your account.';
const ERROR_BANNER_HELP_TEXT =
  'Having trouble? Your date of birth may have been entered incorrectly when your order was placed. In this case, please contact your ordering healthcare provider for your results.';
const IDENTITY_VERIFICATION_CONTENT: IdentityVerificationContent = {
  button: 'Submit',
  disclaimer: 'Please enter your date of birth below to verify your identity.',
  label: 'Date of birth*',
  title: 'Verify your identity',
};

const IdentityVerification: React.FunctionComponent<IdentityVerificationProps> = (props) => {
  const { push: navigate } = useHistory();
  const okta = useOktaAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState({ dateString: '' });
  const { run, error, isLoading, isFulfilled } = useAsync({
    deferFn: useCallback(() => {
      return fetchHelper('/onboarding/verify-identity', {
        method: 'POST',
        authToken: okta.authState?.accessToken?.accessToken,
        body: {
          type: 'SECURITY_QUESTIONS',
          data: {
            dob: moment(date.dateString).format('YYYY-MM-DD'),
          },
        },
      });
    }, [okta, date]),
  });

  const isValidDate = () => {
    return !!date.dateString && moment(date.dateString, 'MM/DD/YYYY').format('MM/DD/YYYY') === date.dateString;
  };

  const onToggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const canSubmit = !isLoading && isValidDate();

  useEffect(() => {
    if (error && error.message === DOB_LOCKOUT_ERROR) {
      logOutAndClearStorage(OktaSignOutState.SUSPENDED).catch(() => {
        navigate(OktaAuthNavigationPath.LOGIN);
      });
    }

    if (isFulfilled) {
      props.onSuccess();
    }
  }, [error, isFulfilled]);

  if (error && error.message === DOB_LOCKOUT_ERROR) {
    return <Loading />;
  }

  if (isFulfilled) {
    return <Loading />;
  }

  return (
    <ContentLayout>
      <div className={css(IDENTITY_VERIFICATION_STYLES.content)}>
        <div className={css(IDENTITY_VERIFICATION_STYLES.contentPadding)}>
          <p className={css(IDENTITY_VERIFICATION_STYLES.title)}>{IDENTITY_VERIFICATION_CONTENT.title}</p>
          <p className={css(IDENTITY_VERIFICATION_STYLES.disclaimer)}>{IDENTITY_VERIFICATION_CONTENT.disclaimer}</p>
          <SingleDatePicker
            className={css(IDENTITY_VERIFICATION_STYLES.singleDatePicker)}
            classes={{
              calendarToggle: css(IDENTITY_VERIFICATION_STYLES.calendarToggle),
              inputWrapper: css(IDENTITY_VERIFICATION_STYLES.inputWrapper),
              forceFocus: css(IDENTITY_VERIFICATION_STYLES.forceFocus),
            }}
            isOpen={isOpen}
            label={IDENTITY_VERIFICATION_CONTENT.label}
            onChange={setDate}
            onToggleIsOpen={onToggleIsOpen}
            value={date}
          />
        </div>
      </div>
      {error && (
        <ErrorBanner
          title={
            <div className={css(IDENTITY_VERIFICATION_ERROR_BANNER_STYLES.wrapperText)}>
              <p className={css(styles.error, IDENTITY_VERIFICATION_ERROR_BANNER_STYLES.text)}>{ERROR_BANNER_TEXT}</p>
              <p
                className={css(
                  styles.error,
                  IDENTITY_VERIFICATION_ERROR_BANNER_STYLES.text,
                  IDENTITY_VERIFICATION_ERROR_BANNER_STYLES.italic,
                )}
              >
                {ERROR_BANNER_HELP_TEXT}
              </p>
            </div>
          }
        />
      )}
      <div className={css(IDENTITY_VERIFICATION_STYLES.content)}>
        <div className={css(IDENTITY_VERIFICATION_STYLES.contentPadding)}>
          <Button
            onClick={run}
            classes={{ root: css(IDENTITY_VERIFICATION_STYLES.button) }}
            theme={InputTheme.Dark}
            disabled={!canSubmit}
          >
            {IDENTITY_VERIFICATION_CONTENT.button}
          </Button>
        </div>
      </div>
    </ContentLayout>
  );
};

const IDENTITY_VERIFICATION_ERROR_BANNER_STYLES = StyleSheet.create({
  text: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  italic: {
    fontStyle: 'italic',
    fontWeight: 500,
  },
  wrapperText: {
    display: 'inline-block',
    textAlign: 'left',
    paddingLeft: '1.07em',
    marginTop: '-1.14em',
  },
});

const IDENTITY_VERIFICATION_STYLES = StyleSheet.create({
  button: {
    backgroundColor: grayPalette.grey80,
    marginTop: '5em',
    width: '100%',
  },
  calendarToggle: {
    backgroundColor: '#FFFFFF',
    borderColor: grayPalette.gray30,
    borderWidth: '0px 0.07em 0px 0px',
    color: '#000000',
    // clears default blue border styling on calender toggle
    ':active': {
      borderColor: grayPalette.gray30,
      borderWidth: '0px 0.07em 0px 0px',
      backgroundColor: grayPalette.gray100,
      boxShadow: 'none',
      color: '#FFFFFF',
    },
    ':focus': {
      borderColor: grayPalette.gray30,
      borderWidth: '0px 0.07em 0px 0px',
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
      color: grayPalette.gray100,
    },
    ':hover': {
      borderColor: grayPalette.gray30,
      borderWidth: '0px 0.07em 0px 0px',
      backgroundColor: grayPalette.gray100,
      boxShadow: 'none',
      color: '#FFFFFF',
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '7.14em 0px',
    [Breakpoints.LARGE_DEVICE_BREAKPOINT]: {
      padding: '2.14em 0px',
    },
    textAlign: 'center',
  },
  content: {
    width: '100%',
    marginTop: '6.07em',
    [Breakpoints.SMALL_DEVICE_BREAKPOINT]: {
      marginTop: '3.035em',
    },
    textAlign: 'center',
  },
  contentPadding: {
    padding: '0px 3.57em 2.14em 3.57em',
    [Breakpoints.LARGE_DEVICE_BREAKPOINT]: {
      padding: '0px 1.60em 2.14em 1.60em',
    },
  },
  disclaimer: {
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '2.86em',
    marginTop: '0em',
  },
  forceFocus: {
    borderColor: grayPalette.gray30,
  },
  inputWrapper: {
    // clears default blue border styling on date of birth input
    ':active': {
      borderColor: grayPalette.gray30,
    },
    ':focus-visible': {
      borderColor: grayPalette.gray30,
      outline: 'none',
    },
    ':focus': {
      borderColor: grayPalette.gray30,
      outline: 'none',
    },
    ':focus-within': {
      borderColor: grayPalette.gray30,
      outline: 'none',
    },
  },
  singleDatePicker: {
    textAlign: 'left',
    marginBottom: '1.07em',
  },
  successTextContainer: {
    alignItems: 'center',
    margin: '0 2.14em',
    justifyContent: 'center',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
    marginBottom: '1.43em',
    textAlign: 'center',
  },
});

export default IdentityVerification;
